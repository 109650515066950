<template>
  <v-layout child-flex>
    <v-card height="50%">
      <v-card-title
        shaped
        class="p-0 subtitle-2"
        style="background-color: #ffffff; color: black"
      >
        <v-row
          ><v-col cols="12" sm="8" md="11"
            ><v-icon class="icon-title mr-1" color="black">
              mdi-delete-alert </v-icon
            >{{ title }}</v-col
          ><v-col cols="12" sm="4" md="1"></v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <DynamicDataTable
          @rowSelected="elementSelected($event)"
          :dataTable_configuration="dataTableConfiguration"
          :dataTable_height="datatableHeight"
          :query="query"
          :deselectAllRows="deselect_all_rows"
          @deselectedAllRows="elementsDeselectedAllRows"
          @position="openMapDialog"
          @log="openLogDialog"
          @uploaded_file="openCodeDialog"
          @vehicle_id="changeVehicle"
          @view_planification="viewPlanification"
          @geojson="openMapDialog"
        />
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
//import axios from 'axios'

//import datatablesConfiguration from '@/config/datatables.json'
import DynamicDataTable from "@/components/dynamic/DynamicDataTable";

export default {
  components: {
    DynamicDataTable,
  },
  props: {
    dataTableConfiguration: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Titulo",
    },
    datatableHeight: {
      type: String,
      default: "100vh",
    },
    deselectAllRows: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    //dataTableConfiguration: ''
    deselect_all_rows: false,
  }),
  methods: {
    elementSelected: function (values) {
      this.$emit("elementSelected", values);
    },
    elementsDeselectedAllRows: function () {
      this.$emit("deselectedAllRows");
    },
  },
  watch: {
    deselectAllRows: function (newVal) {
      this.deselect_all_rows = newVal;
    },
  },
};
</script>
<style scoped>
.icon-title {
  position: absolute;
  right: 0;
}
</style>
