<template>
  <div>
    <v-divider></v-divider>
    <v-radio-group
      v-model="radioGroupRealEstimated"
      @change="showLayer()"
      row
      class="pl-2 mt-0"
    >
      <v-radio label="Reals" value="real"></v-radio>
      <v-radio label="Estimats" value="estimated"></v-radio>
    </v-radio-group>

    <v-checkbox
      v-model="incoherence"
      label="Diferències"
      class="pl-2 mt-0"
      @change="showLayer()"
    ></v-checkbox>

    <v-checkbox
      v-model="contractorCodeLabel"
      label="Noms ubicacions"
      class="pl-2 mt-0"
      @change="showLayer()"
    ></v-checkbox>

    <v-divider :key="`${layerNamePrefix}-ALL-divider`"></v-divider>
    <v-list-item
      :key="`${layerNamePrefix}-ALL`"
      :value="`${layerNamePrefix}-ALL`"
      class="listItemPadding"
    >
      <template v-slot:default="{}">
        <v-list-item-action class="listItemActionMargin">
          <v-checkbox
            v-model="selectAll"
            @change="selectAllFractions($event)"
          ></v-checkbox>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title> Visualitzar totes </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>

    <template v-for="fraction in fractions">
      <v-divider
        :key="`${layerNamePrefix}-${fraction.code}-divider`"
      ></v-divider>
      <v-list-item
        :key="`${layerNamePrefix}-${fraction.code}`"
        :value="`${layerNamePrefix}-${fraction.code}`"
        class="listItemPadding"
      >
        <template v-slot:default="{}">
          <v-list-item-action class="listItemActionMargin">
            <v-checkbox
              v-model="fractionsSelected"
              :value="fraction.code"
              @change="showLayer()"
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{
              fraction.code + " - " + fraction.description
            }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LayerSelectorLocations",
  components: {},
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
    fractionsData: {
      type: Object,
      default: () => ({}),
    },
    fractionsProp: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    url_fractions: `/api/v1/fraction`,
    url_locations_geoserver: "",
    fractions: null,
    layerNamePrefix: "LOCATIONS",
    incoherence: true,
    contractorCodeLabel: false,
    radioGroupRealEstimated: "real",
    fractionsSelected: [],
    selectAll: true,
    init: false,
  }),
  async mounted() {
    this.$emit("init");
    // this.fractions= this.fractionsData fractions
    this.incoherence = this.fractionsData.incoherence;
    this.radioGroupRealEstimated = this.fractionsData.radioGroupRealEstimated;
    this.fractionsSelected = this.fractionsData.fractionsSelected;
    this.selectAll = this.fractionsData.selectAll;
    this.contractorCodeLabel = this.fractionsData.contractorCodeLabel;

    // fractionsData
    // if (this.fractionsProp.length) {
    //   this.fractions = this.fractionsProp;
    // } else {
    //   await this.createMenuFractions();
    //   console.log("emit1");
    //   this.$emit("updateFractions", this.fractions);
    // }
  },
  methods: {
    async createMenu() {
      if (this.fractionsData.fractions.length) {
        this.fractions = this.fractionsData.fractions;
      } else {
        this.init = true;
        await this.createMenuFractions();
        this.$emit("updateFractions", this.fractions);
      }
    },
    createMenuFractions: async function () {
      //console.log("createMenuFractions");
      const url = this.url_fractions;

      try {
        const respuesta = await axios.get(url,this.getHeaders());

        const fractions = respuesta.data;

        this.fractions = fractions.map((element) => ({
          ...element,
          visible: true,
        }));
        this.fractionsSelected = this.fractions.map(
          (fraction) => `${fraction.code}`
        );
        console.log(this.fractionsSelected);
        this.showLayer();
      } catch (err) {
        console.log(err);
        console.log("ha habido un error");
      }
    },

    showLayer: function () {
      if (this.fractionsSelected.length == this.fractions.length) {
        if (this.selectAll != true) {
          this.selectAll = true;
        }
      } else {
        this.selectAll = false;
      }

      let cql_filter_fractions = "";
      let view_params_fractions = "";

      if (this.fractionsSelected.length == 0) {
        cql_filter_fractions = "'-1'";
        view_params_fractions = "location_fractions_like: estimateds = '-1'";
      }

      for (var i = 0; i < this.fractionsSelected.length; i++) {
        if (view_params_fractions == "") {
          view_params_fractions = `location_fractions_like: xxxx like '%${this.fractionsSelected[i]}%'`;
        } else {
          view_params_fractions = `${view_params_fractions} or xxxx like '%${this.fractionsSelected[i]}%'`;
        }

        if (cql_filter_fractions == "") {
          cql_filter_fractions = `'${this.fractionsSelected[i]}'`;
        } else {
          cql_filter_fractions = `${cql_filter_fractions},'${this.fractionsSelected[i]}'`;
        }
      }

      if (this.radioGroupRealEstimated == "real") {
        view_params_fractions = view_params_fractions.replace(/xxxx/g, "reals");
      } else {
        view_params_fractions = view_params_fractions.replace(
          /xxxx/g,
          "estimateds"
        );
      }
      const wmsLocationsViewParams = view_params_fractions;
      const wmsLocationsExtParams = {
        ENV: `contractorCodeLabel:${this.contractorCodeLabel};incoherences:${
          this.incoherence
        };reals:${this.radioGroupRealEstimated == "real" ? true : false}`,
        CQL_FILTER: `fraction IN ( ${cql_filter_fractions} )`,
      };

      this.$emit("changeExtParamsLocations", wmsLocationsExtParams);
      this.$emit("changeViewParamsLocations", wmsLocationsViewParams);
    },
    selectAllFractions: function (selectAll) {
      if (selectAll) {
        this.fractionsSelected = this.fractions.map(
          (fraction) => `${fraction.code}`
        );
      } else {
        this.fractionsSelected = [];
      }
      this.showLayer();
    },
  },
  watch: {
    // fractionsSelected( fractionsDataComputed){
    //    console.log("algo ha cambiaddo");
    //    console.log(fractionsDataComputed);
    // }
  },
  computed: {
    // fractionsDataComputed(){
    //   return {
    //     fractions: this.fractions,
    //     incoherence: this.incoherence,
    //     radioGroupRealEstimated:  this.radioGroupRealEstimated,
    //     fractionsSelected: this.fractionsSelected,
    //     selectAll: this.selectAll,
    //   }
    // }
  },
  inject: ["getHeaders"],

  beforeDestroy() {
    // console.log('beforeDestroy');

    this.$emit("fractionsDataUpdate", {
      fractions: this.fractions,
      incoherence: this.incoherence,
      radioGroupRealEstimated: this.radioGroupRealEstimated,
      fractionsSelected: this.fractionsSelected,
      selectAll: this.selectAll,
      contractorCodeLabel: this.contractorCodeLabel,
    });
  },
};
</script>
<style scoped>
.v-toolbar__title {
  font-size: 1rem;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  font-size: 1.2rem;
}

.v-list-item__title {
  font-size: 0.8rem !important;
}

/deep/ .v-label {
  font-size: 0.8rem !important;
}
.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 24px !important;
}

.v-list--dense .v-subheader {
  height: 37px;
}

.v-list--three-line .v-list-item .v-list-item__avatar,
.v-list--three-line .v-list-item .v-list-item__action,
.v-list-item--three-line .v-list-item__avatar,
.v-list-item--three-line .v-list-item__action {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.v-list-item__content {
  padding: 0px 0 !important;
}

/deep/ .v-icon.v-icon {
  font-size: 1.1rem !important;
}

.v-label {
  font-size: 0.8rem !important;
}

.navigationDrawerBorderLeft {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

button,
input,
optgroup,
select,
textarea {
  font-size: 0.8rem !important;
}

.layer_active {
  background-color: #e2e2e2;
}

/deep/ .bg-active {
  background-color: white;
}

.v-list-item__action {
  margin: 6px 0;
}

.v-list {
  padding: 0 0;
}

.listItemPadding {
  padding: 0px 8px !important;
  min-height: 36px !important;
}

.listItemActionMargin {
  margin-right: 8px !important;
}
/deep/ .v-messages {
  min-height: 0;
}
</style>
