<template>
<DefaultLayout>
<v-container fluid class="grey lighten-5 ma-0">
 
  <v-row dense>
      <v-col cols="12" sm="12" md="S12">
       <Map mapHeight="height:90vh" :menusLayers="menusLayers" ></Map>
        </v-col>
   </v-row>
  </v-container>
  </DefaultLayout>
</template>

<script>
    import DefaultLayout from "../layouts/DefaultLayout.vue";
    import Map from "@/components/map/Map.vue";
    import layersMenuFixed from "@/config/map/layersMenuFixed.json";
    import layersMenuSearcher from "@/config/map/layersMenuSearcher.json";
    //import DynamicSearch from '@/components/dynamic/DynamicSearch'

    export default {
  name: "mapa",
  components: {
    //DynamicSearch,
    Map,
    DefaultLayout
  },
  created(){
    this.menusLayers.push(layersMenuFixed);
    this.menusLayers.push(layersMenuSearcher);
  },
  data() {
    return {
      query: '',
      itinerary_selected_id:8,
      model:"itineraris",
      menusLayers:[]
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      addLayerItem: this.addLayerItem
    }
  },
  methods: {
     itinerarySelected(id) {
       
          this.itinerary_selected_id = id;
      },
      /*setSearch(inputs) {
        //DynamicSearch component emits to this function whenever `Cercar` is pressed
        //Here we should call the server with our search request for a particular model
        //The response should be sent to DynamicDataTable as a prop, to show the queried data
        console.log(inputs)
        this.query = '?' + Object.keys(inputs).map(key => key + '=' + inputs[key]).join('&');
      },*/
     addLayerItem(el, layerGroupName){
      //fix later 
      if (layerGroupName=="Itineraris") {
         console.log(this.menusLayers[1].layersMenu.layersGroups[0].layers);
         let duplicado = this.menusLayers[1].layersMenu.layersGroups[0].layers.filter(l=> l.name == el.name)
          if (duplicado.length) {
            console.log(duplicado.length);
            console.log("ya existe");
            return false;
        }else{
          this.menusLayers[1].layersMenu.layersGroups[0].layers.push(el); 
          return true;
        }
      }else if (layerGroupName=="Ubicacions") {
        console.log(this.menusLayers[1].layersMenu.layersGroups[1].layers);
        let duplicado = this.menusLayers[1].layersMenu.layersGroups[1].layers.filter(l=> l.name == el.name)
          if (duplicado.length) {
            console.log(duplicado.length);
            alert("Ja existeix una capa amb el nom: "+el.name);
            return false;
        }else{
          this.menusLayers[1].layersMenu.layersGroups[1].layers.push(el);
          return true;

        }
      }else if (layerGroupName=="Elements") {
        console.log(this.menusLayers[1].layersMenu.layersGroups[2].layers);
        let duplicado = this.menusLayers[1].layersMenu.layersGroups[2].layers.filter(l=> l.name == el.name)
          if (duplicado.length) {
            console.log(duplicado.length);
            alert("Ja existeix una capa amb el nom: "+el.name);
            return false;

        }else{
          this.menusLayers[1].layersMenu.layersGroups[2].layers.push(el);
          return true;

        }
      }
      else if (layerGroupName=="Serveis") {
        console.log("Map serveis")
        console.log(this.menusLayers[1].layersMenu.layersGroups[3].services);
        let duplicado = this.menusLayers[1].layersMenu.layersGroups[3].services.filter(l=> l.name == el.name)
          if (duplicado.length) {
            console.log(duplicado.length);
            alert("Ja existeix una capa amb el nom: "+el.name);
            return false;

        }else{
          console.log(this.menusLayers[1].layersMenu.layersGroups[3].services.push(el));
          return true;

        }
      }
       
      //  console.log(this.menusLayers[1].layersMenu.layerGroups[0].layers);
       // this.menusLayers[1].layersMenu.layersGroups[0].layers[el]
     }
  },
};
</script>
<style scoped></style>

