<template>
<DefaultLayout>
<v-container fluid class="grey lighten-5 ma-0">
 <v-row dense>
      <v-col cols="12" sm="12" md="S12">
       <DynamicSearch class="mt-4" v-on:search="setSearch" :model="model"/>
        </v-col>
   </v-row>
  <v-row dense>
      <v-col cols="12" sm="12" md="S12">
        <Notifications
          :dataTableConfiguration="datatable_notifications_aj_configuration"
          datatableHeight="48vh"
          title="Notificacions"
          @itinerary_selected=itinerarySelected($event)
        ></Notifications>
        </v-col>
   </v-row>
  </v-container>
  </DefaultLayout>
</template>

<script>
//import Map from "@/components/map/Map.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import Notifications from "@/components/dashboard/notifications.vue";
import DynamicSearch from '@/components/dynamic/DynamicSearch';
import datatablesconfiguration from "@/config/datatables.json";

    export default {
  name: "notifications",
  components: {
    DynamicSearch,
    Notifications,
    DefaultLayout
    
  },
  data() {
    return {
        query: '',
        model:"notifications",
        itinerary_selected_id:0,
        datatable_notifications_aj_configuration:
        datatablesconfiguration.notifications.datatable_notifications_aj,
       
    };
  },
  methods: {
      itinerarySelected(id) {
       
          this.itinerary_selected_id = id;
      },
     
      setSearch(inputs) {
        //DynamicSearch component emits to this function whenever `Cercar` is pressed
        //Here we should call the server with our search request for a particular model
        //The response should be sent to DynamicDataTable as a prop, to show the queried data
        console.log(inputs)
        this.query = '?' + Object.keys(inputs).map(key => key + '=' + inputs[key]).join('&');
      },
  },
};
</script>
<style scoped></style>

