var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataTableConfiguration != null)?_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"d-print-block",attrs:{"height":_vm.dataTableHeight,"dense":"","fixed-header":"","headers":_vm.dataTableConfiguration.headers,"items":_vm.items,"search":_vm.search,"items-per-page":_vm.dataTableConfiguration.items_per_page,"loading":_vm.loading,"loading-text":"Carregant","footer-props":{
      'disable-items-per-page': true,
      'items-per-page-text': '',
      'items-per-page-options': [_vm.dataTableConfiguration.items_per_page],
    },"page":_vm.page,"single-select":"","item-key":_vm.dataTableConfiguration.row_click_selected_id},on:{"click:row":_vm.rowClick,"current-items":_vm.itemsChanged},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"caption"},[_vm._v(" Exportar: ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.exportCSVFile(_vm.items, 'seleccio')}}},[_c('span',{staticClass:"hidden-sm-and-down"}),_c('v-icon',[_vm._v("mdi-file-download-outline")])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),(_vm.dataTableConfiguration.searcher === true)?_c('v-text-field',{staticClass:"search-input justify-end",attrs:{"append-icon":"mdi-magnify","label":"Cercar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)]},proxy:true},_vm._l((_vm.computedHeaders),function(head,i){return {key:("item." + (head.value)),fn:function(ref){
    var item = ref.item;
return [_c('div',{key:i,on:{"click":function($event){return _vm.handelAction(head, item)}}},[(head.type === 'icon')?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item[head.value] != null),expression:"item[head.value] != null"}],key:i,staticClass:"mr-1",domProps:{"textContent":_vm._s(head.icon)},on:{"click":function($event){return _vm.onIconClick(item, head.value)}}}):(head.type === 'icon-with-text-color')?_c('div',{key:i},[_c('v-icon',{staticClass:"mr-0",attrs:{"color":_vm.getColorIcon(item, head.color)},domProps:{"textContent":_vm._s(head.icon)},on:{"click":function($event){return _vm.onIconClick(item, head.value)}}}),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.getTextIcon(item, head.value))+" ")])],1):(head.type === 'text-date')?_c('span',{key:i},[_vm._v(" "+_vm._s(_vm.getDateFormat(item[("" + (head.value))], head.format))+" ")]):(head.type === 'chip-label')?_c('v-chip',{key:i,staticClass:"ma-1 font-weight-bold justify-center",style:({
          'min-width': _vm.getColorChip(item, head.value, head.style)[
            'min-width'
          ],
        }),attrs:{"label":"","color":_vm.getColorChip(item, head.value, head.style)['background-color'],"text-color":_vm.getColorChip(item, head.value, head.style)['color'],"dark":""}},[_vm._v(_vm._s(_vm.getTextChip(item, head.value))+" ")]):(head.type === 'chip-label-array-color-by-key')?_c('span',{key:i},_vm._l((_vm.valueItem(item, head.value)),function(item,a){return _c('v-chip',{key:a,staticClass:"ma-1 font-weight-bold justify-center",attrs:{"label":"","color":_vm.getColorChip(item, head.value, head.style)['background-color'],"text-color":_vm.getColorChip(item, head.value, head.style)['color']}},[_vm._v(_vm._s(item[head.description])+" ")])}),1):_c('v-chip',{key:i,staticClass:"ma-1 font-weight-bold justify-center",style:({
          'min-width': _vm.getColorChip(item, head.value, head.style)[
            'min-width'
          ],
        }),attrs:{"color":_vm.getColorChip(item, head.value, head.style)['background-color'],"text-color":_vm.getColorChip(item, head.value, head.style)['color'],"dark":""}},[_vm._v(_vm._s(_vm.getTextChip(item, head.value))+" ")])],1)]}}}),{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-0 mb-0 mx-n4",attrs:{"dense":"","value":true,"color":"#d9f2f5","icon":"mdi-information-outline","tile":""}},[_vm._v(" No hi ha dades disponibles ")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }