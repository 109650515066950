<template>
  <v-toolbar dense flat style="padding: 0; margin: 0">
    <div class="text-center paddless" style="width: 4.4em !important">
      <v-btn text class="ma-0 pa-0" outlined color="black" @click="today()"
        >Avui</v-btn
      >
    </div>
    <v-divider vertical class="mx-2"></v-divider>
    <v-btn small icon :value="1" @click="addPeriod(-1)">
      <v-icon color="black lighten-1"> mdi-arrow-left-circle </v-icon>
    </v-btn>
    <v-btn small icon :value="2" @click="addPeriod(1)">
      <v-icon color="black lighten-1"> mdi-arrow-right-circle </v-icon>
    </v-btn>
    <v-toolbar-title
      class="overline text-weight-bold ml-2"
      v-if="this.viewdays === 1"
    >
      {{ dailytitle }}
    </v-toolbar-title>

    <v-toolbar-title class="overline text-weight-bold ml-2" v-else>
      {{ monthtitle }}
    </v-toolbar-title>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn-toggle v-model="type" tile color="black accent-2" group>
        <v-btn :value="1" @click="$emit('changeView', 1)"> Diari </v-btn>

        <v-btn :value="7" @click="$emit('changeView', 7)"> Setmanal </v-btn>

        <v-btn :value="31" @click="$emit('changeView', 31)"> Mensual </v-btn>
      </v-btn-toggle>
    </template>
  </v-toolbar>
</template>

<script>
export default {
  name: "CalendarToolbar",
  components: {},
  props: ["currentday", "viewdays", "locale"],
  mounted() {},
  created() {
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);
  },
  data: () => ({
    type: 31,
  }),
  methods: {
    addPeriod(value) {
      /*value = 1 --> 
           * si vista es semanal, la semana siguiente
           * si vista es mensual, el mes siguiente
           * si vista es diaria, el dia siguiente
        value= -1 --> 
           * si vista es semanal, la semana anterior
           * si vista es mensual, el mes anterior
           * si vista es diaria, el dia anterior
       */

      console.log("addPeriod");
      console.log(this.$date(this.currentday).format("DD-MM-YYYY"));
      console.log(parseInt(this.$date(this.currentday).format("d")));

      if (this.viewdays === 7) {
        //Vista semanal
        this.$emit("goTo", this.$date(this.currentday).add(value, "week"));
        //Si hay que ir a la semana siguiente
        /*if (
          value > 0 ||
          parseInt(this.$date(this.currentday).format("d")) === 0
        ) {
          this.$emit("goTo", this.$date(this.currentday).add(value, "week"));
        } else {
          this.$emit("goTo", this.$date(this.currentday).day(0));
        }*/
      } else if (this.viewdays === 1) {
        //Vista de 1 dia
        this.$emit("goTo", this.$date(this.currentday).add(value, "day"));
      } else {
        //Vista mensual
        this.$emit(
          "goTo",
          this.$date(this.currentday).add(value, "month").startOf("month")
        );
        /*if (
          value > 0 ||
          parseInt(this.$date(this.currentday).format("D")) === 1
        ) {
          this.$emit(
            "goTo",
            this.$date(this.currentday).add(value, "month").startOf("month")
          );
        } else {
          this.$emit("goTo", this.$date(this.currentday).startOf("month"));
        }*/
      }
    },
    today() {
      this.$emit("goTo", this.$date());
    },
    switchview(value) {
      this.type = value;
      this.$emit("changeView", value);
    },
  },
  watch: {},
  computed: {
    monthtitle() {
      let text;
      if (this.viewdays > 7) {
        text = this.$date(this.currentday).format("MMMM YYYY");
      } else if (this.viewdays === 7) {
        text =
          this.$date(this.currentday).format("dddd DD MMM YYYY") +
          " - " +
          this.$date(this.currentday)
            .add(this.viewdays - 1, "days")
            .format("dddd DD MMM YYYY");
      } else {
        text = "";
      }
      return text;
      /*return parseInt(this.$date(this.currentday).format("D")) === 1
        ? this.$date(this.currentday).format("MMMM YYYY")
        : this.$date(this.currentday).format("dddd DD MMM") +
            " - " +
            this.$date(this.currentday)
              .add(this.viewdays - 1, "days")
              .format("dddd DD MMM");*/
    },
    day() {
      return parseInt(this.$date(this.currentday).format("D"));
    },
    dailytitle() {
      return this.$date(this.currentday).format("dddd D MMMM YYYY");
    },
    title() {
      return this.viewdays === 1 ? this.dailytitle : this.monthtitle;
    },
  },
};
</script>
<style scoped></style>
