<template> 
<div>

    <v-app-bar app style="background-color: #71cfdb" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Ajuntament del Prat Logo"
          class="shrink mr-2"
          contain
          src="@/assets/log_e_blanc.png"
          transition="scale-transition"
          width="180"
        />
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title class="font-weight-bold"
        ><v-img
          alt="Transparencity"
          class="shrink mr-2"
          contain
          src="@/assets/transparencity.png"
          transition="scale-transition"
          width="240"
      /></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        to="/dashboard"
        text
        rounded
        class="mr-2"
        :color="$route.name == 'dashboard' ? null : 'error'"
      >
        <v-icon left>mdi-home</v-icon>
        Dashboard
      </v-btn>
      <v-btn
        to="/calendar"
        text
        rounded
        class="mr-2"
        :color="$route.name == 'calendar' ? null : 'error'"
      >
        <v-icon left>mdi-calendar</v-icon>
        Calendari
      </v-btn>
      <v-btn
        to="/map"
        text
        rounded
        class="mr-2"
        :color="$route.name == 'map' ? null : 'error'"
      >
        <v-icon left>mdi-map</v-icon>
        Mapa
      </v-btn>
      <v-btn
        to="/reports"
        text
        rounded
        class="mr-2"
        :color="$route.name == 'reports' ? null : 'error'"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        Informes
      </v-btn>
      <v-btn
        to="/notifications"
        text
        rounded
        class="mr-2"
        :color="$route.name == 'notifications' ? null : 'error'"
      >
        <v-icon left>mdi-bell</v-icon>
        Notificacions
      </v-btn>
      <v-btn to="/logout" text rounded class="mr-2" color="error">
        <v-icon left>mdi-exit-to-app</v-icon>
        Sortir
      </v-btn>
      <v-avatar class="mr-2" size="36" color="white">
        <v-icon color="#676b74">mdi-account </v-icon>
      </v-avatar>
      <span class="grey--text text--darken-2 subtitle-2"
        >{{getUser().email}}</span
      >
    </v-app-bar>

    <v-main app> 
       <slot></slot> 
    </v-main> 
</div>

</template>

<script>
export default {
  name: "DefaultLayout",

  data: () => ({
    //
  }),
  mounted() {
    console.log("this.userData")
    console.log(this.userData)
    console.log("holaana")
    
    //console.log(process.env.VUE_APP_TRANSPARENCITY_API);
  },
  inject:["getUser"], 

};
</script>
<style>
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
