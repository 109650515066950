<template>
  <DefaultLayout>
    <v-container fluid class="grey lighten-5 ma-0" fill-height>
      <v-row dense>
        <v-col cols="12" sm="8" md="6">
          <Itineraries
            :dataTableConfiguration="
              datatable_itineraries_actives_configuration
            "
            :deselectAllRows="datatable_itineraries_actives_deselectAll"
            datatableHeight="25vh"
            :digital_clock="true"
            :llegenda="false"
            title="Itineraris actius"
            @itinerarySelected="itineraryActiveSelected($event)"
            @deselectedAllRows="itineraryActiveDeselected"
          ></Itineraries>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <!--<v-card class="pa-2" outlined tile>-->
          <Notifications
            :dataTableConfiguration="datatable_notifications_aj_configuration"
            :deselectAllRows="datatable_notifications_deselectAll"
            datatableHeight="25vh"
            title="Notificacions"
            @notificationSelected="notificationSelected($event)"
            @deselectedAllRows="notificationDeselected"
          ></Notifications>
          <!--</v-card>-->
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <Elements
            :dataTableConfiguration="
              datatable_elements_with_issues_configuration
            "
            :deselectAllRows="datatable_elements_deselectAll"
            datatableHeight="25vh"
            title="Elements amb incidències"
            @elementSelected="elementSelected($event)"
            @deselectedAllRows="elementDeselected"
          ></Elements>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6" md="6">
          <Itineraries
            :dataTableConfiguration="
              datatable_itineraries_executed_configuration
            "
            :deselectAllRows="datatable_itineraries_executed_deselectAll"
            datatableHeight="31.5vh"
            :digital_clock="false"
            :llegenda="true"
            title="Itineraris executats"
            @itinerarySelected="itineraryExecutedSelected($event)"
            @deselectedAllRows="itineraryExecutedDeselected"
          ></Itineraries>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <!--<Map
          :itinerary_id="itinerary_selected_id"
          mapHeight="height:47vh"
        ></Map>-->
          <Map2
            :itinerary_id="itinerary_selected_id"
            :overlayLayers="arr_map_overlay_layers"
            :overlayLayersTitle="overlay_layers_title"
            mapHeight="height:47vh"
          ></Map2>
        </v-col>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "../layouts/DefaultLayout.vue";
import LayersTemplates from "@/config/map/layersTemplates.json";
import Notifications from "../components/dashboard/notifications.vue";
import Itineraries from "../components/dashboard/itineraries.vue";
import Elements from "../components/dashboard/elements.vue";
//import Map from "../components/dashboard/map.vue";
import Map2 from "../components/dashboard/map2.vue";
import datatablesconfiguration from "@/config/datatables.json";

export default {
  name: "dashboard",
  components: {
    Notifications,
    Itineraries,
    Elements,
    DefaultLayout,
    //Map,
    Map2,
  },
  data() {
    return {
      datatable_itineraries_actives_configuration:
        datatablesconfiguration.dashboard.datatable_itineraries_actives,
      datatable_itineraries_executed_configuration:
        datatablesconfiguration.dashboard.datatable_itineraries_executed,
      datatable_notifications_aj_configuration:
        datatablesconfiguration.dashboard.datatable_notifications_aj,
      datatable_elements_with_issues_configuration:
        datatablesconfiguration.dashboard.datatable_elements_with_issues,
      datatable_itineraries_actives_deselectAll: false,
      datatable_notifications_deselectAll: false,
      datatable_elements_deselectAll: false,
      datatable_itineraries_executed_deselectAll: false,
      arr_map_overlay_layers: [],
      itinerary_selected_id: 0,
      overlay_layers_title: "",
    };
  },
  /*provide:{
    datatablesconfiguration: datatablesconfiguration
  },*/
  methods: {
    /**itineraryActiveSelected: 
    elements contiene '[{"name":"path_id","value":3},{"name":"vehicle_registration","value":"1279CPC"}]' **/
    itineraryActiveSelected: function (elements) {
      this.datatable_itineraries_actives_deselectAll = false;
      this.datatable_notifications_deselectAll = true;
      this.datatable_elements_deselectAll = true;
      this.datatable_itineraries_executed_deselectAll = true;

      //eliminar contenido de array para que sea reactivo
      this.arr_map_overlay_layers.splice(0);
      //this.arr_map_overlay_layers = [];

      const path_id = elements.find(
        (element) => element.name === "path_id"
      ).value;

      const vehicle_registration = elements.find(
        (element) => element.name === "vehicle_registration"
      ).value;

      const service_id = elements.find(
        (element) => element.name === "service_id"
      ).value;

      const vehicle_id = elements.find(
        (element) => element.name === "vehicle_id"
      ).value;


      /*const itinerary_id = elements.find(
        (element) => element.name === "itinerary_id"
      ).value;*/

      let obj_layer_gps = LayersTemplates.layers.find(
        (layer) => layer.type === "GPS"
      );

      //Copia profunda
      obj_layer_gps = JSON.parse(JSON.stringify(obj_layer_gps));
      //Copia superficial
      //obj_layer_gps = { ...obj_layer_gps };
      obj_layer_gps.title = `GPS - ${vehicle_registration}`;
      obj_layer_gps.name = `GPS-${vehicle_id}`;
      //obj_layer_gps.registration_id = vehicle_registration;
      obj_layer_gps.registration_id = vehicle_registration;
      obj_layer_gps.vehicle_id = vehicle_id;
      obj_layer_gps.service_id = service_id;
      console.log(obj_layer_gps);
      //obj_layer_gps.visible = true;

      let obj_layer_path = LayersTemplates.layers.find(
        (layer) => layer.type === "PATH"
      );
      obj_layer_path = JSON.parse(JSON.stringify(obj_layer_path));
      //obj_layer_path = { ...obj_layer_path };
      obj_layer_path.title = `PATH`;
      obj_layer_path.name = `PATH-${path_id}`;
      obj_layer_path.path_id = path_id;
      obj_layer_path.fitAtLoad = true;
      obj_layer_path.elementsWithTrashIcon = false;
      obj_layer_path.withClustering = true;
      obj_layer_path.poisWithSameColor = true;
      //obj_layer_path.visible = true;
      this.overlay_layers_title = "ITINERARIS ACTIUS";
      this.arr_map_overlay_layers.push(obj_layer_path, obj_layer_gps);
    },
    /**itineraryExecutedSelected: 
    elements contiene '[{"name":"path_id","value":5}]' **/
    itineraryExecutedSelected: function (elements) {
      this.datatable_itineraries_actives_deselectAll = true;
      this.datatable_itineraries_executed_deselectAll = false;
      this.datatable_notifications_deselectAll = true;
      this.datatable_elements_deselectAll = true;

      console.log("itineraryExecutedSelected");
      console.log(elements);
      this.arr_map_overlay_layers.splice(0);

      const path_id = elements.find(
        (element) => element.name === "path_id"
      ).value;

      const vehicle_registration = elements.find(
        (element) => element.name === "vehicle_registration"
      ).value;

      const service_id = elements.find(
        (element) => element.name === "service_id"
      ).value;

      const service_status = elements.find(
        (element) => element.name === "service_status"
      ).value;

      const vehicle_id = elements.find(
        (element) => element.name === "vehicle_id"
      ).value;

      let obj_layer_gps = LayersTemplates.layers.find(
        (layer) => layer.type === "SERVICEGPS"
      );
      //Copia profunda
      obj_layer_gps = JSON.parse(JSON.stringify(obj_layer_gps));
      //Copia superficial
      //obj_layer_gps = { ...obj_layer_gps };
      obj_layer_gps.title = `GPS - ${vehicle_registration}`;
      obj_layer_gps.name = `SERVICEGPS-${vehicle_id}`;
      //obj_layer_gps.registration_id = vehicle_registration;
      obj_layer_gps.service_id = service_id;
      console.log(obj_layer_gps);
      //obj_layer_gps.visible = true;

      let obj_layer_path = LayersTemplates.layers.find(
        (layer) => layer.type === "PATH"
      );
      obj_layer_path = JSON.parse(JSON.stringify(obj_layer_path));
      //obj_layer_path = { ...obj_layer_path };
      obj_layer_path.title = `Itinerari`;
      obj_layer_path.name = `PATH-${path_id}`;
      obj_layer_path.path_id = path_id;
      obj_layer_path.fitAtLoad = true;
      obj_layer_path.elementsWithTrashIcon = false;
      obj_layer_path.withClustering = false;
      obj_layer_path.poisWithSameColor = true;
      obj_layer_path.visible = false;

      let obj_layer_service_analysis = LayersTemplates.layers.find(
        (layer) => layer.type === "SERVICEANALYSIS"
      );

      obj_layer_service_analysis = JSON.parse(
        JSON.stringify(obj_layer_service_analysis)
      );
      //Copia superficial
      //obj_layer_gps = { ...obj_layer_gps };
      obj_layer_service_analysis.title = `Anàlisi del servei`;
      obj_layer_service_analysis.name = `SERVICEANALYSIS-${vehicle_registration}`;
      //obj_layer_gps.registration_id = vehicle_registration;
      obj_layer_service_analysis.service_id = service_id;
      obj_layer_service_analysis.path_id = path_id;
      console.log(obj_layer_service_analysis);
      //obj_layer_gps.visible = true;

      this.overlay_layers_title = "ITINERARIS EXECUTATS";

      if (service_status == "Unstarted") {
        obj_layer_path.visible = true;
        this.arr_map_overlay_layers.push(obj_layer_path, obj_layer_gps);
      } else {
        this.arr_map_overlay_layers.push(
          obj_layer_path,
           obj_layer_gps,
          obj_layer_service_analysis
         
        );
      }

      console.log("itineraryExecutedSelected");
      console.log(elements);
    },
    /**notificationSelected: 
    values contiene '[{"name":"path_id","value":2}]' **/
    notificationSelected: function (values) {
      this.datatable_itineraries_actives_deselectAll = true;
      this.datatable_notifications_deselectAll = false;
      this.datatable_elements_deselectAll = true;
      this.datatable_itineraries_executed_deselectAll = true;

      this.arr_map_overlay_layers.splice(0);

      console.log("notificationSelected");
      console.log(values);
    },
    /**elementSelected: 
    values contiene '[{"name":"element_id","value":84822}]' **/
    elementSelected: function (values) {
      this.datatable_itineraries_actives_deselectAll = true;
      this.datatable_notifications_deselectAll = true;
      this.datatable_elements_deselectAll = false;
      this.datatable_itineraries_executed_deselectAll = true;

      this.arr_map_overlay_layers.splice(0);

      console.log("elementSelected");
      console.log(values);
    },
    itineraryActiveDeselected: function () {
      this.datatable_itineraries_actives_deselectAll = false;
    },
    notificationDeselected: function () {
      this.datatable_notifications_deselectAll = false;
    },
    elementDeselected: function () {
      this.datatable_elements_deselectAll = false;
    },
    itineraryExecutedDeselected: function () {
      this.datatable_itineraries_executed_deselectAll = false;
    },

    /*itinerarySelected(values) {
      this.itinerary_selected_id = values;
      console.log(this.itinerary_selected_id);
    },*/
  },
};
</script>
<style scoped>
.v-application .error {
  background-color: #dbdbdb !important;
  border-color: #676b74 !important;
}
</style>
