<template>
  <!--<v-container>-->
  <v-layout child-flex>
    <v-card height="50%" class="pa-0">
      <!--<v-card-title
        shaped
        class="p-0 subtitle-2"
        style="background-color: #ffffff; color: black"
        >
        <v-row
          ><v-col cols="12" sm="8" md="8">{{ title }}</v-col
          ><v-col cols="12" sm="4" md="4"
            > </v-col></v-row
      ></v-card-title>-->
      <v-card-text>
        <vl-map
          ref="map"
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="true"
          :style="mapHeight"
          data-projection="EPSG:4326"
        >
          <vl-view ref="mapView" :center.sync="center" :zoom.sync="zoom" />

          <vl-layer-tile
            v-for="layer in base_layers"
            :key="layer.name"
            :id="layer.name"
            :visible="layer.visible"
          >
            <component :is="layer.component" v-bind="layer" />
          </vl-layer-tile>

          <component
            v-for="layer in overlayLayers"
            :is="layer.component"
            :key="layer.name"
            :ref="layer.name"
            v-bind="layer"
            @gpsChange="changeLayerGPSStatus"
            
          ></component>
          <!--<vl-interaction-select
            :features.sync="selectedFeatures"
            :condition="eventCondition"
            
          >
            <template slot-scope="selection">
              <vl-style-func :factory="styleFunctionSelected"></vl-style-func>
              

              
              <vl-overlay
                v-for="feature in selection.features"
                :key="feature.id"
                :id="feature.id"
                class="feature-popup"
                :position="pointOnSurface(feature.geometry)"
                
              >
              
                <template>
                  <v-card
                  elevation="2"
                  outlined
                  shaped
                  tile
                >
                <v-card-title>Feature ID {{ feature.id }}</v-card-title>
                <v-card-text>{{ feature.properties }}</v-card-text>
                

                </v-card>
                 
                </template>
              </vl-overlay>
             
            </template>
          </vl-interaction-select>-->
        </vl-map>
        <LayerSelectorSimple
          :baseLayers="base_layers"
          :overlayLayers="overlayLayers"
          @fitLayerClick="fitLayer($event)"
          :overlayLayersTitle="overlayLayersTitle"
        ></LayerSelectorSimple>
      </v-card-text>
    </v-card>
  </v-layout>
  <!--</v-container>-->
</template>

<script>
//import axios from 'axios'
import * as condition from 'ol/events/condition';
import LayerSelectorSimple from "@/components/dialogs/maps/LayerSelectorSimple.vue";
import BaseLayers from "@/config/map/baseLayers.json";
import LayerPath from "@/components/layersTypes/layerPath.vue";
import LayerGPS from "@/components/layersTypes/layerGPS.vue";
import LayerServiceGPS from "@/components/layersTypes/layerServiceGPS.vue";
import LayerServiceAnalysis from "@/components/layersTypes/layerServiceAnalysis.vue";
import Vue from "vue";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import {findPointOnSurface} from 'vuelayers/lib/ol-ext';
import { Overlay } from 'vuelayers'
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
/*import axios from "axios";
import { loadingAll } from "vuelayers/lib/ol-ext";
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
import Point from "ol/geom/Point";
import MultiPoint from "ol/geom/MultiPoint"; // Text,/ Text,
import LineString from "ol/geom/LineString";
//import Polyline from "ol/format/Polyline";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
//import imageTruckLeft from "@/assets/icons_ubicacions/garbage-truck-64-left.png";
//import imageTruckRight from "@/assets/icons_ubicacions/garbage-truck-64-right.png";*/

Vue.use(VueLayers);

export default {
  name: "map2",
  components: {
    Overlay,
    LayerPath,
    LayerGPS,
    LayerServiceGPS,
    LayerServiceAnalysis,
    LayerSelectorSimple,
  },
  props: {
    overlayLayers: {
      type: Array,
      default: () => [],
    },
    overlayLayersTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Titulo",
    },
    mapHeight: {
      type: String,
      default: "height:100vh",
    },
    path_id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    eventCondition () {
    	return condition.pointerMove;
    }
  },
  watch: {
    path_id: async function () {
      // watch it
      //await this.loadFeatures();
      //await this.loadGPS();
      //this.loadGPS()
    },
  },
  data: () => ({
    //center: [233249.980469, 5060401.190735],
    center: [2.0953203, 41.325022],
    zoom: 16,
    features: [],
    features_gps: [],
    base_layers: null,
    selectedFeatures: [],
    //overlay_layers: null,

    //dataTableConfiguration: ''
  }),
  created() {
    this.base_layers = BaseLayers;
  },
  methods: {
    styleFunctionSelected() {
      return function (feature, resolution) {
            
      
        console.log("feature");
      console.log(feature);
        //const geometry = feature.getGeometry();

        if (resolution > 3) return new Style({});
      
        const styles = [];

        let style_1 = new Style({
          image: new Icon({
            src: require("@/assets/icons_ubicacions/back_white_transparente.png"),
            //anchor: [0.75, 0.5],
            rotateWithView: true,
            //size:40,
            scale: 0.4,
          }),
          zIndex: 6,
        });

        let style_2 = new Style({
          image: new CircleStyle({
            radius: 20,
            fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
            stroke: new Stroke({
              color: "black",
              width: 1,
            }),
          }),
          zIndex: 7,
        });

        styles.push(style_1);
        styles.push(style_2);

        const feature_fractions = feature.getProperties().fractions;
        feature_fractions.forEach((fraction) => {
          let fraction_code = fraction.code;
          let fraction_real = fraction.real;
          let icona = "";
          let offsetY = 0;
          let offsetX = 0;
          let text_color = "";

          switch (fraction_code) {
            case "RE":
              icona = "grey.png";
              offsetY = -10;
              offsetX = 7;
              text_color = "black";

              break;
            case "FO":
              icona = "brown.png";
              offsetY = -10;
              offsetX = -6;
              text_color = "white";

              break;
            case "PC":
              icona = "blue.png";
              offsetY = 3;
              offsetX = -10;
              text_color = "white";

              break;
            case "EN":
              icona = "yellow.png";
              offsetY = 12;
              offsetX = 0;
              text_color = "black";

              break;
            case "VI":
              icona = "green.png";
              offsetY = 3;
              offsetX = 11;
              text_color = "white";

              break;
            case "VO":
              icona = "green.png";
              offsetY = 0;
              offsetX = 0;
              text_color = "white";

              break;
            case "VE":
              icona = "green.png";
              offsetY = 0;
              offsetX = 0;
              text_color = "white";

              break;
            case "SF":
              icona = "green.png";
              offsetY = 0;
              offsetX = 0;
              text_color = "white";

              break;
            default:
              break;
          }
          let style_3 = new Style({
            image: new Icon({
              src: require("@/assets/icons_ubicacions/" + icona),
              //anchor: [0.75, 0.5],
              rotateWithView: true,
              //size:40,
              scale: 0.41,
            }),
            zIndex: 6,
          });
          let style_4 = new Style({
            text: new Text({
              text: fraction_real.toString(),
              offsetY: offsetY,
              offsetX: offsetX,
              font: "bold 9px sans-serif",
              fill: new Fill({
                color: text_color,
              }),
            }),
            zIndex: 6,
          });
          styles.push(style_3, style_4);
        });

        return styles;
      };
    },
    pointOnSurface: findPointOnSurface,
    fitLayer: function (layer_name) {
      let layer = this.overlayLayers.find((layer) => layer.name === layer_name);
      //console.log(layer);
      if (layer != null) {
        if (layer.visible) {
          //console.log("this.$refs")
          //console.log(this.$refs)
          if (layer.type == "GPS") {
            this.$refs.mapView.$view.fit(
              this.$refs[layer_name][0].$refs.vectorSource.$source.getExtent(),
              //this.$refs.vectorSource.$source.getExtent(),
              {
                size: this.$refs.map.$map.getSize(),
                duration: 10,
                maxZoom: 15,
              }
            );
          } else {
            this.$refs.mapView.$view.fit(
              this.$refs[layer_name][0].$refs.vectorSource.$source.getExtent(),
              //this.$refs.vectorSource.$source.getExtent(),
              {
                size: this.$refs.map.$map.getSize(),
                duration: 10,
              }
            );
          }
        }
      }
    },
    //{"layer_name": this.name, "gps_active":false}
    changeLayerGPSStatus: function (objLayerGPSStatus) {
      console.log("changeLayerGPSStatus");
      let layer = this.overlayLayers.find(
        (layer) => layer.name === objLayerGPSStatus.layer_name
      );
      if (layer != null) {
        layer.gps_active = objLayerGPSStatus.gps_active;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-title {
  position: absolute;
  right: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px;
}

::v-deep .ol-control button {
  background-color: rgb(113 207 219) !important;
}

.feature-popup {
  position: absolute;
  left: -50px;
  bottom: 12px;
  width: 20em;
  max-width: none;

  &:after, &:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  }
  .card-content {
    max-height: 20em;
    overflow: auto;
  }
</style>
