<template>
  <!--<v-container>
    <div>-->
  <div id="clock">
    <!--<v-icon class="mr-1" color="#FFFFFF"> mdi-clock-outline </v-icon>-->
    <span class="date weekday mr-4">{{ weekday }}</span>
    <span class="time mr-4">{{ time }}</span>
    <span class="date">{{ date }}</span>
  </div>
  <!--</div>
  </v-container>-->
</template>

<script>
export default {
  mounted() {
    setInterval(this.updateTime, 1000);
    this.updateTime();
  },
  data: () => ({
    time: "",
    date: "",
    weekday: "",
    week: [
      "Diumenge",
      "Dilluns",
      "Dimarts",
      "Dimecres",
      "Dijous",
      "Divendres",
      "Dissabte",
    ],
  }),
  methods: {
    updateTime() {
      var cd = new Date();
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ":" +
        this.zeroPadding(cd.getMinutes(), 2) +
        ":" +
        this.zeroPadding(cd.getSeconds(), 2);
      this.date =
        this.zeroPadding(cd.getDate(), 2) +
        "-" +
        this.zeroPadding(cd.getMonth() + 1, 2) +
        "-" +
        this.zeroPadding(cd.getFullYear(), 4);
      this.weekday = this.week[cd.getDay()];
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
  },
};
</script>
<style scoped>
#clock {
  border: 1.5px solid white;
  border-radius: 6px;
  text-align: center;
  background-color: rgb(113, 207, 219);
  /*background: radial-gradient(ellipse at center, #f0f4f5 0%, #71cfdb 70%);*/

  padding: 0px;
  font-family: "Share Tech Mono", monospace;
  color: #50585c;
  text-shadow: 0 0 20px rgb(10 175 230), 0 0 20px rgb(10 175 230 / 0%);
}
#clock .time {
  letter-spacing: 0.05em;
  font-size: 1rem;
  color: #ffffff;
  font-weight: bolder;
}
#clock .date {
  letter-spacing: 0.1em;
  font-size: 0.7rem;
}
#clock .text {
  letter-spacing: 0.1em;
  font-size: 12px;
  padding: 20px 0 0;
}
</style>
