<template>
  <div
    ref="draggableContainer"
    :key="componentKey"
    :class="[
      'draggableContainer',
      { draggableContainerCollapse: collapse },
      isMenuAtLeft ? 'draggableContainerLeft' : 'draggableContainerRight',
    ]"
  >
    <!-- <div v-if="colorPicker.visible" class="full" @mousedown="hideColorPicker" ></div> -->
    <Confirm ref="confirm" />
    <v-color-picker
      ref="colorPicker"
      v-show="colorPicker.visible"
      width="150"
      hide-inputs
      v-model="colorPicker.color"
      elevation="15"
      canvas-height="100"
      class="colorPicker"
      :style="[
        { left: colorPicker.positionX + 'px', top: colorPicker.clientY + 'px' },
      ]"
      @input="[changeLayerColor]"
      v-click-outside="{
        handler: hideColorPicker,
        include: includeOutsideClicks,
      }"
    ></v-color-picker>

    <div
      v-if="isMenuAtLeft"
      v-show="dynamicSearch.visible"
      class="searchContainer"
    >
      <div class="closeButton">
        <!-- <svg fill="#71cfdb" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">  <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg> -->

        <v-icon style="font-size: 23px !important" large v-on:click="toggle">
          mdi-close
        </v-icon>
      </div>
      <!-- <DynamicSearch  v-if="isMenuAtLeft" class="mt-4" v-on:search="setSearch" :jsonTemplate="dynamicSearch.jsonTemplate" /> -->
      <DynamicSearch
        ref="dynamicSearch"
        :title="dynamicSearch.title"
        :jsonTemplate="dynamicSearch.jsonTemplate"
        @search="dynamicSearchHandle"
      />

      <DynamicDataTable
        v-if="2 > 1"
        ref="datatable"
        :query="dynamicSearch.query"
        :dataTable_configuration="dynamicSearch.dataTableConfiguration"
        dataTable_height="default"
        @rowsSelected="dynamicTableRowsSelected"
        :deselectAllRows="dynamicSearch.deselectAllRows"
      >
      </DynamicDataTable>
      <!-- <NewDynamicDataTable
        v-if="2 > 11"
        :dataTable_configuration="dynamicSearch.dataTableConfiguration"
        dataTable_height="default"
        @rowSelected="dynamicTableRowSelected"
      ></NewDynamicDataTable> -->

      <div class="white">
        <div class="text-right">
          <v-row class="mr-n1" align="center" justify="end">
            <v-col
              cols="12"
              sm="6"
              md="3"
              v-show="
                dynamicSearch.title != 'Itineraris' &&
                dynamicSearch.title != 'Serveis'
              "
            >
              <v-text-field
                label="Nom de la consulta"
                v-model="dynamicSearch.loadAs"
              ></v-text-field>
            </v-col>

            <v-btn
              :disabled="
                dynamicSearch.loadAs == '' &&
                dynamicSearch.title != 'Itineraris' &&
                dynamicSearch.title != 'Serveis'
              "
              class="ma-2 white--text"
              color="rgb(38, 165, 46)"
              @click="copiarAlLayerSelector"
            >
              <v-icon dark left> mdi-content-copy </v-icon>Carregar en el mapa
            </v-btn>
          </v-row>
        </div>
      </div>
    </div>
    <v-card
      height="100%"
      tile
      fla
      elevation="4"
      style=""
      id="draggable-header"
    >
      <v-navigation-drawer permanent width="100%">
        <v-row class="fill-height" dense no-gutters>
          <v-navigation-drawer
            mini-variant
            permanent
            height="100%"
            mini-variant-width="40"
            :class="[
              isMenuAtLeft ? 'order-1' : 'order-2',
              { navigationDrawerBorderLeft: !isMenuAtLeft },
            ]"
          >
            <v-tooltip top >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  class="px-2 cursor-grab"
                  style="background-color: #71cfdb; min-height: 38px"
                  dark
                  @mouseup="handleCollapse"
                  @mousedown.stop="dragMouseDown"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-app-bar-nav-icon  class="cursor-grab">
                    <v-icon class="cursor-grab">{{ menu.layersMenu.icon }}</v-icon>
                  </v-app-bar-nav-icon>
                </v-list-item>
              </template>
              <span>{{ menu.layersMenu.title }}</span>
            </v-tooltip>
            <v-divider></v-divider>

            <v-list dense>
              <v-list-item-group
                mandatory
                v-model="selectedItem"
                color="primary"
              >
                <v-tooltip
                  v-for="layerGroup in menu.layersMenu.layersGroups"
                  :key="layerGroup.name"
                  :right="isMenuAtLeft"
                  :left="!isMenuAtLeft"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      link
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :value="layerGroup.name"
                    >
                      <v-list-item-action>
                        <v-icon>{{ layerGroup.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ layerGroup.title }}</span>
                </v-tooltip>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
          <!-- <v-list  v-if="selectedItem == 0 && !collapse" dense class="grow"> -->

          <template v-for="layerGroup in menu.layersMenu.layersGroups">
            <v-list
              :key="layerGroup.name"
              v-show="selectedItem == layerGroup.name && !collapse"
              dense
              :class="['grow', isMenuAtLeft ? 'order-2' : 'order-1']"
            >
              <v-list-item class="listItemPadding">
                <v-list-item-content>
                  <v-subheader>CAPES : {{ layerGroup.title }}</v-subheader>
                </v-list-item-content>
                <v-icon
                  v-for="action in layerGroup.actions"
                  :key="action.id"
                  :color="action.iconColor"
                  @click="clickAction(action, layerGroup.title)"
                >
                  {{ action.icon }}
                </v-icon>
              </v-list-item>

              <template v-if="layerGroup.layersType == 'base'">
                <v-divider></v-divider>
                <v-list-item-group v-model="selectedBaseLayer">
                  <v-list-item
                    v-for="layer in layerGroup.layers"
                    :key="layer.name"
                    :class="[
                      { layer_active: layer.visible },
                      'listItemPadding',
                    ]"
                    @click="showBaseLayer(layer.name)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ layer.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </template>
              <template v-else-if="layerGroup.layersType == 'overlay'">
                <template v-if="checkbox1">
                  <v-divider
                    :key="`${layerGroup.name}-ALL-divider`"
                  ></v-divider>
                  <v-list-item
                    :key="`${layerGroup.name}-ALL`"
                    :value="`${layerGroup.name}-ALL`"
                    class="listItemPadding"
                  >
                    <template v-slot:default="{}">
                      <v-list-item-action class="listItemActionMargin">
                        <v-checkbox
                          v-model="LayerGroupsWithAllSelected[layerGroup.name]"
                          @change="selectLayerGroup(layerGroup.name)"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          Visualitzar totes
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-icon
                        v-if="
                          'deletableAll' in layerGroup &&
                          layerGroup.deletableAll == true
                        "
                        color="grey lighten-1"
                        @click="deleteLayerGroup(layerGroup)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-list-item>
                </template>

                <template v-for="layer in layerGroup.layers">
                  <v-divider :key="`${layer.name}-divider`"></v-divider>
                  <v-list-item
                    :key="layer.name"
                    :value="layer.name"
                    class="listItemPadding"
                  >
                    <template v-slot:default="{}">
                      <v-list-item-action class="listItemActionMargin">
                        <v-checkbox
                          :input-value="layer.visible"
                          @change="showLayer($event, layer.name)"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ layer.title }}</v-list-item-title>
                      </v-list-item-content>
                      <v-icon
                        class="included"
                        v-if="'color' in layer"
                        :color="layer.color"
                        @click="
                          activateColorPicker(layer.color, layer.name, $event)
                        "
                      >
                        mdi-checkbox-blank
                      </v-icon>
                      <v-icon
                        v-if="'fitable' in layer && layer.fitable == true"
                        color="grey lighten-1"
                        @click="fitLayer($event, layer.name)"
                      >
                        mdi-map-marker
                      </v-icon>
                      <v-icon
                        v-if="'deletable' in layer && layer.deletable == true"
                        color="grey lighten-1"
                        @click="deleteLayer(layer.name, layer.title, false)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-list-item>
                </template>
              </template>
              <template v-else-if="layerGroup.layersType == 'overlayServices'">
                <template v-if="checkbox1">
                  <v-divider
                    :key="`${layerGroup.name}-ALL-divider`"
                  ></v-divider>
                  <v-list-item
                    :key="`${layerGroup.name}-ALL`"
                    :value="`${layerGroup.name}-ALL`"
                    class="listItemPadding"
                  >
                    <template v-slot:default="{}">
                      <v-list-item-action class="listItemActionMargin">
                        <v-checkbox
                          v-model="LayerGroupsWithAllSelected[layerGroup.name]"
                          @change="selectServiceGroup(layerGroup.name)"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          Visualitzar totes 
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-icon
                        v-if="
                          'deletableAll' in layerGroup &&
                          layerGroup.deletableAll == true
                        "
                        color="grey lighten-1"
                        @click="deleteServicesGroup(layerGroup.name)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-list-item>
                </template>
                <template v-for="service in layerGroup.services">
                  <div
                    :key="`${service.name}-ligroup`"
                    :style="{
                      'border-left':
                        '8px solid ' + colorServiceStatus(service.status),
                      'margin-top': '3px',
                    }"
                  >
                    <v-divider :key="`${service.name}-divider`"></v-divider>
                    <v-list-item
                      :key="service.name"
                      :value="service.name"
                      class="listItemPadding"
                    >
                      <template v-slot:default="{}">
                        <v-list-item-action class="listItemActionMargin">
                          <v-checkbox
                            :input-value="service.visible"
                            @change="showAllLayersService($event, service.id)"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            service.title
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-icon
                          v-if=" service.report_alerts_summary != 'null'"
                          :style="{ 'margin-right': '5px' }"
                        >
                          mdi-bell
                        </v-icon>
                        <span>{{
                          getDateFormat(service.date, "DD-MM-YYYY")
                        }}</span>

                        <!--<v-icon
                          class="included"
                          v-if="'color' in service.layers[0]"
                          :color="service.layers[0].color"
                          @click="
                            activateColorPicker(service.layers[0].color, service.layers[0].name, $event)
                          "
                        >
                          mdi-checkbox-blank
                        </v-icon>
                        <v-icon
                          v-if="'fitable' in service.layers[0] && service.layers[0].fitable == true"
                          color="grey lighten-1"
                          @click="fitLayer($event, service.layers[0].name)"
                        >
                          mdi-map-marker
                        </v-icon>
                        <v-icon
                          v-if="'deletable' in service.layers[0] && service.layers[0].deletable == true"
                          color="grey lighten-1"
                          @click="deleteServiceLayers(service.name, service.title, false)"
                        >
                          mdi-delete
                        </v-icon>-->
                      </template>
                    </v-list-item>
                    <!--<v-list-item
                      
                      class="listItemPadding"
                    >
                    <template v-slot:default="{}">
                    <v-list-item-content>
                          <v-list-item-title :style ="{'text-align':'right'}">{{ service.registration_id }}</v-list-item-title>
                          
                        </v-list-item-content>
                      </template>
                    </v-list-item>-->
                    <v-list-item class="listItemPadding">
                      <template v-slot:default="{}">
                        <v-list-item-content :style="{ display: 'inline' }">
                          <template v-for="layer in service.layers">
                            <v-chip
                              label
                              :key="`${layer.name}-chip`"
                              class="mr-1 chipLayerMenu"
                              active-class="chipLayerMenuActive"
                              :input-value="layer.visible"
                              @click="showLayerService(service.id, layer.name, service)"
                            >
                              {{ layer.title }}
                            </v-chip>
                          </template>
                        </v-list-item-content>
                        <v-icon
                          v-if="service.report_url != 'null'"
                          color="grey lighten-1"
                          @click="
                            openUrlDialog(service.report_url)
                          "
                        >
                          mdi-file
                        </v-icon>
                        <v-icon
                          class="included"
                          v-if="'color' in service.layers[0]"
                          :color="service.layers[0].color"
                          @click="
                            activateColorPicker(
                              service.layers[0].color,
                              service.layers[0].name,
                              $event
                            )
                          "
                        >
                          mdi-checkbox-blank
                        </v-icon>
                        <v-icon
                          v-if="
                            'fitable' in service.layers[0] &&
                            service.layers[0].fitable == true
                          "
                          color="grey lighten-1"
                          @click="fitServiceLayer($event, service.id)"
                        >
                          mdi-map-marker
                        </v-icon>
                        <v-icon
                          v-if="
                            'deletable' in service.layers[0] &&
                            service.layers[0].deletable == true
                          "
                          color="grey lighten-1"
                          @click="
                            deleteServiceLayers(
                              layerGroup.name,
                              service.id
                            )
                          "
                        >
                          mdi-delete
                        </v-icon>
                      </template>

                      <!--<v-list-item
                      :key="layer.name"
                      :value="layer.name"
                      class="listItemPadding"
                    >
                      <template v-slot:default="{}">
                        <v-list-item-action class="listItemActionMargin">
                          <v-checkbox
                            :input-value="layer.visible"
                            @change="showLayer($event, layer.name)"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{ layer.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-icon
                          class="included"
                          v-if="'color' in layer"
                          :color="layer.color"
                          @click="
                            activateColorPicker(layer.color, layer.name, $event)
                          "
                        >
                          mdi-checkbox-blank
                        </v-icon>
                        <v-icon
                          v-if="'fitable' in layer && layer.fitable == true"
                          color="grey lighten-1"
                          @click="fitLayer($event, layer.name)"
                        >
                          mdi-map-marker
                        </v-icon>
                        <v-icon
                          v-if="'deletable' in layer && layer.deletable == true"
                          color="grey lighten-1"
                          @click="deleteLayer(layer.name, layer.title, false)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-list-item>-->
                    </v-list-item>
                  </div>
                </template>
              </template>
              <template v-else>
                <LayerSelectorLocations
                  v-show="layerGroup.layersType == 'overlayLocations'"
                  ref="LayerSelectorLocationsRef"
                  @init="LayerSelectorLocationsInit"
                  :fractionsData="fractionsData"
                  @updateFractions="updateFractions"
                  @changeExtParamsLocations="changeExtParamsLocations"
                  @changeViewParamsLocations="changeViewParamsLocations"
                  @fractionsDataUpdate="fractionsDataUpdate"
                ></LayerSelectorLocations>
              </template>

              <!-- <template v-else-if="layerGroup.layersType == 'overlayLocations'">
                  <LayerSelectorLocations 
                    ref="LayerSelectorLocationsRef"
                    :fractionsProp="fractions"
                    @updateFractions="updateFractions"
                    @changeExtParamsLocations="changeExtParamsLocations"
                  ></LayerSelectorLocations>
                </template> -->
              <!-- <template v-else> -->

              <v-divider></v-divider>
            </v-list>
          </template>
        </v-row>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
  
import Confirm from "@/components/dialogs/Confirm";
import DynamicSearch from "@/components/dynamic/DynamicSearch";
import DynamicDataTable from "@/components/dynamic/DynamicDataTableMulti";
import LayerSelectorLocations from "@/components/dialogs/maps/LayerSelectorLocations";
// import NewDynamicDataTable from "@/components/dynamic/NewDynamicDataTable";
import datatablesconfiguration from "@/config/datatables.json";
import axios from "axios";

// import itineraris2 from '@/config/search/map/itineraris.json'

export default {
  name: "LayerSelector",
  components: {
    DynamicSearch,
    DynamicDataTable,
    LayerSelectorLocations,
    // NewDynamicDataTable,
    Confirm,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
    //paths: Array,
    //result: Array
  },
  inject: ["addLayerItem","getHeaders"],
  data: () => ({
    selectedItem: 0,
    selectedBaseLayer: [],
    //selectedOverlaysLayers: [],
    collapse: false,
    dragged:false,
    selectedpaths: [],
    menu_active: null,
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
    },
    overlayLayersData: [],
    checkbox1: true,
    componentKey: 0,
    //colorPickerVisible: false,
    //colorPickerLayerName: "",
    //color: "",
    colorPicker: {
      visible: false,
      layerName: "",
      color: "",
      clientY: 0,
      positionX: 0,
      clientWidth: 0,
    },
    dynamicSearch: {
      query: "",
      visible: false,
      inputsPerLayer: {},
      searchedOnce: {},
      jsonTemplate: {},
      fetchedData: [],
      itemsParaCopiar: [],
      selectedItems: [],
      dataTableConfiguration:
        datatablesconfiguration.maps.datatable_itineraries,
      loadAs: "",
      deselectAllRows: false,
    },
    // fractions: [],
    fractionsData: {
      fractions: [],
      incoherence: true,
      radioGroupRealEstimated: "real",
      fractionsSelected: [],
      selectAll: true,
      contractorCodeLabel: false,
    },

    LayerGroupsWithAllSelected: {
      // ITINERARIES: true,
      // LOCATIONS: true,
      // ELEMENTS: true,
      // SERVICES:true
    },
    // LayerGroupsWithAllSelectedNuevo:{
    //   SERVICES:true
    // }
    // LayerGroupsWithAllSelectedService:false
  }),
  created() {
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);
  },
  async mounted() {
    let obj = {};
    this.menu.layersMenu.layersGroups.map((layerGroup) => {
    if ( ["overlay","overlayServices"].includes(layerGroup.layersType) )  obj[layerGroup.name] = true; 
    return;
    // if (layerGroup.layersType != "overlay" && layerGroup.layersType != "overlayServices") return;
  });

    // this.menu.layersMenu.layersGroups.map((layerGroup) => {
    //   if (layerGroup.layersType != "overlayServices") return;
    //   obj[layerGroup.name] = true; 
    // });

    this.LayerGroupsWithAllSelected = obj;

    this.refreshLayerGroupSelectAll();

    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.colorPicker.visible = false;
      }
    });
  },
  computed: {
    isMenuAtLeft: function () {
      return this.menu.layersMenu.side == "left";
    },

    changeLayerColor: function () {
      let overlayLayers = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlay"
      );
      //console.log(overlayLayers);
      let layers = overlayLayers.map((layerGroup) =>
        layerGroup.layers ? layerGroup.layers : false
      );

      layers = layers.flat();

      /*Para cuando se cambie color a la capa de path de servicios*/
      let overlayLayersServices = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlayServices"
      );
      //console.log(overlayLayers);
      let services = overlayLayersServices.map((layerGroup) =>
        layerGroup.services ? layerGroup.services : false
      );
      let mergedServices = services.flat();

      let layersServices = mergedServices.map((service) =>
        service.layers ? service.layers : false
      );
      let mergedLayersServices = layersServices.flat();

      let allLayers = layers.concat(mergedLayersServices);

      /*Fin Para cuando se cambie color a la capa de path de servicios*/

      let layer = allLayers.find(
        (layer) => layer.name === this.colorPicker.layerName
      );

      if (layer != null) {
        layer.color = this.colorPicker.color;
      }

      //this.colorPicker.visible=false;
      //this.$emit("changeLayerColor");
      return this.colorPicker.color;
    },
  },
  methods: {
    getDateFormat: function (date, format) {
      let returnDate = "";

      if (date != null) {
        returnDate = this.$date(date).format(format);
      }

      return returnDate;
    },
    colorServiceStatus(serviceStatus) {
      let color = "#FFFFFF";
      switch (serviceStatus) {
        case "Scheduled":
          color = "rgb(89, 95, 89)";
          break;
        case "Executing":
          color = "rgb(38, 165, 46)";
          break;
        case "Ended":
          color = "rgb(227, 128, 49)";
          break;
        case "Unstarted":
          color = "rgb(193, 18, 18)";
          break;
      }
      return color;
    },
    refreshLayerGroupSelectAll() {
      //  let overlayLayers = this.menu.layersMenu.layersGroups.find(
      //     (layerGroup) => layerGroup.layersType == "overlay"
      //   );

      // let layerIndex = overlayLayers.layers.findIndex(
      //   (layer) => layer.visible === false
      // );

      //console.log("test1");
      this.menu.layersMenu.layersGroups.map((layerGroup) => {
        let encontradoUnselected;
        if (layerGroup.layersType == "overlay") {
          if (layerGroup.name == "ITINERARIES") {
            // console.log(layerGroup.name);
            //console.log(layerGroup.layers);
            // console.log(encontradoUnselected);
            //console.log("---");
          }
          // if this.LayerGroupsWithAllSelected[layerGroup.name]

          encontradoUnselected = layerGroup.layers.find(
            (layer) => layer.visible == false
          );
        }

        // console.log(layerGroup.layers);
        // console.log(encontradoUnselected);

        if (encontradoUnselected) {
          //console.log("this.LayerGroupsWithAllSelected false");
          this.LayerGroupsWithAllSelected[layerGroup.name] = false;
        } else {
          //console.log("this.LayerGroupsWithAllSelected true");

          this.LayerGroupsWithAllSelected[layerGroup.name] = true;
        }
      });
    },
    fractionsDataUpdate(valNew) {
      //console.log("fractionsDataUpdate");
      //console.log(valNew);
      this.fractionsData = valNew;
    },
    async LayerSelectorLocationsInit() {
      //console.log("LayerSelectorLocationsInit");

      // console.log(this.$refs);
      // console.log(this.$refs.LayerSelectorLocationsRef );

      await this.$refs.LayerSelectorLocationsRef[0].createMenu();
      await this.$refs.LayerSelectorLocationsRef[0].selectAllFractions(true);
    },
    updateFractions(valNew) {
      // console.log("emit2");
      // console.log(valNew);
      this.fractionsData.fractions = valNew;
    },
    changeExtParamsLocations(wmsLocationsExtParams) {
      this.$emit("changeExtParamsLocations", wmsLocationsExtParams);
    },
    changeViewParamsLocations(wmsLocationsViewParams) {
      this.$emit("changeViewParamsLocations", wmsLocationsViewParams);
    },
    dynamicTableRowSelected(el) {
      //unused
      let toAdd = {
        name: "PATH-" + el[2].value,
        type: "PATH",
        title: el[2].value,
        langTitle: el[2].value,
        component: "LayerPath",
        visible: true,
        path_id: el[0].value,
        color: "#00b1ea",
        fitable: true,
        deletable: true,
      };
      this.dynamicSearch.itemaParaCopiar = [toAdd];
      // this.$emit("dynamicTableRowSelected", el);
    },
    dynamicTableRowsSelected(els) {
      this.dynamicSearch.selectedItems = els;
    },
    dynamicTableRowsSelected2(els) {
      let itemsParaCopiar = [];
      switch (this.dynamicSearch.title) {
        case "Itineraris":
          var randomColor = "";
          els.forEach((el) => {
            randomColor = "#000000".replace(/0/g, function () {
              return (~~(Math.random() * 16)).toString(16);
            });
            itemsParaCopiar.push({
              name: "PATH-" + el.itinerary_contractor_code,
              type: "PATH",
              title: el.itinerary_contractor_code + " - " + el.path_init_date,
              langTitle: el.itinerary_contractor_code,
              component: "LayerPath",
              visible: true,
              path_id: el.id,
              color: randomColor,
              fitable: true,
              deletable: true,
            });
          });
          break;
        case "Ubicacions":
          {
            const locationsContractorCodes = els.map(
              (el) => el.contractor_code
            );
            //console.log(locationsContractorCodes);
            randomColor = "#000000".replace(/0/g, function () {
              return (~~(Math.random() * 16)).toString(16);
            });
            itemsParaCopiar.push({
              name: "LOCATIONS-" + this.dynamicSearch.loadAs,
              type: "LOCATIONS",
              title: this.dynamicSearch.loadAs,
              component: "LayerLocations",
              visible: true,
              locationsContractorCode: locationsContractorCodes,
              color: randomColor,
              fitable: true,
              zindex: "7",
              deletable: true,
            });
          }
          break;
        case "Elements":
          {
            // const locationsContractorCodes = els.map(el=>el.location_contractor_code)
            const locationsContractorCodes = els.map(
              (el) => el.location_contractor_code
            );

            //console.log(locationsContractorCodes);
            randomColor = "#000000".replace(/0/g, function () {
              return (~~(Math.random() * 16)).toString(16);
            });
            itemsParaCopiar.push({
              name: "ELEMENTS-" + this.dynamicSearch.loadAs,
              type: "ELEMENTS",
              title: this.dynamicSearch.loadAs,
              component: "LayerLocations",
              visible: true,
              locationsContractorCode: locationsContractorCodes,
              color: randomColor,
              fitable: true,
              zindex: "8",
              deletable: true,
            });
          }
          break;
        case "Serveis":
          els.forEach((el) => {
            randomColor = "#000000".replace(/0/g, function () {
              return (~~(Math.random() * 16)).toString(16);
            });
            //Serveis
            let itemsToPush = []

            if(el.vehicle_position_0 != null && el.vehicle_position_0 != "null" && el.vehicle_position_0  != ""){
              itemsToPush.push(
                {
                  name: `SERVICE-${el.service_id}-SERVICEGPS-${el.service_id}`,
                  type: "SERVICEGPS",
                  title: "GPS",
                  component: "LayerServiceGPS",
                  visible: true,
                  service_id: el.service_id,
                },
              )
            }
            if(el.report_url != null && el.report_url != "null" && el.report_url  != ""){
              itemsToPush.push(
                {
                  name: `SERVICE-${el.service_id}-SERVICEANALYSIS-${el.service_id}`,
                  type: "SERVICEANALYSIS",
                  title: "Anàlisi",
                  component: "LayerServiceAnalysis",
                  visible: true,
                  service_id: el.service_id,
                  path_id: el.path_id,
                },
              )
            }

            itemsParaCopiar.push({
              id: el.service_id,
              name: "SERVICE-" + el.service_id,
              title: el.itinerary_contractor_code,
              date: el.service_starting_timestamp,
              registration_id: el.vehicle_contractor_code,
              status: el.service_status,
              report_url: el.report_url,
              vehicle_position_0:el.vehicle_position_0,
              report_alerts_summary: el.report_alerts_summary,
              visible: true,
              fitable: true,
              deletable: true,
              layers: [
                {
                  name: `SERVICE-${el.service_id}-PATH-${el.itinerary_contractor_code}`,
                  type: "PATH",
                  title: "Itinerari",
                  langTitle: el.itinerary_contractor_code,
                  component: "LayerPath",
                  visible: true,
                  path_id: el.path_id,
                  color: randomColor,
                  fitable: true,
                  deletable: true,
                },
                ...itemsToPush
                // {
                //   name: `SERVICE-${el.service_id}-SERVICEGPS-${el.service_id}`,
                //   type: "SERVICEGPS",
                //   title: "GPS",
                //   component: "LayerServiceGPS",
                //   visible: true,
                //   service_id: el.service_id,
                // },
                // {
                //   name: `SERVICE-${el.service_id}-SERVICEANALYSIS-${el.service_id}`,
                //   type: "SERVICEANALYSIS",
                //   title: "Anàlisi",
                //   component: "LayerServiceAnalysis",
                //   visible: true,
                //   service_id: el.service_id,
                //   path_id: el.path_id,
                // },
              ],
            });
          });

          break;
      }

      this.dynamicSearch.itemsParaCopiar = itemsParaCopiar;
    },
    copiarAlLayerSelector() {
      if (
        this.dynamicSearch.loadAs == "" &&
        this.dynamicSearch.title != "Itineraris" &&
        this.dynamicSearch.title != "Serveis"
      ) {
        alert("Introdueix nom de la consulta");
        return;
      }

      //TODO
      // if (this.dynamicSearch.selectedItems.length==0) {
      //   alert("Señeciona algo")
      //   return;
      // }

      this.dynamicTableRowsSelected2(this.dynamicSearch.selectedItems);

      this.dynamicSearch.itemsParaCopiar.forEach((el) => {
        this.addLayerItem(el, this.dynamicSearch.title);
      });
      // this.dynamicSearch.selectedItems = [];
      // return;
      // switch(this.dynamicSearch.title){
      //   case "Itineraris":
      //         //
      //     break;
      //   case "Ubicacions":
      //         //
      //     break;
      //   case "Elements":
      //         //
      //     break;
      // }
      // this.addLayerItem(this.dynamicSearch.itemaParaCopiar[0]);
    },
    toggle() {
      this.dynamicSearch.visible = !this.dynamicSearch.visible;
    },
    async dynamicSearchHandle(formData) {
      console.log("yeye");
      this.dynamicSearch.searchedOnce[this.dynamicSearch.title] = true;
      //console.log(this.dynamicSearch.title);
      //  (new URLSearchParams(formData).toString());
      let query = "";
      let querys = [];
      // this.dynamicSearch.dataTableConfiguration.changeData = "s"+Math.random();
      
      switch (this.dynamicSearch.title) {
        case "Itineraris":
          formData.codigo
            ? querys.push("itinerary_contractor_code=" + formData.codigo)
            : "";
          formData.date_inici
            ? querys.push(
                "initialday=" + this.changeDateFormat(formData.date_inici)
              )
            : "";
          formData.date_fi
            ? querys.push("finalday=" + this.changeDateFormat(formData.date_fi))
            : "";
          query = querys.join("&");

          //console.log(query);
          // this.dynamicSearch.dataTableConfiguration = datatablesconfiguration.maps.datatable_itineraries
          this.dynamicSearch.dataTableConfiguration.data_url = `/api/v1/path/search/?${query}`;
          // `http://localhost/api/v1/itinerary/services?${query}`;
          // `http://localhost/api/v1/itinerary/services?itinerary_contractor_code=${(formData.codigo||"")}&initialday=${this.changeDateFormat(formData.date_inici)}&finalday=${this.changeDateFormat(formData.date_fi)}`;
          break;
        case "Ubicacions":
          // this.dynamicSearch.dataTableConfiguration = datatablesconfiguration.maps.datatable_locations
          this.dynamicSearch.dataTableConfiguration.data_url =
            "/api/v1/location/extended?contractor_code=" +
            (formData.code || "");
          break;
        case "Elements":
          formData.codi ? querys.push("contractor_code=" + formData.codi) : "";
          formData.rfid ? querys.push("rfid=" + formData.rfid) : "";
          query = querys.join("&");

          this.dynamicSearch.dataTableConfiguration.data_url = `/api/v1/container/extended?${query}`;
          break;
        // `http://localhost/api/v1/container/extended?location_contractor_code=${formData.codi||""}&rfid=${formData.rfid||""}`;
        case "Serveis":
          formData.initialday
            ? querys.push(
                "initialday=" + this.changeDateFormat(formData.initialday)
              )
            : "";
          formData.finalday
            ? querys.push(
                "finalday=" + this.changeDateFormat(formData.finalday)
              )
            : "";
          formData.itinerary_contractor_code
            ? querys.push(
                "itinerary_contractor_code=" +
                  formData.itinerary_contractor_code
              )
            : "";
          formData.vehicle_contractor_code
            ? querys.push(
                "vehicle_contractor_code=" + formData.vehicle_contractor_code
              )
            : "";
          formData.itinerary_type_code
            ? querys.push("itinerary_type_code=" + formData.itinerary_type_code)
            : "";
          formData.from_service_starting_timestamp
            ? querys.push(
                "from_service_starting_timestamp=" +
                  this.changeDateFormat(
                    formData.from_service_starting_timestamp
                  )
              )
            : "";
          formData.to_service_starting_timestamp
            ? querys.push(
                "to_service_starting_timestamp=" +
                  this.changeDateFormat(formData.to_service_starting_timestamp)
              )
            : "";
          formData.Scheduled
            ? formData.Scheduled == "true"
              ? querys.push("service_status=Scheduled")
              : ""
            : "";
          formData.Executing
            ? formData.Executing == "true"
              ? querys.push("service_status=Executing")
              : ""
            : "";
          formData.Ended
            ? formData.Ended == "true"
              ? querys.push("service_status=Ended")
              : ""
            : "";
          formData.Unstarted
            ? formData.Unstarted == "true"
              ? querys.push("service_status=Unstarted")
              : ""
            : "";

          query = querys.join("&");

          this.dynamicSearch.dataTableConfiguration.data_url = `/api/v1/service/summary?${query}`;
          break;
      }

      this.$refs.datatable.reloadItems()
      // this.dynamicSearch.fetchedData =
      // let res = await fetch("http://localhost:3001/itineraries?q=8331").then(response => response.json());

      // this.dynamicSearch.fetchedData = res
    },
    changeDateFormat(input) {
      if (input == undefined) return "";
      // return input
      console.log(input);
      return   this.$date(input).format("YYYY-MM-DD");

      // this.$date().format("l")
      
      //2022-07-13 -> 13/07/2022
      // let date = new Date(input);
      // let year = date.getFullYear();
      // let month = (1 + date.getMonth()).toString().padStart(2, "0");
      // let day = date.getDate().toString().padStart(2, "0");
      // return day + "/" + month + "/" + year;
    },
    clickAction: async function (action, title) {
      //console.log(this.$refs.dynamicSearch.searchInputs);
      this.dynamicSearch.inputsPerLayer[this.dynamicSearch.title || title] =
        this.$refs.dynamicSearch.searchInputs;

      if (this.dynamicSearch.inputsPerLayer[title]) {
        //console.log(this.dynamicSearch.inputsPerLayer[title]);
        this.$refs.dynamicSearch.searchInputs =
          this.dynamicSearch.inputsPerLayer[title];

        this.$refs.dynamicSearch.setInputs(
          this.dynamicSearch.inputsPerLayer[title]
        );
        this.dynamicSearch.title = title;
        this.dynamicSearchHandle(this.dynamicSearch.inputsPerLayer[title]);
      }
      // console.log(this.dynamicSearch.title||title);
      // console.log(this.dynamicSearch.inputsPerLayer[title]);
      // console.log(title);
      // if (this.dynamicSearch.searchedOnce[title]) {
      //   // this.dynamicSearchHandle(this.dynamicSearch.inputsPerLayer[title])
      // }

      // this.$refs.dynamicSearch.searchInputs = this.dynamicSearch.inputsPerLayer[title]

      this.dynamicSearch.visible = true;
      this.dynamicSearch.title = title;
      this.dynamicSearch.deselectAllRows = true;

      if (action.component == "DynamicSearch") {
        // this.dynamicSearch.jsonTemplate = itineraris2;
        console.log("action.jsonTemplatePath");
        console.log(action.jsonTemplatePath);
        import(`@/config/${action.jsonTemplatePath}`).then((module) => {
          //console.log(module);
          this.dynamicSearch.jsonTemplate = module.default;
        });
      }
      switch (this.dynamicSearch.title) {
        case "Itineraris":
          this.dynamicSearch.dataTableConfiguration =
            datatablesconfiguration.maps.datatable_itineraries;
          // this.dynamicSearch.dataTableConfiguration.data_url = "/api/v1/path/search"
          break;
        case "Ubicacions":
          this.dynamicSearch.dataTableConfiguration =
            datatablesconfiguration.maps.datatable_locations;
          //  this.dynamicSearch.dataTableConfiguration.data_url = "/api/v1/location/extended";
          break;
        case "Elements":
          this.dynamicSearch.dataTableConfiguration =
            datatablesconfiguration.maps.datatable_elements;
          //  this.dynamicSearch.dataTableConfiguration.data_url = "/api/v1/container/extended";

          break;
        case "Serveis":
          this.dynamicSearch.dataTableConfiguration =
            datatablesconfiguration.maps.datatable_services;
      }
      this.dynamicSearch.dataTableConfiguration.data_url = ""; // no mostrar nada por defeto
      this.dynamicSearch.deselectAllRows = false;
    },
    hideColorPicker: function () {
      // alert("hola")
      this.colorPicker.visible = false;
    },
    handleCollapse: function () {
      if (this.dragged) {
        this.dragged = false
        return
      }
      if (!this.isMenuAtLeft) {
        let margin = 300
        if (!this.collapse) {
          this.$refs.draggableContainer.style.left =
            parseInt(this.$refs.draggableContainer.style.left) + margin + "px";
        } else {
          this.$refs.draggableContainer.style.left =
            parseInt(this.$refs.draggableContainer.style.left) - margin + "px";
        }
        this.collapse = !this.collapse;
      } else {
        this.collapse = !this.collapse;
      }
    },
    includeOutsideClicks() {
      return [...document.querySelectorAll(".included")];
    },
    activateColorPicker: function (color, layer_name, event) {
      this.colorPicker.visible = true;
      this.colorPicker.color = color;
      this.colorPicker.layerName = layer_name;

      this.colorPicker.clientY =
        event.target.parentElement.getBoundingClientRect().top - 2;
      //this.colorPicker.clientX= event.clientX;
      this.colorPicker.clientWidth = window.innerWidth;
      //console.log("event.target.parent.offsetTop");
      //console.log(event.target.parentElement);
      //console.log(event.target.parentElement.offsetTop);

      //console.log(parseInt(this.$refs.draggableContainer.style.left, 10));
      //console.log(this.$refs.draggableContainer.clientWidth);
      // this.colorPicker.positionX =
      //   this.$refs.draggableContainer.offsetLeft +
      //   this.$refs.draggableContainer.clientWidth + 50;
      if (this.isMenuAtLeft) {
        this.colorPicker.positionX =
          event.target.parentElement.getBoundingClientRect().x +
          event.target.parentElement.getBoundingClientRect().width +
          10;
      } else {
        //console.log("menu derecha");
        //console.log(this.$refs.colorPicker);
        //console.log(this.$refs.colorPicker.clientWidth + " es el width");
        this.colorPicker.positionX =
          event.target.parentElement.getBoundingClientRect().x -
          this.$refs.colorPicker.width -
          10;
      }
      return 0;
    },
    getMaxZIndex: function () {
      return Math.max(
        ...Array.from(document.querySelectorAll("body *"), (el) =>
          parseFloat(window.getComputedStyle(el).zIndex)
        ).filter((zIndex) => !Number.isNaN(zIndex)),
        0
      );
    },
    forceRerender: function () {
      this.componentKey += 1;
    },
    /*recalcularSelectedOverlaysLayers: function () {
      let overlayLayers = this.menu.layersMenu.layersGroups.find(
        (layerGroup) => layerGroup.layersType == "base"
      );
      this.selectedOverlaysLayers = overlayLayers
        .filter((layer) => layer.visible == true)
        .map(function (layer) {
          return layer.name;
        });
    },*/
    showBaseLayer: function (layer_name) {
      let baseLayers = this.menu.layersMenu.layersGroups.find(
        (layerGroup) => layerGroup.layersType == "base"
      );
      if (baseLayers == undefined) return;

      baseLayers = baseLayers.layers;

      let layer = baseLayers.find((layer) => layer.visible);
      if (layer != null) {
        layer.visible = false;
        //layer = Object.assign({}, layer, { visible: false });
      }

      layer = baseLayers.find((layer) => layer.name === layer_name);
      if (layer != null) {
        layer.visible = true;
        //layer = Object.assign({}, layer, { visible: true });
      }
    },
    showLayer: function (show, layer_name) {
      let overlayLayers = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlay"
      );

      let layers = overlayLayers.map((layerGroup) => layerGroup.layers).flat();
      let layer = layers.find((layer) => layer.name === layer_name);

      if (layer != null) {
        if (show) {
          if (!layer.visible) layer.visible = true;
        } else {
          layer.visible = false;
        }
      }
      this.refreshLayerGroupSelectAll();
      // console.log(this.refreshLayerGroupSelectAll());
      //this.recalcularSelectedOverlaysLayers();
    },
    showAllLayersService: function (show, service_id) {
      //
      console.log("changed showAllLayersService");
      let overlayServices = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlayServices"
      );

      let services = overlayServices
        .map((layerGroup) => layerGroup.services)
        .flat();
      let service = services.find((service) => service.id === service_id);
      if (service != null) {
        for (let i = 0; i < service.layers.length; i++) {
          let layer = service.layers[i];
          if (layer != null) {
            if (show) {
              if (!layer.visible) layer.visible = true;
            } else {
              layer.visible = false;
            }
          }
        }
      }

      if(show){
        service.visible = true;
      }
      else{
        service.visible = false;
      }
      console.log(overlayServices.name);
      console.log(overlayServices);
      console.log(service);

      this.calculateServiceGroupValue()
    },
    showLayerService: function (service_id, layer_name) {
      console.log("changed");
      let overlayServices = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlayServices"
      );

      let services = overlayServices
        .map((layerGroup) => layerGroup.services)
        .flat();
      let service = services.find((service) => service.id === service_id);
      if (service != null) {
        let layer = service.layers.find((layer) => layer.name === layer_name);
        if (layer != null) {
          if (!layer.visible) {
            layer.visible = true;
          } else {
            layer.visible = false;
          }
        }

        service.visible = Boolean(service.layers.find((layer) => layer.visible === true))
        console.log(service.visible);
        this.calculateServiceGroupValue();
      }
      
      //BUSCO SERVICE_ID en el menu layers
      // service

      // this.refreshLayerGroupSelectAll();

      /*let layers = overlayLayers.map((layerGroup) => layerGroup.layers).flat();
      let layer = layers.find((layer) => layer.name === layer_name);

      if (layer != null) {
        if (show) {
          if (!layer.visible) layer.visible = true;
        } else {
          layer.visible = false;
        }
      }*/
      //this.refreshLayerGroupSelectAll();
      // console.log(this.refreshLayerGroupSelectAll());
      //this.recalcularSelectedOverlaysLayers();
    },

    fitLayer: function (show, layer_name) {
      //console.log("layer");
      let overlayLayers = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlay"
      );
      //console.log(overlayLayers);
      let layers = overlayLayers.map((layerGroup) =>
        layerGroup.layers ? layerGroup.layers : false
      );

      layers = layers.flat();

      let layer = layers.find((layer) => layer.name === layer_name);
      //console.log(layer);
      if (layer != null) {
        if (show) {
          this.$emit("fitLayerClick", layer_name);
        }
      }
    },

    fitServiceLayer: function (show, service_id) {
      let overlayServices = this.menu.layersMenu.layersGroups.filter(
        (layerGroup) => layerGroup.layersType == "overlayServices"
      );

      let services = overlayServices
        .map((layerGroup) => layerGroup.services)
        .flat();
      let service = services.find((service) => service.id === service_id);

      if (service != null) {
        if (show) {
          this.$emit("fitLayerClick", service.layers[0].name);
        }
      }
    },
    deleteLayer: async function (layer_name, layer_title, skipConfirmation) {
      if (
        skipConfirmation ||
        (await this.$refs.confirm.open(
          "Confirma",
          `Desitja esborrar la capa ${layer_title} ?`
        ))
      ) {
        //Delete layer of map
        this.$emit("deleteLayerClick", layer_name);

        //Delete layer of menu

        let overlayLayers2 = this.menu.layersMenu.layersGroups.filter(
          (layerGroup) => layerGroup.layersType == "overlay"
        );

        overlayLayers2.forEach((OL) => {
          let layerIndex = OL.layers.findIndex(
            (layer) => layer.name === layer_name && layer.deletable === true
          );

          if (layerIndex != -1) {
            OL.layers.splice(layerIndex, 1);
          }
        });
      }
    },
     deleteServiceLayers: async function(layerGroup_name, service_id, skip_confirmation){
      let layerGroup = this.menu.layersMenu.layersGroups.find(
        (layerGroup) => layerGroup.name == layerGroup_name
      ); 

      let service= layerGroup.services.find((service) => service.id === service_id);

      //Pedir confirmación de si queremos eliminar el servicio
      if ( skip_confirmation ||
        (await this.$refs.confirm.open(
          "Confirma",
          `Desitja esborrar el servei ${service.title} ?`
        ))
      ){
        //eliminar las capas del mapa
        service.layers.map(layer =>{
          // this.deleteLayer(layer)
          //Delete layer of map
          this.$emit("deleteLayerClick", layer.name);

        })
        //Eliminar el servicio del menu
    
        let serviceIndex = layerGroup.services.findIndex(
          (service) => service.id === service_id && service.deletable === true
        );

        if (serviceIndex != -1) {
          layerGroup.services.splice(serviceIndex, 1);
        } 
      }
       
       
      
    },
    selectServiceGroup: function (layerGroup_name){
      let layerGroup = this.menu.layersMenu.layersGroups.find(
        (layerGroup) => layerGroup.name == layerGroup_name
      );
      let visible = this.LayerGroupsWithAllSelected[layerGroup_name];
      layerGroup.services.map((service) => {
        service.visible = visible;
        
        service.layers.map((layer)=>{
          layer.visible = visible;
        })
      });
     

    }, 
    calculateServiceGroupValue: function (){ 
      let layerGroup = this.menu.layersMenu.layersGroups.find(
        (layerGroup) => layerGroup.layersType == "overlayServices"
      ); 
      let value = Boolean(layerGroup.services.find((service) => service.visible === true))

       this.LayerGroupsWithAllSelected[layerGroup.name] = value;
       console.log(value);

      //  console.log(value);
    }, 
    
    selectLayerGroup: function (layerGroup_name) {
      let layerGroup = this.menu.layersMenu.layersGroups.find(
        (layerGroup) => layerGroup.name == layerGroup_name
      );
      let visible = this.LayerGroupsWithAllSelected[layerGroup_name];
      layerGroup.layers.map((layer) => {
        layer.visible = visible;
      });
    },
    deleteLayerGroup: async function (layerGroup) {
      if (
        await this.$refs.confirm.open(
          "Confirma",
          `Desitja esborrar totes les capes de ${layerGroup.title} ?`
        )
      ) {
        //Delete layer of map
        layerGroup.layers = [];

        const layerNames = layerGroup.layers.map((layer) => layer.name);

        this.$emit("deleteLayersClick", layerNames);
      }
    },
    /*handleclick: function (index) {
      if (this.selectedpaths.includes(index)) {
        this.selectedpaths.splice(this.selectedpaths.indexOf(index), 1);
      } else {
        this.selectedpaths.push(index);
      }

      this.$emit("selectedpath", index);
    },*/
    dragMouseDown: function (event) { 
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      this.dragged = true
      console.log({...this.positions});
      let isLeft = (this.$refs.draggableContainer.classList.contains("draggableContainerLeft"));
      event.preventDefault();
      
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      // set the element's new position:
      if (isLeft) {
        //horizontal postition
        let leftStyle = this.$refs.draggableContainer.offsetLeft - this.positions.movementX
        if(leftStyle >=0 &&
          this.positions.clientX>0 && this.positions.clientX < window.innerWidth - this.$refs.draggableContainer.offsetWidth  
          ){
          console.log("changing");
          this.$refs.draggableContainer.style.left = leftStyle+"px"
        } 
        //vertical postition

        let topStyle = this.$refs.draggableContainer.offsetTop - this.positions.movementY
        if (topStyle>0 && this.positions.clientY>75 &&
          this.positions.clientY < window.innerHeight-this.$refs.draggableContainer.offsetHeight
        ) {
          this.$refs.draggableContainer.style.top = topStyle + "px"
        }  
      }else{
        //horizontal postition
        let leftStyle = this.$refs.draggableContainer.offsetLeft - this.positions.movementX
        if(leftStyle>=0 && leftStyle<(window.innerWidth-this.$refs.draggableContainer.offsetWidth) &&
          this.positions.clientX>this.$refs.draggableContainer.offsetWidth && this.positions.clientX < window.innerWidth   
          ){
          console.log("changing");
          this.$refs.draggableContainer.style.left = leftStyle+"px"
        } 
        //vertical postition
        let topStyle = this.$refs.draggableContainer.offsetTop - this.positions.movementY
        if (topStyle>0 && this.positions.clientY>75 &&
          this.positions.clientY < window.innerHeight-this.$refs.draggableContainer.offsetHeight
        ) {
          this.$refs.draggableContainer.style.top = topStyle + "px"
        }  
      }
      
       

       // set the element's new position (old ): 
      // this.$refs.draggableContainer.style.left =
      //   this.$refs.draggableContainer.offsetLeft -
      //   this.positions.movementX +
      //   "px";
        // this.$refs.draggableContainer.style.top =
      //   this.$refs.draggableContainer.offsetTop -
      //   this.positions.movementY +
      //   "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },

    async openUrlDialog(url){
      let height = window.innerHeight * 0.80
      let width = window.innerWidth * 0.80 
      var left = (window.innerWidth/2)-(width/2);
      var top = (window.innerHeight/2)-(height/2)
      let sizing = `width=${width},height=${height},top=${top},left=${left}`
      var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,"+sizing);
        
      let HTML = await axios
        .get(url.replace("json","html"), this.getHeaders()  )
        .then((response) => { 
          return response.data 
        }); 
        win.document.body.innerHTML = HTML;
 
    },
    async deleteServicesGroup(layerGroupName){

      if (
        await this.$refs.confirm.open(
          "Confirma",
          `Desitja esborrar totes les capes de Serveis ?`
        )
      ) {
        let array = []
        let layerGroup = this.menu.layersMenu.layersGroups.find(
          (layerGroup) => layerGroup.name == layerGroupName
        );
        layerGroup.services.map((service) => {
          let layerNames =  service.layers.map(layer=>layer.name)
          array = [...array,...layerNames]
        });
        layerGroup.services=[] 
        this.$emit("deleteLayersClick", array);
      }
    }
  },
  watch: {
    /*overlayLayers: function () {
      this.recalcularSelectedOverlaysLayers();
    },*/
  }
};
</script>
<style scoped>
.draggableContainerCollapse {
  width: 40px !important;
}

.draggableContainer {
  width: 340px;
  height: 60%;
  top: 10vh;
  position: absolute;
  z-index: 9;
}

.draggableContainerLeft {
  left: 1vh;
}

.draggableContainerRight {
  right: 1vh;
}

.max-width-chip.v-chip {
  margin: 0px !important;
  margin-left: 4px;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

#draggable-container {
  /*width: 100% !important ;*/
  /*height: 50%;*/

  /* right: 3vh; */
}

#draggable-header {
  z-index: 10;
}

.v-toolbar__title {
  font-size: 1rem;
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  font-size: 1.2rem;
}

.v-list-item__title {
  font-size: 0.8rem !important;
}

.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 24px !important;
}

.v-list--dense .v-subheader {
  height: 37px;
}

.v-list--three-line .v-list-item .v-list-item__avatar,
.v-list--three-line .v-list-item .v-list-item__action,
.v-list-item--three-line .v-list-item__avatar,
.v-list-item--three-line .v-list-item__action {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.v-list-item__content {
  padding: 0px 0 !important;
}

/deep/ .v-icon.v-icon {
  font-size: 1.1rem !important;
}

.v-label {
  font-size: 0.8rem !important;
}

.navigationDrawerBorderLeft {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.cursor-grab{
  cursor: grab;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 0.8rem !important;
}

.layer_active {
  background-color: #e2e2e2;
}

/deep/ .bg-active {
  background-color: white;
}

.v-list-item__action {
  margin: 6px 0;
}

.v-list {
  padding: 0 0;
}

.listItemPadding {
  padding: 0px 8px !important;
  min-height: 36px !important;
}

.listItemActionMargin {
  margin-right: 8px !important;
}

.colorPicker {
  /* position: absolute; */
  position: fixed;
  z-index: 12;
}

.full {
  position: fixed;
  height: 100%;
  background-color: transparent;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
}

.hidden {
  display: none;
}

.searchContainer {
  position: absolute;
  /* width: 862px; */
  width: 1000px;
  left: 355px;
  padding-bottom: 10px;
}

.closeButton {
  position: inherit;
  top: 7px;
  right: 0px;
  z-index: 2;
  height: 40px;
  width: 40px;
  color: #2759ae;
}
.chipLayerMenu {
  font-size: 0.8rem;
  height: 24px;
}

.chipLayerMenuActive {
  background: #71cfdba3;
  color: white;
}
.listItemPaddingServices {
  margin-left: 16px;
  min-height: 36px !important;
}
.v-card__subtitle,
.v-card__text {
  font-size: 0.8rem;
}
</style>
