<template>
<DefaultLayout>
<v-container fluid class="grey lighten-5 ma-0">
  <v-row dense>
      <v-col cols="12" sm="12" md="S12">
      <Calendar @vehicle_id="changeVehicle" />
   </v-col>
   </v-row>
  </v-container>
  </DefaultLayout>
</template>

<script>
  // import Calendar from "@/components/calendar/Calendar.vue";
import Calendar from "@/components/calendar/CalendarSingh.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";

export default {
  name: "calendar",
  components: {
    Calendar,
    DefaultLayout
    //We are lazy-loading the following components to achive better first render performance
    //Dialogmap will also be kept in cache once it is loaded
    //so we do not keep asking for the teselas again and again
    /*DialogMap: () => import('@/components/dialogs/DialogMap'),
      DialogLog: () => import('@/components/dialogs/DialogLog'),
      DialogCode: () => import('@/components/dialogs/DialogCode'),*/
    /*DialogVehicle: () => import('@/components/dialogs/DialogVehicle'),*/
  },
  //props: ['model','operation'],
  data: () => ({
    mapdialog: false,
    logdialog: false,
    codedialog: false,
    vehicledialog: false,
    item: {},
    query: "",
  }),
  methods: {
    changeVehicle(item) {
      console.log(item);
      this.item = item;
      this.vehicledialog = true;
    },
    viewPlanification(item) {
      console.log(item);
    },
    openCodeDialog(item) {
      this.item = item;
      this.codedialog = true;
    },
    openLogDialog(item) {
      this.item = item;
      this.logdialog = true;
    },
    openMapDialog(item) {
      this.item = item;
      this.mapdialog = true;
    },
    closeMapDialog() {
      this.item = null;
      this.mapdialog = false;
    },
    setSearch(inputs) {
      //DynamicSearch component emits to this function whenever `Cercar` is pressed
      //Here we should call the server with our search request for a particular model
      //The response should be sent to DynamicDataTable as a prop, to show the queried data
      console.log(inputs);
      this.query =
        "?" +
        Object.keys(inputs)
          .map((key) => key + "=" + inputs[key])
          .join("&");
    },
  },
  watch: {
    /*model: function () {
      this.switchOperation(this.operation);
      this.query = "";
    },
    operation: function (newval) {
      this.switchOperation(newval);
    },*/
  },
  computed: {
    /*title() {
      return (
        this.$t([`model.operacions.${this.operation.toUpperCase()}`]) +
        " de " +
        this.$t([`model.${this.model.toUpperCase()}`])
      );
    },*/
  },
};
</script>
