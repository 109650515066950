<template>
  <v-form class="formContainer">
    <v-card class="search" style="background-color: white" :outlined="!stylesProp.removeBorder" >
      <v-container fluid class=" padding-0 ma-0">
        <p class="subtitle-2 mb-n1 title m-4 px-6">Cercar {{ title }}</p>
        <br /><br />

        <!--<v-row class="mx-12 d-flex justify-center align-center">-->
        <v-row class="mx-12">
          <template v-for="(field, i) in formData">
            <v-col
              cols="12"
              :md="field.cols ? field.cols : 6"
              v-if="field.type === `textfield`"
              :key="i"
            >
              <v-text-field
                dense
                v-bind:value="searchInputs[field.name]"
                v-on:input="setValue($event, field.name)"
                :label="$t(`${translationModel}.${field.label.toUpperCase()}`)"
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              :md="field.cols ? field.cols : 6"
              v-else-if="field.type === `select`"
              :key="i"
            >
              <v-select
                dense
                chips
                deletable-chips
                v-bind:value="searchInputs[field.name]"
                v-on:input="setValue($event, field.name)"
                :multiple="field.multiple"
                :items="field.items"
                :item-text="field.text"
                :item-value="field.value"
                :label="$t(`${translationModel}.${field.label.toUpperCase()}`)"
                :placeholder="$t(`${translationModel}.${field.label.toUpperCase()}`)"
              >
                <!--template v-slot:selection="{ item }">
                <v-chip small label close @click:self="setValue(null, field.name)" >
                  <span>{{ item.descripcio }}</span>
                </v-chip>
              </template-->
              </v-select>
            </v-col>
            <v-col
              cols="12"
              :md="field.cols ? field.cols : 6"
              v-else-if="field.type === `checkbox`"
              :key="i"
            >
              <v-checkbox
                v-model="searchInputs[field.name]"
              dense
              true-value="true"
              false-value="false"
                v-bind:value="searchInputs[field.name]"
                v-on:input="setValue($event, field.name)"
                :label="$t(`${translationModel}.${field.label.toUpperCase()}`)"
                class="mt-n1"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              :md="field.cols ? field.cols : 6"
              v-else-if="field.type === `date`"
              :key="i"
            >
              <v-menu
                v-model="menudatesStates[field.name]"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind:value="searchInputsForamtted[field.name]"
                    v-on:input="setValueDate($event, field.name)"
                    dense
                    :label="$t(`${translationModel}.${field.label.toUpperCase()}`)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                locale="ca-es"
                  v-bind:value="searchInputs[field.name]"
                  v-on:input="
                    setValueDate($event, field.name);
                    menudate = false;
                    menudatesStates[field.name]=false
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              :md="field.cols ? field.cols : 6"
              v-else-if="field.type === `hidden`"
              :key="i"  
            >
              <!-- EMPTY -->
            </v-col>
          </template>
          <v-col class="d-flex justify-end" cols="12" md="12">
            <v-btn
            
              class="mt-n2 button-small"
              style="background-color: rgb(113, 207, 219)"
              dark
              @click="$emit('search', searchInputs)"
            > 
              {{ $t("consulta.CERCAR")
           
              }}<v-icon class="ml-2">mdi-magnify</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <!--v-row class="mt-n10 justify-end mx-6">
          <v-col
            cols="2"
          >
            <v-btn class="blue" dark @click="$emit('search', searchInputs)"> Cercar </v-btn>
          </v-col>
        </v-row -->
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "DynamicSearch",
  props: {
    title:{
      type:String,
      default:""
    },
    jsonTemplate: {
      type: Object,
      default: () => ({}),
    },
    stylesProp:{
      type:Object,
      default:()=>({
        removeBorder:true
      })
    },
    translationModel:{
      type:String,
      default:"dynamicDatatable"
    },
  },
  data: () => ({
    searchInputs: {}, 
    searchInputsForamtted: {}, 
    formData: null,
    menudate: false,
    menudatesStates: {}
  }),
  mounted() {
     console.log("this.jsonTemplate");
    console.log( Object.keys(this.jsonTemplate).length === 0);

    if (this.jsonTemplate) {
      this.formData = this.fetchItems(this.jsonTemplate);
    }
    console.log("mounted");
    console.log(this.jsonTemplate);

    this.jsonTemplate.fields.filter(f=>f.type=='date').map(f=>{this.menudatesStates[f.name]=false})

  },
  methods: {
    fetchItems(model) {
      console.log("model")
      console.log(model)
     
        console.log("model.fields")
        console.log(model.fields)
        
          return model.fields.map((field) => {
            if (field.type === `select`) {
              axios
                .get(field.api,this.getHeaders())
                .then((response) => (field.items = response.data))
                .catch(() => {
                  field.items = null;
                });
            }
            return field;
          });
        
      
    },
    setValue(value, label) {
      value
        ? (this.searchInputs[label] = value)
        : delete this.searchInputs[label];
    },
    setValueDate(value, label){
      value
        ? (this.searchInputs[label] =   (value))
        : delete this.searchInputs[label];
      value
        ? (this.searchInputsForamtted[label] = this.formatDate(value))
        : delete this.searchInputsForamtted[label];
    },
    setInputs(values){
      console.log("we here");
      console.log(values);
       this.searchInputs = values
    },
    formatDate(value){
      console.log(value);
      // return "2000-11-22"
      return this.$date(value).format("DD-MM-YYYY");
      // : 19/10/2022"
    }
  },
  watch: {
    jsonTemplate: function (newval) {
      if (newval) {
        // this.switchConsulta(newval);
        console.log("hehe");
        this.formData = this.fetchItems(this.jsonTemplate);

        // this.searchInputs = {};
      }
    },
  },
  computed: {
    searchofmodel() {
      /*console.log("ana");
      console.log(this.$t("consulta.CERCAR"));
      console.log(this.model);*/
      //return this.$t('consulta.CERCAR')+' ' + this.$t([`modelo.${this.model.toUpperCase()}`]);
      // return this.$t("consulta.CERCAR");
      return ""
    },
    // translateSearchModel() {
    //   return this.formData
    //     ? this.formData.map((i) => {
    //         i.label = this.$t(`${this.model}.${i.label.toUpperCase()}`);
    //         return i;
    //       })
    //     : this.formData;
    // },
  },
  inject: ["getHeaders"],
};
</script>
<style scoped>
.search {
  /*max-width: 85%;*/
}
/deep/.v-chip.v-size--default {
  height: 28px !important;
  font-size: 0.86rem !important;
}
/deep/.v-label { 
  font-size: 0.76rem !important;
}
/deep/.v-select.v-select--chips.v-input--dense .v-select__selections {
  min-height: 28px !important;
}
/deep/button,
input,
optgroup,
select,
textarea {
  font-size: 0.76rem !important; 
}
.v-input{
  font-size: 0.86rem !important; 
}

.formContainer{
    /* padding: 0; */
    /* background-color: white; */
}
.padding-0{
  padding: 0;
}
.title{
  color: #676b74;
  /* font-weight: bold; */
  padding: 7px 8px;
  background-color: #71cfdb;
}
.noBorder{
  border: 0;
}
</style>
