<template>
  <vl-layer-vector
    ref="vectorLayer"
    :id="name"
    :visible="visible"
    :z-index="zIndex"
  >
    <!--<vl-layer-vector ref="vectorLayer" :id="name" :visible="visible"  >-->
    <vl-source-vector
      ref="vectorSource"
      :features.sync="features"
      :strategy-factory="loadingStrategyFactory"
    ></vl-source-vector>
    <vl-style-func :factory="styleFunction" />
    <vl-interaction-select
      :features.sync="selectedFeatures"
      :condition="eventCondition"
      :filter="filterFunction"
    >
      <template slot-scope="selection">
        <vl-style-func :factory="styleFunctionSelected"></vl-style-func>
        <vl-overlay
          v-for="feature in selection.features"
          :key="feature.id"
          :id="feature.id"
          class="feature-popup"
          :position="pointOnSurface(feature.geometry)"
        >
          <template>
            <v-card elevation="2" outlined shaped tile>
              <v-card-title>{{ name }}</v-card-title>
              <v-card-text
                ><b>Data</b>:
                {{
                  getDateFormat(
                    feature.properties.timestamp,
                    "DD/MM/YYYY HH:mm"
                  )
                }}</v-card-text
              >
              <!--<v-card-actions> <v-btn @click="selectedFeatures = selectedFeatures.filter((f) => f.id !== feature.id)">Close</v-btn> </v-card-actions>-->
            </v-card>
          </template>
        </vl-overlay>
      </template>
    </vl-interaction-select>
  </vl-layer-vector>
</template>

<script>
import * as condition from "ol/events/condition";
import axios from "axios";
//import jsonGPS from "@/config/test/gpszindex.json";
import Vue from "vue";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import { findPointOnSurface } from "vuelayers/lib/ol-ext";
//import { Overlay } from 'vuelayers';
//import axios from "axios";
import { loadingAll } from "vuelayers/lib/ol-ext";
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
import Point from "ol/geom/Point";
//import MultiPoint from "ol/geom/MultiPoint"; // Text,/ Text,
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";

Vue.use(VueLayers);

export default {
  name: "layerServiceGPS",
  components: {},
  props: {
    service_id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      default: "es",
    },
    /*selectedFeatures:{
      type: Array,
      default: () => [],
    },*/
  },

  data: () => ({
    features: [],
    zIndex: 200,
    selectedFeatures:[],
    /*zIndexText: 250,
    zIndexPoligon: 210,
    zIndexLine:220,
    zIndexPoint: 220,*/

    zIndexText: 100,
    zIndexPoligon: 100,
    zIndexLine: 100,
    zIndexPoint: 100,
  }),
  created() {
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);
  },
  async mounted() {
    await this.loadFeatures();
  },
  methods: {
    getDateFormat(date, format) {
      console.log("getDateFormat");
      let returnDate = "";

      if (date != null) {
        returnDate = this.$date(date).format(format);
      }

      return returnDate;
    },
    filterFunction(vector, layer) {
      console.log("vector");
      console.log(vector);
      console.log("layer");
      console.log(layer);
      console.log(vector.getProperties().type);
      return (
        layer.get("id") === this.name &&
        vector.getProperties().type === "pointsMiddle"
      );
    },
    pointOnSurface: findPointOnSurface,
    loadingStrategyFactory: function () {
      return loadingAll;
    },
    styleFunctionSelected: function () {
      const context = this;
      return function (feature) {
        console.log(feature);
        const styles = [];
        const geometry = feature.getGeometry();
        const feature_type = feature.getProperties().type;
        let style, segment_opacity;
        switch (feature_type) {
          case "pointsMiddle":
            style = new Style({
              image: new CircleStyle({
                radius: 5,
                fill: new Fill({ color: "rgba(113, 207, 219)" }),
                /*stroke: new Stroke({
                  color: "rgba(120, 120, 120)",
                  width: 0.1,
                }),*/
              }),
              zIndex: context.zIndexPoint,
            });
            styles.push(style);
            break;

          case "route":
            segment_opacity = 1;

            geometry.forEachSegment((start, end) => {
              let lineString = new LineString([start, end]);

              style = new Style({
                stroke: new Stroke({
                  color: "rgba(113, 207, 219, " + segment_opacity + ")",
                  width: 2,
                }),
                geometry: lineString,
                zIndex: context.zIndexLine,
              });
              styles.push(style);
            });
            break;
        }

        return styles;
      };
    },
    styleFunction: function () {
      const context = this;
      return function (feature) {
        const styles = [];
        const geometry = feature.getGeometry();
        const feature_type = feature.getProperties().type;
        let style, styleBox, styleRegistration, styleTime, segment_opacity;
        switch (feature_type) {
          case "pointNowRight":
            style = new Style({
              image: new Icon({
                anchor: [0.5, 0.5],
                src: require("@/assets/icons_ubicacions/garbage-truck-64-right.png"),
                scale: 0.6,
                /*offsetOrigin: "bottom-right",
                offset: [50,0]*/
              }),
              zIndex: 5,
            });
            styles.push(style);

            styleBox = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/gps.png"),
                anchor: [0.5, 0.48],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: 3,
            });
            styles.push(styleBox);

            styleRegistration = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: feature.getProperties().registration,
                offsetY: -52,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleRegistration);

            styleTime = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: feature.getProperties().timestamp.split("T")[1],
                offsetY: -37,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleTime);

            break;
          case "pointNowLeft":
            style = new Style({
              image: new Icon({
                anchor: [0.5, 0.5],
                src: require("@/assets/icons_ubicacions/garbage-truck-64-left.png"),
                scale: 0.6,
              }),
              zIndex: 5,
            });
            styles.push(style);

            styleBox = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/gps.png"),
                //anchor: [0.75, 0.5],
                anchor: [0.5, 0.48],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: 3,
            });
            styles.push(styleBox);

            styleRegistration = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: feature.getProperties().registration,
                offsetY: -52,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleRegistration);

            styleTime = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: feature.getProperties().timestamp.split(" ")[1],
                offsetY: -37,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleTime);

            break;
          case "pointLast":
            style = new Style({
              image: new CircleStyle({
                radius: 0,
                fill: new Fill({ color: "rgba('0,0,0,0')" }),
                /*stroke: new Stroke({
                  color: "rgba('0,0,0,0')",
                  width: 10,
                }),*/
              }),
              zIndex: context.zIndexPoint,
            });
            styles.push(style);
            break;

          case "pointsMiddle":
            style = new Style({
              image: new CircleStyle({
                radius: 2.5,
                fill: new Fill({ color: "rgba(120, 120, 120)" }),
                /*stroke: new Stroke({
                  color: "rgba(120, 120, 120)",
                  width: 0.1,
                }),*/
              }),
              zIndex: context.zIndexPoint,
            });
            styles.push(style);
            break;

          case "route":
            segment_opacity = 1;

            geometry.forEachSegment((start, end) => {
              let lineString = new LineString([start, end]);

              style = new Style({
                stroke: new Stroke({
                  color: "rgba(120, 120, 120, " + segment_opacity + ")",
                  width: 2,
                }),
                geometry: lineString,
                zIndex: context.zIndexLine,
              });
              styles.push(style);
            });
            break;
        }

        return styles;
      };
    },

    loadFeatures: async function () {
      try {
        const writer = new GeoJSON();

        const url = `/api/v1/service/${this.service_id}/gps`;
        const respuesta = await axios.get(url, this.getHeaders());

        const punts_gps = respuesta.data;

        //const punts_gps =jsonGPS;

        const arr_coordinates_gps = punts_gps.map(function (punt_gps) {
          return [punt_gps.lon, punt_gps.lat];
        });

        const route = new LineString(arr_coordinates_gps);

        const routeFeature = new Feature({
          type: "route",
          geometry: route,
        });

        const geoJsonroute = writer.writeFeature(routeFeature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        });

        const pointsMiddleFeature = punts_gps.map(function (punt_gps) {
          return new Feature({
            type: "pointsMiddle",
            geometry: new Point([punt_gps.lon, punt_gps.lat]),
            timestamp: punt_gps.timestamp,
          });
        });

        const geoJsonMiddleMarkers = pointsMiddleFeature.map(function (point) {
          return JSON.parse(
            writer.writeFeature(point, {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:4326",
            })
          );
        });

        this.features = await [JSON.parse(geoJsonroute), geoJsonMiddleMarkers]
          .flat()
          .map(Object.freeze);
      } catch (err) {
        console.log(err);
        console.log("ha habido un error");
      }
    },
  },
  computed: {
    eventCondition() {
      return condition.pointerMove;
      //return condition.singleClick;
    },
  },
  watch: {
    service_id: async function () {
      await this.loadFeatures();
    },
    selectedFeatures: function (newVal) {
      console.log(newVal);
      this.styleFunctionSelected(newVal);
    },
  },
  inject: ["getHeaders"],
};
</script>
<style lang="scss" scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 5px;
  font-size: 0.8rem !important;
}
.v-card__title {
  text-align: center;
  display: block;
}
.feature-popup {
  position: absolute;
  left: -50px;
  bottom: 12px;
  width: 20em;
  max-width: none;

  &:after,
  &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
}
</style>
