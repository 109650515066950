<template>
  <vl-layer-group :ref="name" :id="name" :opacity="opacity" :visible="visible">
    <vl-layer-vector
      :ref="path_vector_name"
      :id="path_vector_name"
      :visible="visible"
      :z-index="zIndexPath"
    >
      <vl-source-vector
        ref="vectorSourcePath"
        :features.sync="features_path"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunctionPath" />
    </vl-layer-vector>

    <vl-layer-vector
      :ref="path_vector_name_pois"
      :id="path_vector_name_pois"
      :visible="visible"
      :z-index="zIndexPathPois"
    >
      <vl-source-vector
        ref="vectorSourcePathPois"
        :features.sync="features_path_pois"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunctionPath" />
    </vl-layer-vector>

    <vl-layer-vector
      :ref="warning_analsyis_vector_name"
      :id="warning_analsyis_vector_name"
      :visible="visible"
      :z-index="zIndexWarningAnalysis"
    >
      <vl-source-vector
        ref="vectorSourceWarning"
        :features.sync="features_warning_analysis"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunctionErrorAnalysis" />
    </vl-layer-vector>

    <vl-layer-vector
      :ref="warning_analsyis_vector_name_pois"
      :id="warning_analsyis_vector_name_pois"
      :visible="visible"
      :z-index="zIndexErrorAnalysisPois"
    >
      <vl-source-vector
        ref="vectorSourceWarningPois"
        :features.sync="features_warning_analysis_pois"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunctionErrorAnalysis" />
    </vl-layer-vector>

    <vl-layer-vector
      :ref="error_analsyis_vector_name"
      :id="error_analsyis_vector_name"
      :visible="visible"
      :z-index="zIndexErrorAnalysis"
    >
      <vl-source-vector
        ref="vectorSourceError"
        :features.sync="features_error_analysis"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunctionErrorAnalysis" />
    </vl-layer-vector>

    <vl-layer-vector
      :ref="error_analsyis_vector_name_pois"
      :id="error_analsyis_vector_name_pois"
      :visible="visible"
      :z-index="zIndexErrorAnalysisPois"
    >
      <vl-source-vector
        ref="vectorSourceErrorPois"
        :features.sync="features_error_analysis_pois"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunctionErrorAnalysis" />
    </vl-layer-vector>

    <vl-interaction-select
      :features.sync="selectedFeatures"
      :condition="eventCondition"
      :filter="filterFunction"
    >

    <template slot-scope="selection">
        <vl-style-func :factory="styleFunctionErrorAnalysisSelected"></vl-style-func>
        <vl-overlay
          v-for="feature in selection.features"
          :key="feature.id"
          :id="feature.id"
          class="feature-popup"
          :position="pointOnSurface(feature.geometry)"
        >
          <template>
            <v-card elevation="2" outlined shaped tile>
              <v-card-title
                >{{ name }}</v-card-title
              >
              <!--<v-card-text 
              v-for="feature in selection.features"
              :key="feature.id"
              :id="feature.id"
              >
              
              {{feature.properties}}
              </v-card-text>-->
              <v-card-text v-html="getHtmlFeatureProperties"></v-card-text>
              <!--<v-card-actions> <v-btn @click="selectedFeatures = selectedFeatures.filter((f) => f.id !== feature.id)">Close</v-btn> </v-card-actions>-->
            </v-card>
          </template>
        </vl-overlay>
      </template>

      <!--<template slot-scope="selection">
        <vl-style-func :factory="styleFunctionErrorAnalysisSelected"></vl-style-func>
        <vl-overlay
          v-for="feature in selection.features"
          :key="feature.id"
          :id="feature.id"
          class="feature-popup"
          :position="pointOnSurface(feature.geometry)"
        >
          <template>
            <v-card elevation="2" outlined shaped tile>
              <v-card-title
                >{{ name }}</v-card-title
              >
             
              <v-card-text v-html="getHtmlFeatureProperties()"></v-card-text>-
             
            </v-card>
          </template>
        </vl-overlay>
      </template>-->
    </vl-interaction-select>
  </vl-layer-group>
</template>

<script>
//import axios from 'axios'
//import jsonReport from "@/config/test/jsonzindex.json";
import * as condition from "ol/events/condition";
import Vue from "vue";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import axios from "axios";
import { loadingAll, createStyle } from "vuelayers/lib/ol-ext";
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
import Point from "ol/geom/Point";
import { findPointOnSurface } from "vuelayers/lib/ol-ext";

Vue.use(VueLayers);

export default {
  components: {},
  props: {
    service_id: {
      type: Number,
      default: 0,
    },
    path_id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#7d62ad",
    },
    poisWithSameColor: {
      type: Boolean,
      default: true,
    },
    fitAtLoad: {
      type: Boolean,
      default: false,
    },
    elementsWithTrashIcon: {
      type: Boolean,
      default: false,
    },
    withClustering: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "es",
    },
  },
  watch: {
    path_id: async function () {
      await this.loadFeatures();
    },
    service_id: async function () {
      await this.loadFeatures();
    },
    color: function () {
      this.$refs[this.name].refresh();
      this.$refs[this.name].refresh();
      this.$refs[this.error_analsyis_vector_name].refresh();
      this.$refs[this.warning_analsyis_vector_name].refresh();
      this.$refs[this.error_analsyis_vector_name_pois].refresh();
      this.$refs[this.warning_analsyis_vector_name_pois].refresh();
      this.$refs[this.cluster_name].refresh();
      this.$refs[this.path_vector_name].refresh();
      this.$refs[this.path_vector_name_pois].refresh();
    },
  },
  data: () => ({
    url_base_path: "/api/v1/path",
    features_path: [],
    //features_path_locations: [],
    //features_analysis:[],
    features_error_analysis: [],
    features_warning_analysis: [],
    opacity: 1,
    zIndex: 100,
    zIndexWarningAnalysis: 104,
    zIndexErrorAnalysis: 105,

    zIndexPath: 103,
    //Para que se vean estas capas encima de la capa GPS que tiene un zindex de 200
    zIndexPathPois: 203,
    zIndexWarningAnalysisPois: 204,
    zIndexErrorAnalysisPois: 205,

    /*zIndexText: 250,
    zIndexPoligon: 210,
    zIndexLine:220,
    zIndexPoint: 220,*/
    zIndexText: 100,
    zIndexPoligon: 100,
    zIndexLine: 100,
    zIndexPoint: 100,
    
    selectedFeatures: [],
    features_error_analysis_pois:[],
    features_warning_analysis_pois:[],
    features_path_pois:[],
  }),
  created() {
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);
  },
  async mounted() {
    await this.loadFeatures();
  },

  computed: {
    getHtmlFeatureProperties(){
      if (this.selectedFeatures.length == 0) return;
      let html = ""; 

      this.selectedFeatures.forEach(feature => {
        let featureProperties= feature.properties;  
        for (const property in featureProperties) {
          html = html + `<b>${property}</b>: ${featureProperties[property]}<br>`;
        
        }
      })
      return html;
      
    },
    eventCondition() {
      return condition.pointerMove;
    },
    cluster_name: function () {
      return `CLUSTER_${this.name}`;
    },
    error_analsyis_vector_name: function () {
      return `ERROR_ANALYSYS_VECTOR_${this.name}`;
    },
    warning_analsyis_vector_name: function () {
      return `WARNING_ANALYSYS_VECTOR_${this.name}`;
    },
    error_analsyis_vector_name_pois: function () {
      return `ERROR_ANALYSYS_VECTOR_${this.name}_POIS`;
    },
    warning_analsyis_vector_name_pois: function () {
      return `WARNING_ANALYSYS_VECTOR_${this.name}_POIS`;
    },
    path_vector_name: function () {
      return `PATH_VECTOR_${this.name}`;
    },
    path_vector_name_pois: function () {
      return `PATH_VECTOR_${this.name}_POIS`;
    },
  },
  methods: {
    filterFunction(vector, layer) {
      //console.log("vector");
      //console.log(vector);
      //console.log("layer");
      //console.log(layer.get("id"));
      return layer.get("id") == this.warning_analsyis_vector_name_pois ||
        layer.get("id") == this.error_analsyis_vector_name_pois ||
        layer.get("id") == this.warning_analsyis_vector_name ||
        layer.get("id") == this.error_analsyis_vector_name;
      //layer.get('id') === this.name ||
      //layer.get('id')==this.path_vector_name_pois ||
      //layer.get('id')==this.path_vector_name ||
    },
    pointOnSurface: findPointOnSurface,
    getDateFormat: function (date, format) {
      let returnDate = "";

      if (date != null) {
        if (date.length == 8) {
          returnDate = date.substr(0, date.length - 3);
        } else {
          returnDate = this.$date(date).format(format);
        }
      }

      return returnDate;
    },
    loadingStrategyFactory: function () {
      return loadingAll;
    },
    styleFunctionCluster() {
      const cache = {};

      return function __clusterStyleFunc(feature, resolution) {
        if (resolution <= 3) return new Style({});
        const size = feature.get("features").length;
        let style = cache[size];

        if (!style) {
          style = createStyle({
            imageRadius: 11,
            strokeColor: "#fff",
            fillColor: "#71cfdb",
            text: size.toString(),
            textFillColor: "#FFF",
          });

          cache[size] = style;
        }

        return [style];
      };
    },
    styleFunction: function () {
      const context = this;
      return function (feature, resolution) {
        const styles = [];
        const geometry = feature.getGeometry();
        const feature_type = feature.getProperties().type;
        const feature_subtype = feature.getProperties().subtype;

        //const feature_code= feature.getProperties().code;
        let stylePoi = null;
        let styleSection = null;
        let styleText = null;
        let styleZone = null;
        let styleLocation = null;
        let styleGeomarker = null;

        switch (feature_type) {
          case "point_of_interest":
            if (resolution > 5) return new Style({});
            switch (feature_subtype) {
              case "start":
                stylePoi = new Style({
                  image: new Icon({
                    src: require("@/assets/icons_ubicacions/inici_iti.png"),
                    //anchor: [0.75, 0.5],
                    rotateWithView: true,
                    scale: 0.45,
                  }),
                  zIndex: 201,
                });
                styleText = new Style({
                  text: new Text({
                    text: feature.getProperties().time_from,
                    offsetY: -60,
                    font: "bold 11px sans-serif",
                  }),
                  zIndex: 202,
                });

                break;
              case "end":
                stylePoi = new Style({
                  image: new Icon({
                    src: require("@/assets/icons_ubicacions/final_iti.png"),
                    //anchor: [0.75, 0.5],
                    rotateWithView: true,
                    scale: 0.45,
                  }),
                  zIndex: 201,
                });
                styleText = new Style({
                  text: new Text({
                    text: feature.getProperties().time_to,
                    offsetY: -60,
                    font: "bold 11px sans-serif",
                  }),
                  zIndex: 202,
                });
                break;
            }

            styles.push(stylePoi);
            styles.push(styleText);
            break;
          case "location":
            if (context.elementsWithTrashIcon) {
              if (resolution > 3) return new Style({});
              styleLocation = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/" +
                    feature.getProperties().fractions +
                    ".png"),
                  //anchor: [0.75, 0.5],
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: 201,
              });
              styleText = new Style({
                text: new Text({
                  text: feature.getProperties().n_containers.toString(),
                  offsetX: 10,
                  font: "bold 11px sans-serif",
                }),
                zIndex: 202,
              });

              styles.push(styleLocation);
              styles.push(styleText);
            } else {
              if (resolution > 5) return new Style({});
              if (resolution > 2 && resolution <= 5) {
                styleLocation = new Style({
                  image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                    stroke: new Stroke({
                      color: context.color,
                      width: 2,
                    }),
                  }),
                  zIndex: 5,
                });
              } else {
                styleLocation = new Style({
                  image: new CircleStyle({
                    radius: 22,
                    fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                    stroke: new Stroke({
                      color: context.color,
                      width: 4.5,
                    }),
                  }),
                  zIndex: 5,
                });
              }
              styles.push(styleLocation);
            }

            break;
          case "zone":
            styleZone = new Style({
              fill: new Fill({
                //color: "rgba(214, 72, 150, 0.2)",
                color: `${context.color}33`,
              }),
              stroke: new Stroke({
                width: 2,
                //color: "rgba(214, 72, 150, 1)",
                color: context.color,
              }),
              zIndex: 2,
            });
            styleText = new Style({
              text: new Text({
                text: feature.getProperties().orden,
                font: "bold 11px sans-serif",
              }),
              zIndex: 3,
            });
            styles.push(styleZone);
            styles.push(styleText);
            break;
          case "section":
            styleSection = new Style({
              stroke: new Stroke({
                //color: "rgba(214, 72, 150, 1)",
                color: context.color,
                width: 2,
              }),
              zIndex: 1,
            });
            styles.push(styleSection);
            if (resolution && geometry.getType() == "MultiLineString") {
              var i = 0;

              geometry.getLineString().forEachSegment(function (start, end) {
                if (i == 10) {
                  i = 0;
                }

                if (i == 0) {
                  const dx = end[0] - start[0];
                  const dy = end[1] - start[1];
                  const rotation = Math.atan2(dy, dx);
                  // arrows
                  styles.push(
                    new Style({
                      geometry: new Point(end),
                      image: new Icon({
                        color: context.color,
                        src: require("@/assets/arrow-itinerary.png"),
                        anchor: [0.75, 0.5],
                        rotateWithView: true,
                        rotation: -rotation,
                        scale: 0.5,
                      }),
                      zIndex: 1,
                    })
                  );
                }
                i = i + 1;
              });
            }
            break;
          default:
            //Para probar punto
            styleGeomarker = new Style({
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({ color: "green" }),
                stroke: new Stroke({
                  color: "blue",
                  width: 10,
                }),
              }),
              zIndex: 1,
            });
            styles.push(styleGeomarker);
            break;
        }
        return styles;
      };
    },

    styleFunctionPath: function () {
      const color = "#4CAF50";
      const context = this;
      return function (feature, resolution) {
        const styles = [];
        const geometry = feature.getGeometry();
        const feature_type = feature.getProperties().type;
        const feature_subtype = feature.getProperties().subtype;

        //const feature_code= feature.getProperties().code;
        let stylePoi = null;
        let styleSection = null;
        let styleText = null;
        let styleZone = null;
        let styleLocation = null;
        let styleGeomarker = null;

        switch (feature_type) {
          case "point_of_interest":
            if (resolution > 10) return new Style({});
            switch (feature_subtype) {
              case "start":
                stylePoi = new Style({
                  image: new Icon({
                    src: require("@/assets/icons_ubicacions/inici_iti.png"),
                    //anchor: [0.75, 0.5],
                    rotateWithView: true,
                    scale: 0.45,
                  }),
                  zIndex: context.zIndexText,
                });
                styleText = new Style({
                  text: new Text({
                    text: feature.getProperties().time_from,
                    offsetY: -60,
                    font: "bold 11px sans-serif",
                    fill: new Fill({ color: "#000000" }),
                  }),
                  zIndex: context.zIndexText,
                });

                break;
              case "end":
                stylePoi = new Style({
                  image: new Icon({
                    src: require("@/assets/icons_ubicacions/final_iti.png"),
                    //anchor: [0.75, 0.5],
                    rotateWithView: true,
                    scale: 0.45,
                  }),
                  zIndex: context.zIndexText,
                });
                styleText = new Style({
                  text: new Text({
                    text: feature.getProperties().time_to,
                    offsetY: -60,
                    font: "bold 11px sans-serif",
                    fill: new Fill({ color: "#000000" }),
                  }),
                  zIndex: context.zIndexText,
                });
                break;
            }

            styles.push(stylePoi);
            styles.push(styleText);
            break;
          case "location":
            if (context.elementsWithTrashIcon) {
              if (resolution > 3) return new Style({});
              styleLocation = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/" +
                    feature.getProperties().fractions +
                    ".png"),
                  //anchor: [0.75, 0.5],
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: feature.getProperties().n_containers.toString(),
                  offsetX: 10,
                  font: "bold 11px sans-serif",
                }),
                zIndex: context.zIndexText,
              });

              styles.push(styleLocation);
              styles.push(styleText);
            } else {
              if (resolution > 10) return new Style({});
              if (resolution > 2 && resolution <= 5) {
                styleLocation = new Style({
                  image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                    stroke: new Stroke({
                      color: color,
                      width: 2,
                    }),
                  }),
                  zIndex: context.zIndexText,
                });
              } else {
                styleLocation = new Style({
                  image: new CircleStyle({
                    radius: 22,
                    fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                    stroke: new Stroke({
                      color: color,
                      width: 4.5,
                    }),
                  }),
                  zIndex: context.zIndexText,
                });
              }
              styles.push(styleLocation);
            }

            break;
          case "zone":
            styleZone = new Style({
              fill: new Fill({
                //color: "rgba(214, 72, 150, 0.2)",
                color: `${color}33`,
                //color:color
              }),
              stroke: new Stroke({
                width: 2,
                //color: "rgba(214, 72, 150, 1)",
                color: color,
              }),
              zIndex: context.zIndexPoligon,
            });
            styleText = new Style({
              text: new Text({
                text: feature.getProperties().orden,
                font: "bold 11px sans-serif",
              }),
              zIndex: context.zIndexText,
            });
            styles.push(styleZone);
            styles.push(styleText);
            break;
          case "section":
            styleSection = new Style({
              stroke: new Stroke({
                //color: "rgba(214, 72, 150, 1)",
                color: color,
                width: 2,
              }),
              zIndex: context.zIndexLine,
            });
            styles.push(styleSection);
            if (resolution && geometry.getType() == "MultiLineString") {
              var i = 0;

              geometry.getLineString().forEachSegment(function (start, end) {
                if (i == 10) {
                  i = 0;
                }

                if (i == 0) {
                  const dx = end[0] - start[0];
                  const dy = end[1] - start[1];
                  const rotation = Math.atan2(dy, dx);
                  // arrows
                  styles.push(
                    new Style({
                      geometry: new Point(end),
                      image: new Icon({
                        color: color,
                        src: require("@/assets/arrow-itinerary.png"),
                        anchor: [0.75, 0.5],
                        rotateWithView: true,
                        rotation: -rotation,
                        scale: 0.5,
                      }),
                      zIndex: context.zIndexLine,
                    })
                  );
                }
                i = i + 1;
              });
            }
            break;
          default:
            //Para probar punto
            styleGeomarker = new Style({
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({ color: color }),
                stroke: new Stroke({
                  color: "blue",
                  width: 10,
                }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(styleGeomarker);
            break;
        }
        return styles;
      };
    },

    styleFunctionErrorAnalysis: function () {
      //const color = "#FF0000";
      //const colorTransparent = "#FF00000D";
      const color_error = "#E53935";
      const color_error_transparent = "#E5393540";
      //const color_ok = "#57A639";
      //const color_ok_transparent = "#57A6390D";
      const color_warning = "#FF9800";
      //const color_warning_transparent = "FF80000D";

      const context = this;
      return function (feature, resolution) {
        const styles = [];
        let stylePoi = null;
        let styleText = null;
        let styleLine = null;
        let stylePolygon = null;
        let first_position_timestamp = "";
        let last_position_timestamp = "";
        let poi_type = "";
        //console.log(resolution);
        const feature_error_code = feature.getProperties().code;

        switch (feature_error_code) {
          case "starting_out_of_time":
            if (resolution > 10) return new Style({});
            //BIEN
            first_position_timestamp =
              feature.getProperties().first_position_timestamp;
            first_position_timestamp = context.getDateFormat(
              first_position_timestamp,
              "HH:mm"
            );
            //first_position_timestamp = first_position_timestamp.substr(first_position_timestamp.length - 8, 5 );
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_e.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styleText = new Style({
              text: new Text({
                text: `INI ${first_position_timestamp}`,
                offsetY: 0,
                offsetX: -76,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styles.push(styleText);
            break;
          //Deberia de haber empezado a las x y empieza a las x
          //primera posicion gps dentro del buffer y dentro del rango horario que se permite para el servicio
          //has pasado por el punto
          case "ending_out_of_time":
            if (resolution > 10) return new Style({});
            //BIEN
            last_position_timestamp =
              feature.getProperties()
                .timestamp_of_last_position_with_displacement;
            last_position_timestamp = context.getDateFormat(
              last_position_timestamp,
              "HH:mm"
            );
            //last_position_timestamp = last_position_timestamp.substr(last_position_timestamp.length - 8, 5 );

            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_d.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styleText = new Style({
              text: new Text({
                text: `FIN ${last_position_timestamp}`,
                offsetY: 0,
                offsetX: 76,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styles.push(styleText);
            break;
          //Deberia de haber terminado a las x y empieza a las x
          //ultima posicion gps dentro del buffer y dentro del rango horario que se permite para el servicio
          case "average_speed_too_fast":
            //
            //no hay geojson
            break;
          case "average_speed_too_slow":
            //no hay geojson
            break;
          case "check_points_out_of_time":
            if (resolution > 10) return new Style({});
            //he pasado por los puntos de control pero fuera de horas
            //end y start tmb
            //la posicion que da es: la del checkpoint
            //BIEN
            poi_type = feature.getProperties().poi_type;
            if (poi_type == "start" || poi_type == "end") {
              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-alert-outline.png"),
                  anchor: [-45, 200],
                  anchorXUnits: "pixels",
                  anchorYUnits: "pixels",
                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 12,
                  fill: new Fill({ color: "#FFFFFF" }),
                  stroke: new Stroke({
                    color: "#FFFFFF",
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);

              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-alert-outline.png"),

                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }
            break;
          case "check_points_uncrossed":
            if (resolution > 10) return new Style({});
            //BIEN
            //error
            //No ha pasado por aqui entre una hora y otra

            poi_type = feature.getProperties().poi_type;
            if (poi_type == "start" || poi_type == "end") {
              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-remove-outline.png"),
                  anchor: [-45, 200],
                  anchorXUnits: "pixels",
                  anchorYUnits: "pixels",
                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 12,
                  fill: new Fill({ color: "#FFFFFF" }),
                  stroke: new Stroke({
                    color: "#FFFFFF",
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);

              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-remove-outline.png"),

                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }
            /*stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_e.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: 6,
            });
            styles.push(stylePoi);
            
            
            styleText = new Style({
              text: new Text({
                text: `${poi_type}`,
                offsetY: 0,
                offsetX: - 65,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: 6,
            });
            styles.push(styleText);*/
            break;
          case "displaced_ending_position":
            if (resolution > 10) return new Style({});
            //FALTA MIRAR, NO ESTA IMPLEMENTADA
            //La ultima posicion esta lejos del punto final
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_e.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/map-marker-distance.png"),
                anchor: [255, 26],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                color: color_error,
                rotateWithView: true,
                scale: 0.4,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styleText = new Style({
              text: new Text({
                text: `FIN`,
                offsetY: 0,
                offsetX: -65,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(styleText);

            break;
          case "displaced_starting_position":
            if (resolution > 10) return new Style({});
            //FALTA MIRAR, NO ESTA IMPLEMENTADA
            //La primera posicion esta lejos del punto inicial
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_d.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/map-marker-distance.png"),
                anchor: [255, 26],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                color: color_error,
                rotateWithView: true,
                scale: 0.4,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styleText = new Style({
              text: new Text({
                text: `INI`,
                offsetY: 0,
                offsetX: -65,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(styleText);
            break;
          case "extra_pickuped_containers":
            if (resolution > 10) return new Style({});
            //posicion gps donde se ha recogido el contenedor de más
            //contenedor + verde
            //No lo he podido probar
            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 1,
                  fill: new Fill({ color: color_warning }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 0,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -7,
                  offsetX: 7,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({ color: color_warning }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -7,
                  offsetX: 7,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            }

            break;
          case "extra_pickuped_locations":
            if (resolution > 10) return new Style({});
            //posicion de las ubicaciones que se han recogido de más
            //ubicacion redonda + verde
            //BIEN
            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 2,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -7,
                  offsetX: 7,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 22,
                  fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 4.5,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -23,
                  offsetX: 24,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            }

            break;
          case "missing_container_pickups":
            if (resolution > 10) return new Style({});
            //BIEN
            //posicion de la ubicacion por donde se deberian recojer algo que no se ha recogido
            //contenedor roja

            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 1,
                  fill: new Fill({ color: color_error }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 0,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({ color: color_error }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }

            break;
          case "missing_location_pickups":
            if (resolution > 10) return new Style({});
            //BIEN
            //posicion de la ubicacion por donde se deberia haber pasado o recogido algo
            //ubicacion redonda roja
            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 2,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 22,
                  fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 4.5,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }

            break;
          case "some_segments_uncleaned":
            //BIEN
            styleLine = new Style({
              stroke: new Stroke({
                width: 2,
                color: color_warning,
              }),
              zIndex: context.zIndexLine,
            });
            styles.push(styleLine);
            break;
          case "too_many_segments_uncleaned":
            //BIEN
            styleLine = new Style({
              stroke: new Stroke({
                width: 2,
                color: color_error,
              }),
              zIndex: context.zIndexLine,
            });
            styles.push(styleLine);
            break;
          case "uncleaned_surfaces":
            //BIEN
            stylePolygon = new Style({
              fill: new Fill({
                color: color_error_transparent,
              }),
              stroke: new Stroke({
                width: 2,
                color: color_error,
              }),
              zIndex: context.zIndexPoligon,
            });
            styles.push(stylePolygon);
            break;
        }

        return styles;
      };
    },
    styleFunctionErrorAnalysisSelected: function () {
      //const color = "#FF0000";
      //const colorTransparent = "#FF00000D";
      const color_error = "#71cfdb";
      const color_error_transparent = "#E5393540";
      //const color_ok = "#57A639";
      //const color_ok_transparent = "#57A6390D";
      const color_warning = "#71cfdb";
      //const color_warning_transparent = "FF80000D";

      const context = this;
      return function (feature, resolution) {
        const styles = [];
        let stylePoi = null;
        let styleText = null;
        let styleLine = null;
        let stylePolygon = null;
        let first_position_timestamp = "";
        let last_position_timestamp = "";
        let poi_type = "";
        //console.log(resolution);
        const feature_error_code = feature.getProperties().code;

        switch (feature_error_code) {
          case "starting_out_of_time":
            if (resolution > 10) return new Style({});
            //BIEN
            first_position_timestamp =
              feature.getProperties().first_position_timestamp;
            first_position_timestamp = context.getDateFormat(
              first_position_timestamp,
              "HH:mm"
            );
            //first_position_timestamp = first_position_timestamp.substr(first_position_timestamp.length - 8, 5 );
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_e.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styleText = new Style({
              text: new Text({
                text: `INI ${first_position_timestamp}`,
                offsetY: 0,
                offsetX: -76,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styles.push(styleText);
            break;
          //Deberia de haber empezado a las x y empieza a las x
          //primera posicion gps dentro del buffer y dentro del rango horario que se permite para el servicio
          //has pasado por el punto
          case "ending_out_of_time":
            if (resolution > 10) return new Style({});
            //BIEN
            last_position_timestamp =
              feature.getProperties()
                .timestamp_of_last_position_with_displacement;
            last_position_timestamp = context.getDateFormat(
              last_position_timestamp,
              "HH:mm"
            );
            //last_position_timestamp = last_position_timestamp.substr(last_position_timestamp.length - 8, 5 );

            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_d.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styleText = new Style({
              text: new Text({
                text: `FIN ${last_position_timestamp}`,
                offsetY: 0,
                offsetX: 76,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styles.push(styleText);
            break;
          //Deberia de haber terminado a las x y empieza a las x
          //ultima posicion gps dentro del buffer y dentro del rango horario que se permite para el servicio
          case "average_speed_too_fast":
            //
            //no hay geojson
            break;
          case "average_speed_too_slow":
            //no hay geojson
            break;
          case "check_points_out_of_time":
            if (resolution > 10) return new Style({});
            //he pasado por los puntos de control pero fuera de horas
            //end y start tmb
            //la posicion que da es: la del checkpoint
            //BIEN
            poi_type = feature.getProperties().poi_type;
            if (poi_type == "start" || poi_type == "end") {
              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-alert-outline.png"),
                  anchor: [-45, 200],
                  anchorXUnits: "pixels",
                  anchorYUnits: "pixels",
                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 12,
                  fill: new Fill({ color: "#FFFFFF" }),
                  stroke: new Stroke({
                    color: "#FFFFFF",
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);

              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-alert-outline.png"),

                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }
            break;
          case "check_points_uncrossed":
            if (resolution > 10) return new Style({});
            //BIEN
            //error
            //No ha pasado por aqui entre una hora y otra

            poi_type = feature.getProperties().poi_type;
            if (poi_type == "start" || poi_type == "end") {
              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-remove-outline.png"),
                  anchor: [-45, 200],
                  anchorXUnits: "pixels",
                  anchorYUnits: "pixels",
                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 12,
                  fill: new Fill({ color: "#FFFFFF" }),
                  stroke: new Stroke({
                    color: "#FFFFFF",
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);

              stylePoi = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/clock-remove-outline.png"),

                  color: color_error,
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }
            /*stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_e.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: 6,
            });
            styles.push(stylePoi);
            
            
            styleText = new Style({
              text: new Text({
                text: `${poi_type}`,
                offsetY: 0,
                offsetX: - 65,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: 6,
            });
            styles.push(styleText);*/
            break;
          case "displaced_ending_position":
            if (resolution > 10) return new Style({});
            //FALTA MIRAR, NO ESTA IMPLEMENTADA
            //La ultima posicion esta lejos del punto final
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_e.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/map-marker-distance.png"),
                anchor: [255, 26],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                color: color_error,
                rotateWithView: true,
                scale: 0.4,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styleText = new Style({
              text: new Text({
                text: `FIN`,
                offsetY: 0,
                offsetX: -65,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(styleText);

            break;
          case "displaced_starting_position":
            if (resolution > 10) return new Style({});
            //FALTA MIRAR, NO ESTA IMPLEMENTADA
            //La primera posicion esta lejos del punto inicial
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/punt_interes_d.png"),
                //anchor: [0.75, 0.5],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            stylePoi = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/map-marker-distance.png"),
                anchor: [255, 26],
                anchorXUnits: "pixels",
                anchorYUnits: "pixels",
                color: color_error,
                rotateWithView: true,
                scale: 0.4,
              }),
              zIndex: context.zIndexText,
            });
            styles.push(stylePoi);
            styleText = new Style({
              text: new Text({
                text: `INI`,
                offsetY: 0,
                offsetX: -65,
                font: "bold 11px sans-serif",
                fill: new Fill({ color: color_error }),
              }),
              zIndex: context.zIndexText,
            });
            styles.push(styleText);
            break;
          case "extra_pickuped_containers":
            if (resolution > 10) return new Style({});
            //posicion gps donde se ha recogido el contenedor de más
            //contenedor + verde
            //No lo he podido probar
            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 1,
                  fill: new Fill({ color: color_warning }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 0,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -7,
                  offsetX: 7,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({ color: color_warning }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -7,
                  offsetX: 7,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            }

            break;
          case "extra_pickuped_locations":
            if (resolution > 10) return new Style({});
            //posicion de las ubicaciones que se han recogido de más
            //ubicacion redonda + verde
            //BIEN
            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 2,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -7,
                  offsetX: 7,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 22,
                  fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                  stroke: new Stroke({
                    color: color_warning,
                    width: 4.5,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styleText = new Style({
                text: new Text({
                  text: `+`,
                  offsetY: -23,
                  offsetX: 24,
                  font: "bold 14px sans-serif",
                  fill: new Fill({ color: color_warning }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
              styles.push(styleText);
            }

            break;
          case "missing_container_pickups":
            if (resolution > 10) return new Style({});
            //BIEN
            //posicion de la ubicacion por donde se deberian recojer algo que no se ha recogido
            //contenedor roja

            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 1,
                  fill: new Fill({ color: color_error }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 0,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 4,
                  fill: new Fill({ color: color_error }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 1,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }

            break;
          case "missing_location_pickups":
            if (resolution > 10) return new Style({});
            //BIEN
            //posicion de la ubicacion por donde se deberia haber pasado o recogido algo
            //ubicacion redonda roja
            if (resolution > 2 && resolution <= 5) {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 2,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            } else {
              stylePoi = new Style({
                image: new CircleStyle({
                  radius: 22,
                  fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                  stroke: new Stroke({
                    color: color_error,
                    width: 4.5,
                  }),
                }),
                zIndex: context.zIndexText,
              });
              styles.push(stylePoi);
            }

            break;
          case "some_segments_uncleaned":
            //BIEN
            styleLine = new Style({
              stroke: new Stroke({
                width: 2,
                color: color_warning,
              }),
              zIndex: context.zIndexLine,
            });
            styles.push(styleLine);
            break;
          case "too_many_segments_uncleaned":
            //BIEN
            styleLine = new Style({
              stroke: new Stroke({
                width: 2,
                color: color_error,
              }),
              zIndex: context.zIndexLine,
            });
            styles.push(styleLine);
            break;
          case "uncleaned_surfaces":
            //BIEN
            stylePolygon = new Style({
              fill: new Fill({
                color: color_error_transparent,
              }),
              stroke: new Stroke({
                width: 2,
                color: color_error,
              }),
              zIndex: context.zIndexPoligon,
            });
            styles.push(stylePolygon);
            break;
        }

        return styles;
      };
    },
    loadFeatures: async function () {
      ///api/v1/path
      const url_path = `${this.url_base_path}/${this.path_id}`;

      try {
        const respuesta = await axios.get(url_path, this.getHeaders());
        const respuestaJSON = JSON.parse(respuesta.data.geojson);
        const featuresPath = respuestaJSON.features;

        const featuresPathPois = featuresPath.filter(
          (feature) => feature.geometry.type == "Point"
        );
        const featuresPathOther = featuresPath.filter(
          (feature) => feature.geometry.type != "Point"
        );

        this.features_path = await featuresPathOther.map(Object.freeze);

        this.features_path_pois = await featuresPathPois.map(Object.freeze);

        //Cambiar cuando tengamos el servicio hecho
        const url_analysis = `/api/v1/service/${this.service_id}/report/json`;
        const respuestaAnalysis = await axios.get(
          url_analysis,
          this.getHeaders()
        );

        if (respuestaAnalysis.data.status != "ok") {
          console.log("ha habido un error al cargar el análisis");
          return;
        }
        const respuestaAnalisysJson = respuestaAnalysis.data.json;

        //const respuestaAnalisysJson = jsonReport.json;

        //WARNINGS
        const arrayWarningsWithGeoJson = respuestaAnalisysJson.alerts.filter(
          (alert) => alert.type == "warning" && alert.geojson != null
        );

        const featuresWarning = arrayWarningsWithGeoJson.map(function (
          warning
        ) {
          if (warning.geojson.type == "Feature") {
            return warning.geojson;
          } else {
            // (warning.geojson.type =="FeatureCollection"){
            return warning.geojson.features;
          }
        });

        const featuresWarningFlat = featuresWarning.flat();

        const featuresWarningFlatPois = featuresWarningFlat.filter(
          (feature) => feature.geometry.type == "Point"
        );
        const featuresWarningFlatOther = featuresWarningFlat.filter(
          (feature) => feature.geometry.type != "Point"
        );

        this.features_warning_analysis = await featuresWarningFlatOther.map(
          Object.freeze
        );
        this.features_warning_analysis_pois = await featuresWarningFlatPois.map(
          Object.freeze
        );

        //ERRORS
        const arrayErrorsWithGeoJson = respuestaAnalisysJson.alerts.filter(
          (alert) => alert.type == "error" && alert.geojson != null
        );

        const featuresError = arrayErrorsWithGeoJson.map(function (error) {
          if (error.geojson.type == "Feature") {
            return error.geojson;
          } else {
            // (warning.geojson.type =="FeatureCollection"){
            return error.geojson.features;
          }
        });

        const featuresErrorFlat = featuresError.flat();

        const featuresErrorFlatPois = featuresErrorFlat.filter(
          (feature) => feature.geometry.type == "Point"
        );
        const featuresErrorFlatOther = featuresErrorFlat.filter(
          (feature) => feature.geometry.type != "Point"
        );

        this.features_error_analysis = await featuresErrorFlatOther.map(
          Object.freeze
        );
        this.features_error_analysis_pois = await featuresErrorFlatPois.map(
          Object.freeze
        );
      } catch (err) {
        console.log(err);
        console.log("ha habido un error");
      }
    },
  },
  inject: ["getHeaders"],
};
</script>
<style lang="scss" scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 5px;
  font-size: 0.8rem !important;
}
.v-card__title {
  text-align: center;
  display: block;
}
.feature-popup {
  position: absolute;
  left: -50px;
  bottom: 12px;
  width: 20em;
  max-width: none;

  &:after,
  &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
}
</style>
