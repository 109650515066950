<template>
  <v-card v-if="dataTable_configuration != null" flat> 
    <v-data-table 
    
      v-model="selected"
      :height="dataTableHeight"
      dense
      fixed-header
      :headers="dataTable_configuration.headers"
      :items="items"
      :item-key="dataTable_configuration.row_click_selected_id"

      :search="search"
      :items-per-page="dataTable_configuration.items_per_page"
      :loading="loading"
      loading-text="Carregant"
      class="d-print-block"
      :footer-props="{
        'disable-items-per-page': true,
        'items-per-page-text': '',
        'items-per-page-options': [dataTable_configuration.items_per_page],
      }"
      :page="page"
      @click:row="rowClick"
      @current-items="itemsChanged"
      multi-select
      show-select
    >
      <template v-slot:top>
        <v-toolbar dense flat>
          <v-toolbar-title class="caption"> Exportar: </v-toolbar-title>
          <v-divider class="mx-4" vertical inset></v-divider>
          <v-btn icon v-on:click="exportCSVFile(items, 'seleccio')">
            <span class="hidden-sm-and-down"></span>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-btn>
          <!--<v-btn icon v-on:click="printMe">
            <span class="hidden-sm-and-down"></span>
            <v-icon>mdi-file-pdf</v-icon>
          </v-btn>-->
          <v-divider class="mx-4" vertical inset></v-divider>
          <v-btn icon v-on:click="selected=[]">
            <span class="hidden-sm-and-down"></span>
            <v-icon>mdi-selection-off</v-icon>
          </v-btn>

          <v-divider class="mx-4" vertical inset></v-divider>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
            v-if="dataTable_configuration.searcher === true"
            v-model="search"
            append-icon="mdi-magnify"
            label="Cercar"
            single-line
            hide-details
            class="search-input justify-end"
          ></v-text-field>

          <!--<v-divider class="mx-4" vertical inset></v-divider>-->
          <!--<v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>-->
        </v-toolbar>
      </template>

      <template
        v-for="(head, i) in computedHeaders"
        v-slot:[`item.${head.value}`]="{ item }"
        @
      >
        <v-icon
          v-if="head.type === 'icon'"
          :key="i"
          class="mr-1"
          @click="onIconClick(item, head.value)"
          v-text="head.icon"
        >
        </v-icon>
        <div v-else-if="head.type === 'icon-with-text-color'" :key="i">
          <v-icon
            class="mr-0"
            @click="onIconClick(item, head.value)"
            v-text="head.icon"
            :color="getColorIcon(item, head.color)"
          >
          </v-icon
          ><br />
          <span>
            {{ getTextIcon(item, head.value) }}
          </span>
        </div>
        <span v-else-if="head.type === 'text-date'" :key="i">
          {{ getDateFormat(item[`${head.value}`], head.format) }}
        </span>
        <v-chip
          v-else-if="head.type === 'chip-label'"
          :key="i"
          label
          class="ma-1 font-weight-bold justify-center"
          :style="{
            'min-width': getColorChip(item, head.value, head.style)[
              'min-width'
            ],
          }"
          :color="
            getColorChip(item, head.value, head.style)['background-color']
          "
          :text-color="getColorChip(item, head.value, head.style)['color']"
          dark
          >{{ getTextChip(item, head.value) }}
        </v-chip>
        <span
          v-else-if="head.type === 'chip-label-array-color-by-key'"
          :key="i"
        >
          <v-chip
            v-for="(item, a) in valueItem(item, head.value)"
            :key="a"
            label
            class="ma-1 font-weight-bold justify-center"
            :color="
              getColorChip(item, head.value, head.style)['background-color']
            "
            :text-color="getColorChip(item, head.value, head.style)['color']"
            >{{ item.descripcio }}
          </v-chip>
        </span>
        <v-chip
          v-else
          :key="i"
          class="ma-1 font-weight-bold justify-center"
          :style="{
            'min-width': getColorChip(item, head.value, head.style)[
              'min-width'
            ],
          }"
          :color="
            getColorChip(item, head.value, head.style)['background-color']
          "
          :text-color="getColorChip(item, head.value, head.style)['color']"
          dark
          >{{ getTextChip(item, head.value) }}
        </v-chip>
      </template>

      <template v-slot:no-data>
        <v-alert
          dense
          :value="true"
          color="#d9f2f5"
          icon="mdi-information-outline"
          class="mt-0 mb-0 mx-n4"
          tile
        >
          No hi ha dades disponibles
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";
var XLSX = require("xlsx");

//import datatablesconfiguration from "@/config/datatables.json";

export default {
  name: "datatable-consulta",
  props: {
    query: {
      type: String,
      default: "",
    },
    dataTable_configuration: {
      type: Object,
    },
    dataTable_height: {
      type: String,
      default: "100vh",
    },
    deselectAllRows: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {
    console.log("timer creado");
    this.dataTableConfiguration = this.dataTable_configuration;
    this.dataTableHeight = this.dataTable_height;

    let refresh_frequency = this.dataTableConfiguration.refresh_frequency;
    if (refresh_frequency == -1) {
      this.getItems();
    } else {
      const timer = setInterval(() => {
        this.getItems();
      }, this.dataTableConfiguration.refresh_frequency);

      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    }
  },
  data: () => ({
    selectedId: -1,
    search: "",
    loading: false,
    items: [],
    dataTableConfiguration: null,
    dataTableHeight: null,
    selected: [],
    page: 1,
    //valor: null,
    //dataTableConfiguration: null,
  }),
  methods: {
    getProp(obj, key) {
      return key.split(".").reduce(function (o, x) {
        return typeof o == "undefined" || o === null ? o : o[x];
      }, obj);
    },

    printMe() {
      window.print();
    },
    /*convertToCSV(objArray) {
      let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
          if (line != "") line += ",";
          line += array[i][index];
        }
        str += line + "\r\n";
      }
      return str;
    },
    exportCSVFile(headers, items, fileTitle) {
      //Next 5 lines add the table headers to the .csv file, it works like a charm
      let keys = headers.map((x) => x.value);
      let values = headers.map((x) => x.text);
      let result = {};
      keys.forEach((key, i) => (result[key] = values[i]));
      items.unshift(result);
      // Convert Object to JSON
      let csv = this.convertToCSV(items);
      let exportedFilenmae = fileTitle + ".csv" || "export.csv";
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },*/
    download_file(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },
    exportCSVFile(items, fileTitle) {
      console.log(items);
      let header = this.dataTableConfiguration.headers.map(
        (element) => element.value
      );
      console.log(header);
      let header_titles = this.dataTableConfiguration.headers.map(
        (element) => element.text
      );
      var dataRows = [];
      //this.selected.map( row => {
      items.map((row) => {
        var dataRow = [];
        header.map((column) => {
          let column_value = this.getProp(row, column);
          //let column_value=row[column]
          console.log(column_value);
          if (column_value == "true") {
            column_value = "Sí";
          } else if (column_value == "false") {
            column_value = "No";
          }
          dataRow.push(column_value);
        });
        dataRows.push(dataRow);
      });

      const ws = XLSX.utils.aoa_to_sheet([header_titles, ...dataRows]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "hola");
      var ws1 = wb.Sheets[wb.SheetNames[0]];
      var csv = XLSX.utils.sheet_to_csv(ws1, { strip: true });
      this.download_file(csv, fileTitle + ".csv", "text/csv;encoding:utf-8");
    },
    exportSheet(items, fileTitle, sheetTitle) {
      //let header = this.headers.map(element => element.value)
      let header = this.dataTableConfiguration.headers.map(
        (element) => element.value
      );
      let header_titles = this.dataTableConfiguration.headers.map(
        (element) => element.text
      );
      var dataRows = [];
      //this.selected.map( row => {
      items.map((row) => {
        var dataRow = [];
        header.map((column) => {
          let column_value = this.getProp(row, column);
          console.log(column_value);
          if (column_value == "true") {
            column_value = "Sí";
          } else if (column_value == "false") {
            column_value = "No";
          }
          dataRow.push(column_value);
        });
        dataRows.push(dataRow);
      });

      const ws = XLSX.utils.aoa_to_sheet([header_titles, ...dataRows]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetTitle);
      XLSX.writeFile(wb, fileTitle + ".xlsx");
    },
    getDateFormat(date, format) {
      let returnDate = "";
      if (date != null && date != "null") {
        console.log(date);
        console.log(format);
        returnDate = this.$date(date).format(format);
      }

      return returnDate;
    },
    getItems() {
      //Quitar luego en producción
      // var queryRandom = "?_start=" + Math.floor(Math.random() * 50) + "&_end=100";
      // queryRandom=""
      // console.log("getItems " + this.dataTable_configuration.data_url);
      if(this.dataTable_configuration.data_url == "") {
        this.items=[]
        return
      }
      axios
        .get(this.dataTable_configuration.data_url + this.query,this.getHeaders())
        .then((respuesta) => {
          if (this.dataTableConfiguration.data_item == "") {
            this.items = respuesta;
          } else {
            let itemsx = this.resolve(this.dataTableConfiguration.data_item, respuesta)
            this.items = itemsx
            this.items = itemsx.map(el=>{ 
              Object.keys(el).map(key => {
                if(typeof el[key] == 'object'){
                  el[key] = JSON.stringify(el[key]).replace(/,/g, ', ').replace(/[{}"]/g, "");
                } if(typeof el[key] == 'boolean'){
                  el[key] = el[key]?"Si":"No"
                } 
              })
              
              // el.containers = JSON.stringify(el.containers).replace(/,/g, ', ').replace(/[{}"]/g, "");
              // el.estimateds = JSON.stringify(el.estimateds).replace(/,/g, ', ').replace(/[{}"]/g, "");
              return el
            })  

            // this.items = this.resolve(this.dataTableConfiguration.data_item, respuesta)
 
          }
        });

        //mejorar
        this.items = this.items.map(el=>{
          el.containers = JSON.stringify(el.containers)
          return el
        })
        this.items=[]
    },
    reloadItems(){
      this.getItems()
      this.selected = [];
      this.selectedId = -1;
    },
    getColorChip(item, value, style) {
      let color = null;
      let backgroundColor = null;
      let minWidth = style["min-width"];

      if (style.type === "valueIN") {
        for (let i = 0; i < style.categories.length; i++) {
          //if (item[value] == style.categories[i].value) {
          if (this.resolve(value, item) == style.categories[i].value) {
            color = style.categories[i]["color"];
            backgroundColor = style.categories[i]["background-color"];
          }
        }
      } else if (style.type === "valueNULL") {
        //if (item[value] === null) {
        if (this.resolve(value, item) === null) {
          let arr_style_null = style.categories.filter(
            (category) => category.value === null
          );
          color = arr_style_null[0]["color"];
          backgroundColor = arr_style_null[0]["background-color"];
        } else {
          let arr_style_not_null = style.categories.filter(
            (category) => category.value === ""
          );
          color = arr_style_not_null[0]["color"];
          backgroundColor = arr_style_not_null[0]["background-color"];
        }
      } else if (style.type === "keyIN") {
        for (let i = 0; i < style.categories.length; i++) {
          if (item[style.categories[i].key] == style.categories[i].value) {
            color = style.categories[i]["color"];
            backgroundColor = style.categories[i]["background-color"];
          }
        }
      }
      return {
        color: color,
        "background-color": backgroundColor,
        "min-width": minWidth,
      };
    },
    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getTextChip(item, value) {
      return this.resolve(value, item);
    },
    getTextIcon(item, value) {
      return this.resolve(value, item);
    },
    getColorIcon(item, value) {
      return this.resolve(value, item);
    },
    onIconClick(item, head) {
      this.$emit(`${head}`, item);
    },
    valueItem(item, value) {
      return this.resolve(value, item);
    },
    rowClick(item, row) {
      //console.log(item);
      console.log("Dynamicdatatable.rowClick");
      row.select(true);

      console.log(item);
      this.selectedId = this.valueItem(
        item,
        this.dataTable_configuration.row_click_selected_id
      );
      
      console.log("this.selectedId");
      console.log(this.selectedId);

      this.valuesToEmit = this.dataTable_configuration.row_click.map(
        (campo_emit) => {
          return {
            name: campo_emit.name,
            value: this.valueItem(item, campo_emit.value),
          };
        }
      );
      console.log("this.selectedId");
      console.log(this.selectedId);

      this.$emit("rowsSelected", this.selected);
    },
    itemsChanged() {
      this.jumpToSelection();
    },
    jumpToSelection() {
      let indexOfItem = this.items
        .map((item) => item[this.dataTable_configuration.row_click_selected_id])
        .indexOf(this.selectedId);
        
      if (indexOfItem != -1) {
        this.page = Math.ceil(
          (indexOfItem + 1) / this.dataTableConfiguration.items_per_page
        );
      } else {
        this.page = 1;
      }
    },
  },
  computed: {
    computedHeaders: function () {
      return this.dataTable_configuration.headers.filter(
        (header) =>
          header.type === "icon" ||
          header.type === "icon-with-text-color" ||
          header.type === "text-date" ||
          header.type === "chip" ||
          header.type === "chip-label" ||
          header.type === "chip-label-array-color-by-key"
      );
    },
  },

  watch: {
    dataTable_configuration: {
      handler(){
       console.log("cambio en dataTable_configuration");
       this.getItems()
       this.selected = [];
        this.selectedId = -1;
        this.$emit("deselectedAllRows");
      },
     deep: true
    },
    selected(){
      console.log("cambio select");
      this.$emit("rowsSelected", this.selected); 
    },

    dataTableConfiguration: function () {
      this.getItems();
    },
    query: function () {
      this.getItems();
    },
    deselectAllRows: function (newVal) {
      console.log("deseleccionar todas las filas")
      if (newVal == true) {
        this.selected = [];
        this.selectedId = -1;
        this.$emit("deselectedAllRows");
      }
    },
  },
  inject: ["getHeaders"],
};
</script>

<style scoped>

/deep/.v-text-field {
}
/deep/.v-label {
  font-size: 0.75rem;
}
/deep/.v-input {
  font-size: 0.75rem;
}
/deep/tr.v-data-table__selected {
  background: #71cfdb45 !important;
}

.v-data-table {
  table-layout: fixed;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 0.7rem !important;
}
.v-chip.v-size--default {
  font-size: 0.7rem;
  height: 27px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 0.7rem !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 5px !important;
}
  /* .v-data-table__wrapper{
    overflow-y: hidden !important;
  } */
 
.v-data-table /deep/ .v-data-table__wrapper {
  /* overflow: unset; */
   padding-bottom: 10px !important;

}
 
/*.v-data-table /deep/ .v-data-table__wrapper {
	overflow-x: auto;
    overflow-y: auto;
    height: 24vh;
}*/
@media screen {
  #preview {
    display: none;
  }
}
@media print {
  * {
    display: none;
  }

  #preview {
    display: inline;
  }
  #preview input {
    display: none;
  }
}
</style>
