<template>
  <DefaultLayout>
    <v-container fluid class="grey lighten-5 ma-0">
      <v-row dense>
        <v-col cols="12" sm="12" md="S12">
          <DynamicSearch
            translationModel="reports"
            ref="dynamicSearch"
            class="mt-4"
            v-on:search="setSearch"
            :model="model"
            :jsonTemplate="dynamicSearch.jsonTemplate"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="12" md="S12">
          <Itineraries
            :dataTableConfiguration="
              datatable_itineraries_executed_configuration
            "
            datatableHeight="30vh"
            :digital_clock="false"
            :llegenda="true"
            title="Itineraris executats"
            @itinerary_selected="itinerarySelected($event)"
            :query="query"
          ></Itineraries>
        </v-col>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
//import Map from "@/components/map/Map.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import Itineraries from "@/components/dashboard/itineraries.vue";
import DynamicSearch from "@/components/dynamic/DynamicSearch";
import datatablesconfiguration from "@/config/datatables.json";
import dynamicSearchJsonTemplate from "@/config/search/informs/itinerarisExecuted.json";

export default {
  name: "reports",
  components: {
    DynamicSearch,
    Itineraries,
    DefaultLayout,
  },
  data() {
    return {
      query: "",
      model: "itineraris",
      itinerary_selected_id: 0,
      datatable_itineraries_executed_configuration:
        datatablesconfiguration.reports.datatable_itineraries_executed,
      dynamicSearch: {
        jsonTemplate: dynamicSearchJsonTemplate,
      },
    };
  },
  mounted() {
    this.dynamicSearch.jsonTemplate = dynamicSearchJsonTemplate;
    console.log(dynamicSearchJsonTemplate);
    this.$refs.dynamicSearch.fetchItems(dynamicSearchJsonTemplate);
  },
  methods: {
    itinerarySelected(id) {
      this.itinerary_selected_id = id;
    },

    setSearch(inputs) {
      //DynamicSearch component emits to this function whenever `Cercar` is pressed
      //Here we should call the server with our search request for a particular model
      //The response should be sent to DynamicDataTable as a prop, to show the queried data
      let newVals = {};

      newVals.itinerary_contractor_code =
        inputs.itinerary_contractor_code == undefined
          ? ""
          : inputs.itinerary_contractor_code;
      newVals.itinerary_type_code =
        inputs.itinerary_type_code == undefined
          ? ""
          : inputs.itinerary_type_code;
      newVals.vehicle_contractor_code =
        inputs.vehicle_contractor_code == undefined
          ? ""
          : inputs.vehicle_contractor_code;

      newVals.finalday = this.changeDateFormat(inputs.finalday);
      newVals.from_service_starting_timestamp = this.changeTimeStampFormat(
        inputs.from_service_starting_timestamp
      );
      newVals.initialday = this.changeDateFormat(inputs.initialday);
      newVals.to_service_starting_timestamp = this.changeTimeStampFormat(
        inputs.to_service_starting_timestamp
      );

      // inputs.finalday = this.changeDateFormat(inputs.finalday)
      // inputs.from_service_starting_timestamp = this.changeDateFormat(inputs.from_service_starting_timestamp)
      // inputs.initialday = this.changeDateFormat(inputs.initialday)
      // inputs.to_service_starting_timestamp = this.changeDateFormat(inputs.to_service_starting_timestamp)

      console.log(newVals);
      console.log(inputs);
      this.query =
        "?" +
        Object.keys(newVals)
          .map((key) => key + "=" + newVals[key])
          .join("&");

      let serviceStatusTrue = Object.keys(inputs)
        .map((key) => {
          console.log(inputs[key] + " - " + key);
          if (inputs[key] == "true") {
            return key;
          }
          return false;
          // key + '=' + inputs[key]
        })
        .filter(Boolean);
      //.join(",");

      console.log(serviceStatusTrue);
      for (let i = 0; i < serviceStatusTrue.length; i++) {
        this.query = this.query + "&service_status=" + serviceStatusTrue[i];
      }
      //newVals.service_status = serviceStatusTrue;

      console.log("this.query");
      this.query=this.query+"&random="+Math.random();

      console.log(this.query);
    },
    changeDateFormat(input) {
      if (input == undefined) return "";
      return input
      //2022-07-13 -> 13/07/2022
      // let date = new Date(input);
      // let year = date.getFullYear();
      // let month = (1 + date.getMonth()).toString().padStart(2, "0");
      // let day = date.getDate().toString().padStart(2, "0");
      // return day + "/" + month + "/" + year;
    },
    changeTimeStampFormat(input) {
      if (input == undefined) return "";
      //2022-07-13 -> '2022-07-28T22:31'
      let date = new Date(input);
      return date.toISOString().slice(0, -8);
    },
  },
};
</script>
<style scoped>
@media (min-width: 1904px) {
  .container {
    max-width: 100% !important;
  }
}
.container {
  max-width: 100% !important;
}
</style>
