<template>
  <!--<v-container>-->
  <v-layout child-flex>
    <v-card height="50%">
      <v-card-title
        shaped
        class="p-0 subtitle-2"
        style="background-color: #ffffff; color: black"
        ><!--{{ title }}<DigitalClock class="ml-16"
                v-if="digital_clock == true"
              ></DigitalClock>-->
        <v-row
          ><v-col cols="12" sm="8" md="7">{{ title }}</v-col
          ><v-col cols="12" sm="4" md="5" align="right">
            <span class="titol-llegenda" v-if="llegenda == true"
              >Alarmes

              <v-chip
                class="ma-1 font-weight-bold justify-center"
                style="width: 70px"
                :color="tag.color"
                v-for="tag in tags_llegenda"
                label
                :key="tag"
              >
                {{ tag.valor }}
              </v-chip>
            </span>
            <DigitalClock
              v-if="digital_clock == true"
            ></DigitalClock> </v-col></v-row
      ></v-card-title>
      <v-card-text>
        <DynamicDataTable
          @rowSelected="itinerarySelected($event)"
          :dataTable_configuration="dataTableConfiguration"
          :dataTable_height="datatableHeight"
          :query="query"
          :deselectAllRows="deselect_all_rows"
          @deselectedAllRows="itinerariesDeselectedAllRows"
          @position="openMapDialog"
          @log="openLogDialog"
          @uploaded_file="openCodeDialog"
          @vehicle_id="changeVehicle"
          @view_planification="viewPlanification"
          @geojson="openMapDialog"
        />
      </v-card-text>
    </v-card>
  </v-layout>
  <!--</v-container>-->
</template>

<script>
//import axios from 'axios'

//import datatablesConfiguration from '@/config/datatables.json'
import DynamicDataTable from "@/components/dynamic/DynamicDataTable";
import DigitalClock from "@/components/digitalClock";

export default {
  components: {
    DynamicDataTable,
    DigitalClock,
  },
  /*mounted(){
    console.log(this.datatablesconfiguration.dashboard.datatable_itineraries_actives);
  },*/
  props: {
    dataTableConfiguration: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: "",
    },
    query: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Titulo",
    },
    digital_clock: {
      type: Boolean,
    },
    datatableHeight: {
      type: String,
      default: "100vh",
    },
    llegenda: {
      type: Boolean,
      default: false,
    },
    deselectAllRows: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    /*tags_llegenda: [
      { valor: "Lleu", color: "rgba(232, 232, 53, 0.5)" },
      { valor: "Moderat", color: "rgba(227, 128, 49, 0.5)" },
      { valor: "Greu", color: "rgba(193, 18, 18, 0.5)" },
    ],*/
    tags_llegenda: [
      { valor: "Warning", color: "rgba(232, 232, 53, 0.5)" },
      { valor: "Error", color: "rgba(193, 18, 18, 0.5)" },
    ],
    deselect_all_rows: false,
    //dataTableConfiguration: ''
  }),
  /*inject:[
    'datatablesconfiguration'
  ],*/
  methods: {
    itinerarySelected: function (values) {
      
      this.$emit("itinerarySelected", values);
    },
    itinerariesDeselectedAllRows: function () {
      this.$emit("deselectedAllRows");
    },
  },
  watch: {
    deselectAllRows: function (newVal) {
      this.deselect_all_rows = newVal;
    },
  },
};
</script>
<style scoped>
.icon-title {
  position: absolute;
  right: 0;
}
.v-chip.v-size--default {
  font-size: 0.7rem;
  height: 22px;
}
.titol-llegenda {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>
