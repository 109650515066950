<template>
  <vl-layer-group
    :ref="name"
    :id="name"
    :opacity="opacity"
    :visible="visible"
    :z-index="zIndex"
  >
    <vl-layer-vector :ref="vector_name" :id="vector_name" :visible="visible" :z-index="zIndex">
      <vl-source-vector
        ref="vectorSource"
        :features.sync="features"
        :strategy-factory="loadingStrategyFactory"
      ></vl-source-vector>
      <vl-style-func :factory="styleFunction" />
    </vl-layer-vector>
    <vl-layer-vector v-if="withClustering" :ref="cluster_name" :id="cluster_name" render-mode="image" :z-index="zIndex">
      <vl-source-cluster :distance="50">
        <vl-source-vector :features="features_locations"></vl-source-vector>
      </vl-source-cluster>

      <vl-style-func :factory="styleFunctionCluster"></vl-style-func>
    </vl-layer-vector>
  </vl-layer-group>
</template>

<script>
//import axios from 'axios'
import Vue from "vue";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import axios from "axios";
import { loadingAll, createStyle } from "vuelayers/lib/ol-ext";
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
import Point from "ol/geom/Point";

Vue.use(VueLayers);

export default {
  components: {},
  props: {
    path_id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#71cfdb",
    },
    poisWithSameColor:{
      type:Boolean,
      default:true,
    },
    fitAtLoad:{
      type: Boolean,
      default: false,
    },
    elementsWithTrashIcon:{
      type:Boolean,
      default: false,
    },
    withClustering:{
      type:Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "es",
    },

  },
  watch: {
    path_id: async function () {
      await this.loadFeatures();
    },
    color: function () {
      this.$refs[this.name].refresh();
      this.$refs[this.vector_name].refresh();
    },
  },
  data: () => ({
    url_base: "/api/v1/path/",
    features: [],
    features_locations: [],
    opacity: 1,
    zIndex:100,
  }),
  created() {
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);
  },
  async mounted() {
    await this.loadFeatures();
  },
  computed: {
    cluster_name: function () {
      return `CLUSTER_${this.name}`;
    },
    vector_name: function () {
      return `VECTOR_${this.name}`;
    },
  },
  methods: {
    getDateFormat: function (date, format) {
      let returnDate = "";

      if (date != null) {
        if (date.length == 8) {
          returnDate = date.substr(0, date.length - 3);
        } else {
          returnDate = this.$date(date).format(format);
        }
      }

      return returnDate;
    },
    loadingStrategyFactory: function () {
      return loadingAll;
    },
    styleFunctionCluster() {
      const cache = {};

      return function __clusterStyleFunc(feature, resolution) {
        if (resolution <= 3) return new Style({});
        const size = feature.get("features").length;
        let style = cache[size];

        if (!style) {
          style = createStyle({
            imageRadius: 11,
            strokeColor: "#fff",
            fillColor: "#71cfdb",
            text: size.toString(),
            textFillColor: "#FFF",
          });

          cache[size] = style;
        }

        return [style];
      };
    },
    styleFunction: function () {
      const context = this;
      return function (feature, resolution) {
        const styles = [];
        const geometry = feature.getGeometry();
        const feature_type = feature.getProperties().type;
        const feature_subtype = feature.getProperties().subtype;

        //const feature_code= feature.getProperties().code;
        let stylePoi = null;
        let styleSection = null;
        let styleText = null;
        let styleZone = null;
        let styleLocation = null;
        let styleGeomarker = null;

        switch (feature_type) {
          case "point_of_interest":
            if (resolution > 10) return new Style({});
            switch (feature_subtype) {
              case "start":
               
                  stylePoi = new Style({
                    image: new Icon({
                      src: require("@/assets/icons_ubicacions/inici_iti.png"),
                      //anchor: [0.75, 0.5],
                      rotateWithView: true,
                      scale: 0.45,
                    }),
                    zIndex: 6,
                  });
                  styleText = new Style({
                    text: new Text({
                      text: feature.getProperties().time_from,
                      offsetY: -60,
                      font: "bold 11px sans-serif",
                    }),
                    zIndex: 6,
                  });
                
                break;
              case "end":
                stylePoi = new Style({
                  image: new Icon({
                    src: require("@/assets/icons_ubicacions/final_iti.png"),
                    //anchor: [0.75, 0.5],
                    rotateWithView: true,
                    scale: 0.45,
                  }),
                  zIndex: 6,
                });
                styleText = new Style({
                  text: new Text({
                    text: feature.getProperties().time_to,
                    offsetY: -60,
                    font: "bold 11px sans-serif",
                  }),
                  zIndex: 6,
                });
                break;
            }

            styles.push(stylePoi);
            styles.push(styleText);
            break;
          case "location":
            if (context.elementsWithTrashIcon){
              if (resolution > 3) return new Style({});
              styleLocation = new Style({
                image: new Icon({
                  src: require("@/assets/icons_ubicacions/" +
                    feature.getProperties().fractions +
                    ".png"),
                  //anchor: [0.75, 0.5],
                  rotateWithView: true,
                  scale: 0.5,
                }),
                zIndex: 5,
              });
              styleText = new Style({
                text: new Text({
                  text: feature.getProperties().n_containers.toString(),
                  offsetX: 10,
                  font: "bold 11px sans-serif",
                }),
                zIndex: 5,
              });

              styles.push(styleLocation);
              styles.push(styleText);
            }
            else{
              if (resolution > 10) return new Style({});
              if (resolution > 2 && resolution <=5 ){
                styleLocation = new Style({
                  image: new CircleStyle({
                      radius: 5,
                      fill: new Fill({ color: "rgba(255, 255, 255, 1)" }),
                      stroke: new Stroke({
                        color: context.color,
                        width: 2,
                      }),
                    }),
                  zIndex: 5,
                });
              }
              else{
                styleLocation = new Style({
                  image: new CircleStyle({
                      radius: 22,
                      fill: new Fill({ color: "rgba(214, 72, 150, 0)" }),
                      stroke: new Stroke({
                        color: context.color,
                        width: 4.5,
                      }),
                    }),
                  zIndex: 5,
                });
              }
              styles.push(styleLocation);
              
            }
            
            break;
          case "zone":
            styleZone = new Style({
              fill: new Fill({
                //color: "rgba(214, 72, 150, 0.2)",
                color: `${context.color}33`,
              }),
              stroke: new Stroke({
                width: 2,
                //color: "rgba(214, 72, 150, 1)",
                color: context.color,
              }),
              zIndex: 2,
            });
            styleText = new Style({
              text: new Text({
                text: feature.getProperties().orden,
                font: "bold 11px sans-serif",
              }),
              zIndex: 3,
            });
            styles.push(styleZone);
            styles.push(styleText);
            break;
          case "section":
            styleSection = new Style({
              stroke: new Stroke({
                //color: "rgba(214, 72, 150, 1)",
                color: context.color,
                width: 2,
              }),
              zIndex: 1,
            });
            styles.push(styleSection);
            if (geometry.getType() == "MultiLineString") {
              var i = 0;

              geometry.getLineString().forEachSegment(function (start, end) {
                if (i == 10) {
                  i = 0;
                }

                if (i == 0) {
                  const dx = end[0] - start[0];
                  const dy = end[1] - start[1];
                  const rotation = Math.atan2(dy, dx);
                  // arrows
                  styles.push(
                    new Style({
                      geometry: new Point(end),
                      image: new Icon({
                        color: context.color,
                        src: require("@/assets/arrow-itinerary.png"),
                        anchor: [0.75, 0.5],
                        rotateWithView: true,
                        rotation: -rotation,
                        scale: 0.5,
                      }),
                      zIndex: 1,
                    })
                  );
                }
                i = i + 1;
              });
            }
            break;
          default:
            //Para probar punto
            styleGeomarker = new Style({
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({ color: "green" }),
                stroke: new Stroke({
                  color: "blue",
                  width: 10,
                }),
              }),
              zIndex: 1,
            });
            styles.push(styleGeomarker);
            break;
        }
        return styles;
      };
    },
    loadFeatures: async function () {
      const url = this.url_base + this.path_id +"/";

      try {
        const respuesta = await axios.get(url,this.getHeaders());
        const respuestaJSON=JSON.parse(respuesta.data.geojson);
        this.features = await respuestaJSON.features.map(
          Object.freeze
        );
       
        this.features_locations = this.features.filter(
          (feature) => feature.properties.type == "location"
        );
        if (this.fitAtLoad){
          setTimeout(() => {
            this.$refs.vectorSource.$view.fit(
              this.$refs.vectorSource.$source.getExtent(),
              {
                size: this.$refs.vectorSource.$map.getSize(),
                duration: 10,
              }
            );
          }, 200);
        }
      } catch (err) {
        console.log("ha habido un error");
      }
    },
  },
  inject: ["getHeaders"],
};
</script>
