<template>
  <!--<v-container>-->
  <v-layout child-flex>
    <v-card height="100%" class="pa-0">
      <!--<v-card-title
        shaped
        class="p-0 subtitle-2"
        style="background-color: #ffffff; color: black"
        >
        <v-row
          ><v-col cols="12" sm="8" md="8">{{ title }}</v-col
          ><v-col cols="12" sm="4" md="4"
            > </v-col></v-row
      ></v-card-title>-->
      <v-card-text>
        <vl-map
          @click = "getFeatureInfo"
          ref="map"
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="true"
          :style="mapHeight"
          data-projection="EPSG:4326"
        >
          <vl-view ref="mapView" :center.sync="center" :zoom.sync="zoom" />

          <template v-for="menuLayer in menusLayers">
            <template v-for="layerGroup in menuLayer.layersMenu.layersGroups">
              <template v-if="layerGroup.layersType == 'base'">
                <component
                  v-for="layer in layerGroup.layers"
                  :is="layer.layerComponent"
                  :key="layer.name"
                  :id="layer.name"
                  :visible="layer.visible"
                  :opacity="layer.opacity"
                  :z-index="layer.zindex"
                >
                  <component
                    :ref="layer.name"
                    :is="layer.sourceComponent"
                    v-bind="layer"
                  />
                </component>
              </template>
              <template v-if="layerGroup.layersType == 'overlay'">
                <template v-for="layer in layerGroup.layers">
                  <component
                    v-if="'component' in layer"
                    :is="layer.component"
                    :key="layer.name"
                    :ref="layer.name"
                    v-bind="layer"
                    :visible="layer.visible"
                    :opacity="layer.opacity"
                    :z-index="layer.zindex"
                  ></component>
                  <component
                    v-else
                    :is="layer.layerComponent"
                    :key="layer.name"
                    :id="layer.name"
                    :visible="layer.visible"
                    :opacity="layer.opacity"
                    :z-index="layer.zindex"
                  >
                    <component :is="layer.sourceComponent" v-bind="layer" />
                  </component>
                </template>
              </template>
              <template v-if="layerGroup.layersType == 'overlayLocations'">
                <vl-layer-group :key="layerGroup.name" :id="layerGroup.name" :z-index="2">
                  <vl-layer-image :z-index="1">
                    <vl-source-image-wms
                      :url="overlayLocations.url"
                      layers="locations"
                      :extParams="overlayLocations.clustering"
                    ></vl-source-image-wms>
                  </vl-layer-image>
                  <vl-layer-image :z-index="1">
                    <vl-source-image-wms
                      :url="overlayLocations.url"
                      layers="locations_splitted"
                      :extParams="overlayLocations.extParams"
                    ></vl-source-image-wms>
                  </vl-layer-image>
                </vl-layer-group>
              </template>
              <template v-if="layerGroup.layersType == 'overlayServices'">
                <template v-for="service in layerGroup.services">
                  <template v-for="layer in service.layers">
                    <component
                      v-if="'component' in layer"
                      :is="layer.component"
                      :key="layer.name"
                      :ref="layer.name"
                      v-bind="layer"
                      :visible="layer.visible"
                      :opacity="layer.opacity"
                      :z-index="layer.zindex"
                    ></component>
                    <component
                      v-else
                      :is="layer.layerComponent"
                      :key="layer.name"
                      :id="layer.name"
                      :visible="layer.visible"
                      :opacity="layer.opacity"
                      :z-index="layer.zindex"
                    >
                      <component :is="layer.sourceComponent" v-bind="layer" />
                    </component>
                  </template>
                </template>
                
              </template>
            </template>
          </template>
        </vl-map>
        <LayerSelector
          v-for="menu in menusLayers"
          :key="menu.name"
          :menu="menu"
          @fitLayerClick="fitLayer"
          @changeExtParamsLocations="changeExtParamsLocations"
          @changeViewParamsLocations="changeViewParamsLocations"
          @changeLayerColor="changeLayerColor"
          @deleteLayersClick ="deleteLayersClick"
          @deleteLayerClick ="deleteLayerClick"
        />
      </v-card-text>
    </v-card>
  </v-layout>
  <!--</v-container>-->
</template>

<script>
import Vue from "vue";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import axios from "axios";
import {transform} from 'ol/proj';
//import axios from "axios";
/*import { loadingAll, createStyle } from "vuelayers/lib/ol-ext";
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
import Point from "ol/geom/Point"; */ // Text,
//import Feature from "ol/Feature";
//import GeoJSON from "ol/format/GeoJSON";
//import ItinerariesSearcher from "@/components/dialogs/maps/ItinerariesSearcher";
//import LocationsSearcher from "@/components/dialogs/maps/LocationsSearcher";
//import ElementsSearcher from "@/components/dialogs/maps/ElementsSearcher";
import LayerSelector from "@/components/dialogs/maps/LayerSelector";
import LayerPath from "@/components/layersTypes/layerPath.vue";
import LayerGPS from "@/components/layersTypes/layerGPS.vue";
import LayerLocations from "@/components/layersTypes/layerLocations.vue";
import LayerServiceGPS from "@/components/layersTypes/layerServiceGPS.vue";
import LayerServiceAnalysis from "@/components/layersTypes/layerServiceAnalysis.vue";

//import AddressSearcher from "@/components/dialogs/maps/AddressSearcher";

Vue.use(VueLayers);

export default {
  components: { LayerSelector, LayerPath, LayerGPS, LayerLocations, LayerServiceGPS, LayerServiceAnalysis},
  props: {
    title: {
      type: String,
      default: "Titulo",
    },
    mapHeight: {
      type: String,
      default: "height:100vh",
    },
    menusLayers: {
      type: Array,
      default: () => [],
    },
  },
  watch: {},
  data: () => ({
    center: [2.0953203, 41.325022],
    zoom: 16,
    overlayLocations: {
      url: `/geoserver/ows`,
      extParams: {
        ENV: "incoherences:true;reals:true",
        CQL_FILTER: "fraction IN ('-1')",
      },
      clustering: {
        viewParams: "location_fractions_like: estimateds = '-1'",
        //viewParams: "location_fractions_like: reals like '%FO%' or reals like '%PC%' or reals like '%VI%' or reals like '%EN%' or reals like '%RE%' or reals like '%VO%' or reals like '%VE%' or reals like '%SF%'"
      },
      
    },

    //features: [],
    //features_locations: [],
  }),
  mounted() {},
  methods: {
    loadFeatures: async function (coordinates) {
      
      const coord3857=transform(coordinates,'EPSG:4326','EPSG:3857')
      const bottomLeft3857 =  [coord3857[0]-1,coord3857[1]-1]
      const topRight3857 = [coord3857[0]+1,coord3857[1]+1]
      const bottomLeft4326 = transform(bottomLeft3857,'EPSG:3857','EPSG:4326');
      const topRight4326 = transform(topRight3857,'EPSG:3857','EPSG:4326');

     

      const url = `/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=transparencity:locations&srsName=EPSG:4326&outputFormat=application/json&bbox=${bottomLeft4326[0]},${bottomLeft4326[1]},${topRight4326[0]},${topRight4326[1]}`;

      

      try {
        //const writer = new GeoJSON();
        /*const axiosInstance = axios.create({
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        });*/
        const respuesta = await axios.get(url,this.getHeaders());
        console.log(respuesta.data);
        //const punts_gps = respuesta.data.punts_gps.slice(0, 5);
      }
      catch (err) {
        console.log(err);
        console.log("Ha habido un error al hacer una petición de ubicaciones");
      }
    },

    getFeatureInfo : async function (evt){
       const coordinate = this.$refs.map.getCoordinateFromPixel(evt.pixel);
       await this.loadFeatures(coordinate);

    },
    changeExtParamsLocations: function (wmsLocationsExtParams) {
      this.overlayLocations.extParams = wmsLocationsExtParams;
    },
    changeViewParamsLocations: function (wmsLocationsViewParams){
      this.overlayLocations.clustering = {
        "viewParams":wmsLocationsViewParams
      }
    },
    changeLayerColor: function(){
      
      //console.log(this.$refs.map.$map)
      this.$refs.map.$map.updateSize();
      this.$refs.map.refresh()
      this.$refs.map.updateSize()
      this.$refs.map.render()
    },
    fitLayer: function (layer_name) {
      
      let arrMergedLayersGroups = this.menusLayers
        .map((menu) => menu.layersMenu.layersGroups)
        .flat();
      let arrayLayers = arrMergedLayersGroups.map((layerGroup) =>
        layerGroup.layers ? layerGroup.layers : false
      );
      let mergedLayers = arrayLayers.flat();
      

      /*Para cuando se hace un fit a la capa de servicios*/
      let arrayServices = arrMergedLayersGroups.map((layerGroup) =>
        layerGroup.services ? layerGroup.services : false
      );
      let mergedServices = arrayServices.flat();

      let arrayLayersServices = mergedServices.map((service) =>
        service.layers ? service.layers : false
      );
      let mergedLayersServices = arrayLayersServices.flat();
      /*Fin Para cuando se hace un fit a la capa de servicios*/

      let allLayers= mergedLayers.concat (mergedLayersServices);


      let layer = allLayers.find((layer) => layer.name == layer_name);

      if (layer != null && layer.visible) {
        //console.log("this.$refs");
        //console.log(this.$refs);
        this.$refs.mapView.$view.fit(
          this.$refs[layer_name][0].$refs.vectorSource.$source.getExtent(),
          //this.$refs.vectorSource.$source.getExtent(),
          {
            size: this.$refs.map.$map.getSize(),
            duration: 10,
          }
        );
      }
    },
    deleteLayer:function(layer_name){
      //console.log(layer_name)
      const map = this.$refs.map.$map;
      //console.log(map)
      //console.log(map.getLayers().getArray())
      map.getLayers().getArray()
        .filter(layer => layer.get('id') === layer_name)
        .forEach(layer => map.removeLayer(layer));
    },
    deleteLayersClick: function(layer_names){
      const map = this.$refs.map.$map;

      layer_names.map((layer_name) => {
        map.getLayers().getArray()
        .filter(layer => layer.get('id') === layer_name)
        .forEach(layer => map.removeLayer(layer));
      })
         
    },
    deleteLayerClick: function(layer_name){
      const map = this.$refs.map.$map;
      map.getLayers().getArray()
        .filter(layer => layer.get('id') === layer_name)
        .forEach(layer => map.removeLayer(layer));
     
    }
  },
  inject: ["getHeaders"],
};
</script>
<style scoped>
.icon-title {
  position: absolute;
  right: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px;
}

/deep/.ol-control button {
  background-color: rgb(113 207 219) !important;
}
</style>
