<template>
  <div class="wrapper">
    <div class="text-image">
      <img src="../assets/login/texto.svg" alt="" />
    </div>
    <div class="sun-image">
      <img src="../assets/login/sol.png" alt="" />
    </div>
    <div class="o-404">
      <v-card class="login-card mx-auto" min-width="344">
        <v-card-title>
          <div
            style="
              background-color: #71cfdb;
              width: 100%;
              padding: 6px;
              border-radius: 4px;
            "
            class=""
          >
            <v-img
              alt="Ajuntament del Prat Logo"
              class="shrink mr-2"
              contain
              src="@/assets/log_e_blanc.png"
              transition="scale-transition"
              width="180"
            />
          </div>
          <!--<div>Accedir</div>-->
        </v-card-title>
        <v-card-title style="padding: 0; justify-content: center">
          Accedir
        </v-card-title>
        <v-card-text class="login-card-content">
          <v-text-field v-model="username" label="Usuari"></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            label="Contrasenya"
          ></v-text-field>

          <!--<span class="caption grey--text text--darken-1">
            Recuperar contrasenya
          </span>-->

          <v-alert color="red" v-if="error.show == true" type="error">{{
            error.message
          }}</v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="login-card-content">
          <v-btn block color="error" @click="login()">
            Entrar
            <v-icon>mdi-login</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      left: 9,
      right: 6.1,
      skew: 0,
      username: "elprat",
      password: "prat555prat",
      error: {
        show: false,
        message: "Error",
      },
    };
  },
  mounted() {
    // console.log(this.userData);
  },
  destroyed: function () {},
  methods: {
    async login() {
      
      await axios
        .post("/api/v1/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.error.show = false; 

          this.setUser(response.data);
          this.setHeaders(response.data.token);
          console.log(response.data.token);
          localStorage.setItem("userData", JSON.stringify(response.data));
          this.$router.push("/dashboard")
 1       })
        .catch(() => {
          console.log("no");
          this.error.show = true;
          this.error.message = "Credencials no vàlides";
        });
    },
  },
  inject: ["setUser","setHeaders"],
};
</script>

<style scoped>
.wrapper {
  margin-top: 90px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../assets/login/fondo.svg) no-repeat;
  background-size: cover;
}

.login-card {
  opacity: 0.85;
}

.login-card-content {
}

.svg {
  margin-left: 3.5em;
  position: relative;
  z-index: 100;
}

.text-image {
  position: absolute;
  top: 30px;
  left: 35px;
}

.sun-image img {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 184px;
  height: 184px;
  animation: spin 7s infinite linear;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
