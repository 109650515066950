<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<template>
  <v-layout child-flex>
    <v-card height="90vh">
      <div>
        <Toolbar
          dark
          :currentday="currentday"
          :viewdays="viewdays"
          :locale="locale"
          @goTo="goTo"
          @changeView="changeView"
        />

        <transition name="mapfade" appear>
          <v-simple-table dense>
            <!--Header for Daily view-->

            <thead v-if="viewdays < 7">
              <tr>
                <td class="calDies"></td>
              </tr>
              <tr class="calDies">
                <td class="text-center overline">Itineraris</td>
                <th v-for="hour in 24" :key="hour" style="text-align: center">
                  <a>{{ (hour - 1 + currentHour) % 24 }} h</a>
                </th>
              </tr>
            </thead>

            <!--Header for Weekly and Montly views-->
            <thead v-else-if="viewdays >= 7">
              <tr class="calDies">
                <td></td>
                <td
                  v-for="(day, i) in daysofweek"
                  :key="i"
                  style="
                    border-bottom: none;
                    text-align: center;
                    text-transform: capitalize;
                  "
                >
                  {{ day }}
                </td>
              </tr>
              <tr class="calDies">
                <td class="text-center overline">Itineraris</td>
                <th
                  v-for="(day, i) in daysofmonth.day"
                  :key="daysofmonth.day[i]"
                  style="border-bottom: none; text-align: center"
                  @click="goTo(daysofmonth.days[i])"
                  :class="[
                    dateFormatYYYYMMDD(daysofmonth.days[i]) === todaydate
                      ? 'today'
                      : '',
                  ]"
                >
                  <a>{{ day }}</a>
                </th>
              </tr>
            </thead>

            <tbody v-if="!loading && caldata">
              <!-- Daily View -->
              <template v-if="viewdays < 7">
                <tr v-for="(it, i) in caldata.data" :key="it.itinerari_id">
                  <template
                    v-if="(typeof dailyMapedDatesReduced[i] == 'object')"
                    v-show="
                      it.itinerary_id === focusonitinerari ||
                      focusonitinerari === 0
                    "
                  >
                    <!--So we can focus on a row-->
                    <td
                      class="nomsitineraris"
                      @click="selectItinerari(it.itinerary_id)"
                    >
                      <a>
                        {{ it.itinerary_contractor_code }}
                      </a>
                    </td>
                    <template
                      v-if="(typeof dailyMapedDatesReduced[i] !== 'object')"
                    >
                      <template v-for="i in 24">
                        <td
                          class="higherheight"
                          :key="i"
                          style="
                            background: #71cfdb33;
                            border-bottom: 1px solid #cfd0d2 !important;
                            text-align: center;
                            color: #fff;
                            font-size: 12px;
                            border-right: 1px solid #999 !important;
                          "
                        ></td>
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-for="(item, hour) in dailyMapedDatesReduced[i]"
                      >
                        <td
                          class="higherheight"
                          v-if="typeof item === 'number' && hour < 24"
                          :key="i + hour"
                          style="
                            background: #71cfdb33;
                            bordcurrentdayer-bottom: 1px solid #cfd0d2 !important;
                            text-align: center;
                            color: #fff;
                            font-size: 12px;
                            border-right: 1px solid #999 !important;
                          "
                        ></td>
                        <td
                          class="nopadding higherheight"
                          v-else-if="typeof item === 'object'"
                          :colspan="item.colspan"
                          :key="i + hour"
                          style="
                            text-align: center;
                            color: #ff0000;
                            font-size: 12px;
                          "
                        >
                          <v-btn
                            :color="item.color + '8C'"
                            dark
                            small
                            tile
                            depressed
                            block
                            :ripple="false"
                            style="border-right: 1px solid grey !important"
                            class="tooltip"
                            @click="$emit('vehicle_id', it)"
                          >
                            <i
                              v-if="item.prevDay == true"
                              class="mdi mdi-arrow-left-bold"
                            ></i>
                            <v-icon color="white" class="mr-1">
                              mdi-car
                            </v-icon>
                            <template v-if="item.colspan > 1">
                              {{ item.vehicleContractorCode }}
                            </template>
                            <i
                              v-if="item.nextDay == true"
                              class="mdi mdi-arrow-right-bold"
                            ></i>
                            <span class="tooltiptextdaily">
                              <div class="chip">
                                <span class="icon mx-auto">
                                  <i class="mdi mdi-car"></i>
                                </span>
                                <span>{{ item.vehicleContractorCode }}</span>
                              </div>
                              <br />
                              <div class="chip">
                                <span class="icon mx-auto">
                                  <i
                                    v-if="item.prevDay == true"
                                    class="mdi mdi-arrow-left-thin"
                                  ></i>
                                  <i class="mdi mdi-clock-time-nine"></i>
                                </span>
                                <span
                                  >{{ item.hourInit }} - {{ item.hourFinal }}
                                </span>
                                <i
                                  v-if="item.nextDay == true"
                                  class="mdi mdi-arrow-right-thin"
                                ></i>
                              </div>
                              <div class="chip">
                                {{ item.dateService }}
                              </div>
                            </span>
                          </v-btn>
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
                <tr v-if="(!dailyMapedDatesReduced.find(x=>(typeof x == 'object')))">
                  <td colspan="25">

                    <div> 
                      No hi ha itineraris
                    </div>
                  </td>
                </tr>
                  
              </template>

              <!-- Weekly view -->
              <template v-else-if="viewdays === 7">
                <tr v-for="(it, j) in caldata.data" :key="it.itinerari_id">
                  <template
                    v-if="
                      (it.itinerary_id === focusonitinerari || focusonitinerari === 0) && 
                      (mapDates[j].find(x=>(typeof x == 'object')))
                    "
                  >
                    <td
                      class="nomsitineraris"
                      @click="selectItinerari(it.itinerary_id)"
                    >
                      <a>
                        {{ it.itinerary_contractor_code }}
                      </a>
                    </td>
                    <template>
                      <td
                        v-for="(data, i) in mapDates[j]"
                        :key="i"
                        :style="[
                          Math.floor(((i + currentDayOfWeek - 1) % 7) / 5)
                            ? { background: '#f1edeecc' }
                            : { background: '#71cfdb33' },
                          dateFormatYYYYMMDD(daysofmonth.days[i]) === todaydate
                            ? {
                                'border-left': '2px solid #222 !important',
                                'border-right': '2px solid #222 !important',
                              }
                            : { 'border-right': '1px solid #ccc !important' },
                        ]"
                        style="
                          border-bottom: 1px solid #ccc !important;
                          text-align: center;
                          padding: 0px !important;
                        "
                        class="tooltip"
                      >
                        <div v-if="typeof data != 'number'">
                          <v-btn
                            :color="it.color + '8C'"
                            dark
                            small
                            tile
                            depressed
                            block
                            :ripple="false"
                            class="tooltip my-0"
                            @click="$emit('vehicle_id', it)"
                          >
                            <v-icon left color="white" class="mr-1">
                              mdi-car
                            </v-icon>
                            {{ data.vehicleContractorCode }}
                            <span class="tooltiptext2">
                              <div class="chip">
                                <span class="icon mx-auto">
                                  <i class="mdi mdi-car"></i>
                                </span>
                                <span>{{ data.vehicleContractorCode }}</span>
                              </div>
                              <br />
                              <div class="chip">
                                <span class="icon mx-auto">
                                  <i class="mdi mdi-clock-time-nine"></i>
                                </span>
                                <span>
                                  {{ data.hourInit }} -
                                  {{ data.hourFinal }}
                                </span>
                              </div>
                              <div class="chip">
                                {{ data.dateService }}
                              </div>
                            </span>
                          </v-btn>
                        </div>
                      </td>
                    </template>
                  </template>
                </tr>
                <!-- <tr v-if="(!mapDates.find(y=>(y.find(x=>(typeof x == 'object')))))"> -->
                <tr v-if="(!mapDates.flat().find(x=>(typeof x == 'object')))">
                  <td colspan="8"> 
                    <div> 
                      No hi ha itineraris
                    </div>
                  </td>
                </tr>
              </template>

              <!-- Monthly view -->
              <template v-else-if="viewdays > 7">
                <tr v-for="(it, j) in caldata.data" :key="it.itinerari_id">
                  <template
                    v-if="
                      (it.itinerary_id === focusonitinerari || focusonitinerari === 0) &&
                      (mapDates[j].find(x=>(typeof x == 'object')))
                    "
                  >
                    <td
                      class="nomsitineraris"
                      @click="selectItinerari(it.itinerary_id)"
                    >
                      <a>
                        {{ it.itinerary_contractor_code }}
                      </a>
                    </td>
                    <template>
                      <td
                        v-for="(data, i) in mapDates[j]"
                        :key="i"
                        :style="[
                          typeof data != 'number'
                            ? { background: it.color + '8C' }
                            : Math.floor(
                                (Math.abs(data) + currentDayOfWeek - 1) / 7
                              ) % 2
                            ? { background: '#71cfdb33' }
                            : { background: '#f1edeecc' },
                          dateFormatYYYYMMDD(daysofmonth.days[i]) === todaydate
                            ? {
                                'border-left': '2px solid #222 !important',
                                'border-right': '2px solid #222 !important',
                              }
                            : { 'border-right': '1px solid #ccc !important' },
                        ]"
                        style="
                          border-bottom: 1px solid #ccc !important;
                          text-align: center;
                          padding: 0 5px !important;
                          font-size: 13px;
                          font-weight: 700;
                        "
                        class="tooltip"
                      >
                        <div
                          v-if="typeof data === 'number'"
                          style="color: '#000000 !important'"
                        ></div>
                        <div
                          v-if="(typeof data != 'number')"
                          @click="$emit('vehicle_id', it)"
                        >
                          {{ data.onlyHourInit }}h
                          <span class="tooltiptext">
                            <div class="chip">
                              <span class="icon">
                                <i class="mdi mdi-car"></i>
                              </span>
                              <span>{{ data.vehicleContractorCode }}</span>
                            </div>
                            <br />
                            <div class="chip">
                              <span class="icon">
                                <i class="mdi mdi-clock-time-nine"></i>
                              </span>
                              <span
                                >{{ data.hourInit }} -
                                {{ data.hourFinal }}
                              </span>
                            </div>
                            <div class="chip">
                              {{ data.dateService }}
                            </div>
                          </span>
                        </div>
                      </td>
                    </template>
                  </template>
                </tr>
                <tr v-if="(!mapDates.flat().find(x=>(typeof x == 'object')))">
                  <td :colspan="mapDates[0].length+1"> 
                    <div> 
                      No hi ha itineraris
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <td :colspan="viewdays">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="blue lighten-1"
                  indeterminate
                ></v-progress-circular>
              </td>
            </tbody>
          </v-simple-table>
        </transition>
      </div>
    </v-card>
  </v-layout>
</template>

<script>
import axios from "axios";
import Toolbar from "@/components/calendar/Toolbar";

export default {
  name: "Calendar",
  components: {
    Toolbar,
  },
  props: {
    borderBodyColor: {
      type: String,
      default: "#fff",
    },
    primaryColor: {
      type: String,
      /*default: '#dcd2d399'*/
      default: "#71cfdb33",
    },
    secondaryColor: {
      type: String,
      default: "#f1edeecc",
    },
    primaryTextColor: {
      type: String,
      default: "#b6b6b6",
    },
    secondaryTextColor: {
      type: String,
      default: "#fff",
    },
    borderHeadColor: {
      type: String,
      default: "#b6b6b6",
    },
    borderTodayColor: {
      type: String,
      default: "#222",
    },
    locale: {
      type: String,
      default: "es",
    },
  },
  async mounted() {
    this.startindex =
      parseInt(this.$date(this.currentday).startOf("month").dayOfYear()) - 1;
    //this.startindex = this.currentDayOfYear;
    console.log("this.startindex");
    console.log(this.startindex);
    console.log("");
    await this.fetchItems();
  },
  data: () => ({
    focusonitinerari: 0,
    todaydate: null,
    initialday: null,
    currentday: null,
    viewdays: 31,
    startindex: 0,
    datalength: 7,
    transition: true,
    caldata: null,
    loading: false,
    caldataAux: null,
    itinerariesInCaldata: [],
  }),
  beforeCreate() {},
  async created() {
    console.log(
      "--------------------------------------------------------------------"
    );
    console.log(
      "--------------------------------------------------------------------"
    );
    console.log(
      "--------------------------------------------------------------------"
    );
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);

    //await this.fetchItems();

    this.todaydate = this.$date().format("YYYY-MM-DD");
    console.log("this.todaydate: " + this.todaydate);

    //Fecha del primer dia de este mes
    this.initialday = this.$date().startOf("month");
    console.log("this.initialday: " + this.initialday.format("YYYY-MM-DD"));

    //Fecha seleccionada, inicialmente dia de hoy
    this.currentday = this.$date().startOf("day");
    console.log("this.currentday: " + this.currentday.format("YYYY-MM-DD"));

    //Número de dias que tiene el mes
    this.viewdays = parseInt(this.$date().daysInMonth());
    console.log("this.viewdays:" + this.viewdays);
  },
  methods: {
    dateFormatYYYYMMDD(data) {
      return this.$date(data).format("YYYY-MM-DD");
    },
    generateRandomColorHex() {
      return (
        "#" +
        (
          "00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)
        ).slice(-6)
      );
    },
    async fetchItems(initialday = "", finalday = "", addTocaldataAux = false) {
      console.log("fetchItems");

      const url = "/api/v1/service/calendar";
      this.loading = true;
      try {
        const respuesta = await axios.get(
          url + "?initialday=" + initialday + "&finalday=" + finalday,
          this.getHeaders()
        );
        console.log("respuesta.data");
        console.log(respuesta.data);
        if (respuesta.data.status == "ok") { 
          respuesta.data.info.data.sort((a, b) => {
            const nameA = a.itinerary_contractor_code.toUpperCase(); // ignore upper and lowercase
            const nameB = b.itinerary_contractor_code.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            } 
            // names must be equal
            return 0;
          });
          if (addTocaldataAux) {
            this.caldataAux = respuesta.data.info;
            console.log("caldataAux");
            console.log(this.caldataAux);
            //Seguro qeu tengo que cambiar los colores
            for (let it = 0; it < this.caldataAux.data.length; it++) {
              this.caldataAux.data[it].color = this.generateRandomColorHex();
            }

            this.syncItinerarisFromAuxCaldata()
          } else {
            this.caldata = respuesta.data.info;
            
            // this.caldata.data[0].dates[0] = {
            //     "data": "2021-12-31T06:00:00/PT02H40M",
            //     // "data": "2021-12-31T00:00:00/PT02H40M",
            //     "vehicle_contractor_code": "E6679BGY",
            //     "tags": [
            //         "test"
            //     ]
            // }
            // this.caldata.data.push({ "itinerary_id": 3870, "itinerary_contractor_code": "CBC1D401AM", "dates": [ {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, { "data": "2021-12-29T06:00:00/PT6H40M", "vehicle_contractor_code": "E6679BGY", "tags": [ "test" ] }, { "data": "2021-12-30T06:00:00/PT6H40M", "vehicle_contractor_code": "E6679BGY", "tags": [ "test" ] }, { "data": "2021-12-31T06:00:00/PT21H40M", "vehicle_contractor_code": "E6679BGY", "tags": [ "test" ] } ] })
            // getItinerarisFromAux(respuesta.data.info,)

                  console.log("this.caldata");
            console.log(this.caldata);
            for (let it = 0; it < this.caldata.data.length; it++) {
              this.caldata.data[it].color = this.generateRandomColorHex();
            }
            
          }
          
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        console.log("ha habido un error");
      }
    },
    syncItinerarisFromAuxCaldata(){
      let itinerariCodesAux= {}
      // let itinerariCodesAux = this.caldataAux.data.map(itinerari=> itinerari.itinerary_contractor_code)
      this.caldataAux.data.map(itinerari=>itinerariCodesAux[itinerari.itinerary_contractor_code]= itinerari.itinerary_id)
      // ^^^ => { itinerary_contractor_code: "itinerary_id", ... }

      let totalDaysInYearAux = this.$date(this.caldataAux.initialday).endOf('year').dayOfYear() // 365
      
      // const length = this.caldata.data.length
      const itinerarisCodes = this.caldata.data.map(itinerari=> itinerari.itinerary_contractor_code);
       
      for (let i = 0; i < Object.keys(itinerariCodesAux).length; i++) { 
        let Iti = Object.keys(itinerariCodesAux)[i]
         
        if (!itinerarisCodes.includes(Iti)) {
          let tempIti = {
            dates:Array(totalDaysInYearAux).fill({}),
            itinerary_contractor_code: Iti,
            itinerary_id: itinerariCodesAux[Iti]  
          }   
          this.caldata.data.push(tempIti)
        } 
      }

      // this.caldata.data.forEach(itinerari=>{
      //   if (!itinerariCodesAux.include(itinerari.itinerary_contractor_code)) {
          
      //   }

      // })
      // const length = this.caldata.data.length
      // console.error(itinerariCodesAux);
      // for (let i = 0; i < length; i++) {
      //   const itinerari = this.caldata.data[i];
      //   // if (!itinerariCodesAux.includes(itinerari.itinerary_contractor_code)) {
      //     if (!Object.keys(itinerariCodesAux).includes(itinerari.itinerary_contractor_code)) {
      //     let tempIti = {
      //       dates:Array(totalDaysInYearAux).fill({}),
      //       itinerary_contractor_code:itinerari.itinerary_contractor_code,
      //       itinerary_id: itinerari.itinerary_id ,
      //       aCustom: true 
      //     }  
      //     console.error("si "+tempIti.itinerary_contractor_code);
      //     this.caldata.data.push(tempIti)
      //     }else{
      //       console.error("no "+itinerari.itinerary_contractor_code);

      //     }
      // }
 
    },
    fetchItemsBuena(initialday = "", finalday = "", addTocaldataAux = false) {
      console.log("fetchItems");
      //const url= "http://localhost:3001/calendar";
      const url = "/api/v1/service/calendar";
      this.loading = true;
      axios
        .get(
          url + "?initialday=" + initialday + "&finalday=" + finalday,
          this.getHeaders()
        )
        .then((response) => {
          if (response.data.status == "ok") {
            if (addTocaldataAux) {
              this.caldataAux = response.data.info;
              console.log("caldataAux");
              console.log(this.caldataAux);
              //Seguro qeu tengo que cambiar los colores
              for (let it = 0; it < this.caldataAux.data.length; it++) {
                this.caldataAux.data[it].color = this.generateRandomColorHex();
              }
            } else {
              this.caldata = response.data.info;
              console.log("this.caldata");
              console.log(this.caldata);
              for (let it = 0; it < this.caldata.data.length; it++) {
                this.caldata.data[it].color = this.generateRandomColorHex();
              }
            }

            this.loading = false;
          }
        });
    },
    async goTo(day) {
      console.log("this.currentday: "+this.$date(this.currentday).format("DD-MM-YYYY"))
      console.log("day goto: " + day.format("DD-MM-YYYY"));

      // Set current date to the day requested
      // Get diff between fristday with data available and current date
      if (this.viewdays > 7) {
        this.viewdays = parseInt(this.$date(day).daysInMonth());
      }
      this.currentday = this.$date(day).startOf("day");
      console.log("this.currentday: " + this.currentday.format("DD-MM-YYYY"));

      let firstdaywithdata = this.$date(this.caldata.initialday, "YYYY-MM-DD");
      console.log("firstdaywithdata: " + firstdaywithdata.format("DD-MM-YYYY"));

      let difftoday = -1 * firstdaywithdata.diff(day, "day");
      console.log("difftoday: " + difftoday);
      console.log();

      let diff = 0;
      if (this.viewdays > 7) {
        //Vista mensual
        let dayStartOfMonth = this.$date(day).startOf("month");
        this.currentday = dayStartOfMonth;
        diff = -1 * firstdaywithdata.diff(dayStartOfMonth, "day");
      } else if (this.viewdays === 7) {
        //Vista semanal
        let dayStartOfWeek = this.$date(day).startOf("week");
        this.currentday = dayStartOfWeek;
        diff = -1 * firstdaywithdata.diff(dayStartOfWeek, "day");
      } else {
        //vista dia
        diff = difftoday;
      }

      console.log("this.caldata.initialday" + this.caldata.initialday);
      console.log("this.caldata.finalday" + this.caldata.finalday);
      /*let isprior = this.$date(this.caldata.initialday, "YYYY-MM-DD").isAfter(
        day.add(1, "days")
      );*/
      let isprior = this.$date(this.caldata.initialday, "YYYY-MM-DD").isAfter(
        day
      );

      console.log(
        "isprior: el dia que solicito es anterior al primer dia de mi consulta?: " +
          isprior
      );

      /*let isafter = this.$date(this.caldata.finalday, "YYYY-MM-DD").isBefore(
        day.add(1, "days")
      );*/
      let isafter = this.$date(this.caldata.finalday, "YYYY-MM-DD").isBefore(
        day
      );

      console.log(
        "isafter el dia que solicito es posterior al primer dia de mi consulta?:" +
          isafter
      );

      console.log("viewdays:" + this.viewdays);
      console.log(
        "this.caldata.finalday:" +
          this.$date(this.caldata.finalday).format("DD-MM-YYYY")
      );
      console.log(
        "is before dayadd:" +
          this.$date(day).add(this.viewdays, "days").format("DD-MM-YYYY")
      );

      

      let isuncompletedPosterior = false;
      let isuncompletedPrevious = false;

      if (this.viewdays === 7) {
        console.log("Day add 7:" + day.add(this.viewdays, "days").format("DD-MM-YYYY"))
        isuncompletedPosterior = this.$date(
          this.caldata.finalday,
          "YYYY-MM-DD"
        ).isBefore(day.add(this.viewdays-1, "days"));

        //Si el initial day, el primer dia del año, no cae en lunes, la semana es incompleta, habrá que añadir dias anteriores al año anterior
        isuncompletedPrevious = this.$date(
          this.caldata.initialday,
          "YYYY-MM-DD"
        ).day() != 1;
      }

      console.log("isuncompletedPrevious")
      console.log(isuncompletedPrevious)
      console.log("isuncompletedPosterior:" + isuncompletedPosterior);

      let firstDayOfYear = this.$date(day).startOf("year");
      console.log(
        "firstDayOfYear: " + this.$date(firstDayOfYear).format("DD-MM-YYYY")
      );
      let lastDayOfYear = this.$date(day).endOf("year");
      console.log(
        "lastDayOfYear: " + this.$date(lastDayOfYear).format("DD-MM-YYYY")
      );

      if (isprior) {
        console.log("cond-isprior");
        //el dia que solicito es anterior al primer dia de mi consulta
        await this.fetchItems(
          firstDayOfYear.format("YYYY-MM-DD"),
          lastDayOfYear.format("YYYY-MM-DD")
        );
        diff = -1 * firstDayOfYear.diff(day, "day");
        this.startindex = diff;

        //Rellenar otro calendario, caldataAux
        console.log("rellenar caladataposterior");
        let firstDayOfPosteriorYear = day
          .add(this.viewdays, "days")
          .startOf("year")
          .format("YYYY-MM-DD");
        let lastDayOfPosteriorYear = day
          .add(this.viewdays, "days")
          .endOf("year")
          .format("YYYY-MM-DD");
        await this.fetchItems(
          firstDayOfPosteriorYear,
          lastDayOfPosteriorYear,
          true
        );

      } else if (isafter) {
        console.log("cond-isafter");
        //el dia que solicito es posterior al primer dia de mi consulta
        await this.fetchItems(
          firstDayOfYear.format("YYYY-MM-DD"),
          lastDayOfYear.format("YYYY-MM-DD")
        );
        

        diff = -1 * firstDayOfYear.diff(day, "day");
        this.startindex = diff;

        let firstDayOfPreviousYear = day
          .subtract(this.viewdays, "days")
          .startOf("year")
          .format("YYYY-MM-DD");
        let lastDayOfPreviousYear = day
          .subtract(this.viewdays, "days")
          .endOf("year")
          .format("YYYY-MM-DD");
        await this.fetchItems(
          firstDayOfPreviousYear,
          lastDayOfPreviousYear,
          true
        );
      } else if (isuncompletedPosterior) {
        console.log("cond-isuncompletedPosterior");
        await this.fetchItems(
          firstDayOfYear.format("YYYY-MM-DD"),
          lastDayOfYear.format("YYYY-MM-DD")
        );
        //diff = -1 * firstDayOfYear.diff(day, "day");
        //this.startindex = diff;
        this.startindex = this.$date(day).startOf("week").dayOfYear()-1;

        //Rellenar otro calendario, caldataAux
        console.log("rellenar caladataAux");
        let firstDayOfPosteriorYear = day
          .add(this.viewdays, "days")
          .startOf("year")
          .format("YYYY-MM-DD");
        let lastDayOfPosteriorYear = day
          .add(this.viewdays, "days")
          .endOf("year")
          .format("YYYY-MM-DD");
        await this.fetchItems(
          firstDayOfPosteriorYear,
          lastDayOfPosteriorYear,
          true
        );
      } else if (isuncompletedPrevious) {
        console.log("cond-isuncompletedPrevious");
        await this.fetchItems(
          firstDayOfYear.format("YYYY-MM-DD"),
          lastDayOfYear.format("YYYY-MM-DD"),true
        );
        
        this.startindex = this.$date(day).startOf("week").dayOfYear()-1;

        //Rellenar otro calendario, caldataAux
        console.log("rellenar caladataAux");
        let firstDayOfPreviousYear = day
          .subtract(this.viewdays, "days")
          .startOf("year")
          .format("YYYY-MM-DD");
        let lastDayOfPreviousYear = day
          .subtract(this.viewdays, "days")
          .endOf("year")
          .format("YYYY-MM-DD");
        await this.fetchItems(
          firstDayOfPreviousYear,
          lastDayOfPreviousYear,
          
        );
      }
      else {
        //Dentro del mismo año
        if (diff == 0) {
          //Estoy en el dia 1 del año, para calcular el modo diario necesito acceder al dia anterior
          //tengo que hacer un fetchitems del año anterior

          let firstDayOfPreviousYear = this.$date(day)
            .subtract(1, "day")
            .startOf("year");
          console.log(
            "firstDayOfPreviousYear: " +
              this.$date(firstDayOfPreviousYear).format("DD-MM-YYYY")
          );
          let lastDayOfPreviousYear = this.$date(day)
            .subtract(1, "day")
            .endOf("year");
          console.log(
            "lastDayOfPreviousYear: " +
              this.$date(lastDayOfPreviousYear).format("DD-MM-YYYY")
          );

          await this.fetchItems(
            firstDayOfPreviousYear.format("YYYY-MM-DD"),
            lastDayOfPreviousYear.format("YYYY-MM-DD"),
            true
          );
        }
        this.startindex = diff;
      }

      /*if (isprior || isafter || isuncompleted) {
        this.fetchItems(
          day.add(-2 * this.viewdays, "days").format("YYYY-MM-DD"),
          day.add(2 * this.viewdays, "days").format("YYYY-MM-DD")
        );
        this.startindex = difftoday;
      } else {
        this.startindex = diff;
      }*/
    },
    goToHour(h) {
      //Unused function to move from hours on Daily View, we have to change dailyMapedDates to make it work
      let diff = 0;

      if (this.currentHour > h) {
        this.startindex += 1;
        if (h === 0) {
          diff = 24 - this.currentHour;
        } else {
          diff = 24 - this.currentHour + h;
        }
      } else {
        diff = h - this.currentHour;
      }
      this.currentday = this.$date(this.currentday).add(diff, "hours");
    },
    selectItinerari(focus) {
      if (this.focusonitinerari === focus) {
        this.focusonitinerari = 0;
      } else {
        this.focusonitinerari = focus;
      }
    },
    changeView(type) {
      //Handle changes of type of view from Toolbar buttons
      //Options: 1,7 or 31 -- DAILY, WEEKLY, MONTHLY
      this.viewdays = type;
      this.goTo(this.$date(this.currentday));
    },
  },
  watch: {},
  computed: {
    daysofweek() {
      let days = [];
      let index = [];
      if (this.viewdays === 7) {
        let dayStartOfWeek = this.$date(this.currentday).startOf("week");

        index = [...Array(7).keys()];
        days = index.map((day, i) => {
          return dayStartOfWeek.add(i, "days").format("dd");
        });
      } else {
        index = Array.apply(null, Array(this.viewdays)).map(
          (d, i) => (this.startOfCurrentMonthDayOfWeek + i) % 7
        );

        let that = this;
        days = index.map(function (_) {
          return that.$date().startOf("week").isoWeekday(_).format("dd");
        });
      }

      return days;
    },
    daysofweekOld() {
      let index = Array.apply(null, Array(this.viewdays)).map(
        (d, i) => (this.currentDayOfWeek + i) % 7
      );
      console.log("Index daysofweek:" + index);
      let that = this;
      /*let days = index.map(function (_) {
        return that
          .$date()
          .startOf("week")
          .isoWeekday(_ + 1)
          .format("dd");
      });*/
      let days = index.map(function (_) {
        return that.$date().startOf("week").isoWeekday(_).format("dd");
      });
      return days;
    },
    daysofmonth() {
      console.log("***********");
      console.log("daysofmonth");
      console.log("***********");
      console.log(this.viewdays);
      console.log(
        "currentDay:" + this.$date(this.currentday).format("DD-MM-YYYY")
      );
      const b = [...Array(this.viewdays).keys()];
      let days = {};
      if (this.viewdays === 7) {
        //Vista semanal

        let dayStartOfWeek = this.$date(this.currentday)
          .startOf("week")
          .format("D");
        let dayEndOfMonth = this.$date(this.currentday)
          .startOf("week")
          .daysInMonth();

        console.log("dayEndOfMonth:" + dayEndOfMonth);
        console.log("dayStartOfWeek:" + dayStartOfWeek);

        days = {
          day: b.map((d, i) => {
            if (parseInt(dayStartOfWeek) + i > dayEndOfMonth) {
              return parseInt(dayStartOfWeek) + i - dayEndOfMonth;
            } else {
              return parseInt(dayStartOfWeek) + i;
            }
          }),
          days: b.map((d, i) =>
            this.$date(this.currentday).startOf("week").add(i, "days")
          ),
        };
      } else {
        //Vista mensual
        let currentmonthdays =
          parseInt(this.$date(this.currentday).daysInMonth()) + 1;
        console.log("currentmonthdays:" + currentmonthdays);
        days = {
          day: b.map(
            (d, i) => 1 + i
            //((1 + i) % currentmonthdays) +
            //Math.floor((1 + i) / currentmonthdays)
          ),
          days: b.map((d, i) =>
            this.$date(this.currentday).startOf("month").add(i, "days")
          ),
        };
      }

      console.log("daysofmonth");
      console.log(days);
      return days;
    },
    daysofmonthOld() {
      const b = [...Array(this.viewdays).keys()];
      let currentmonthdays =
        parseInt(this.$date(this.currentday).daysInMonth()) + 1;
      let days = {
        day: b.map(
          (d, i) =>
            ((this.currentDayOfMonth + i) % currentmonthdays) +
            Math.floor((this.currentDayOfMonth + i) / currentmonthdays)
        ),
        days: b.map((d, i) => this.$date(this.currentday).add(i, "days")),
      };
      console.log("daysofmonth");
      console.log(days);
      return days;
    },
    currentHour() {
      return parseInt(this.$date(this.currentday).format("H"));
    },
    currentDayOfWeek() {
      return parseInt(this.$date(this.currentday).format("d"));
    },
    startOfCurrentMonthDayOfWeek() {
      return parseInt(this.$date(this.currentday).startOf("month").format("d"));
    },
    currentDayOfMonth() {
      console.log(
        "currentDayOfMonth:" + parseInt(this.$date(this.currentday).format("D"))
      );
      return parseInt(this.$date(this.currentday).format("D"));
    },
    currentDayOfYear() {
      return parseInt(this.$date(this.currentday).dayOfYear()) - 1;
    },
    currentDaysOfMonth() {
      return parseInt(this.$date(this.currentday).daysInMonth());
    },

    //mapDates revisada
    //La que funciona siempre y cuando no cambiamos de año
    mapDatesBuena() {
      return this.caldata.data.map((it) => {
        let itDatesCopy = JSON.parse(JSON.stringify(it.dates));
        return itDatesCopy
          .slice(this.startindex, this.viewdays + this.startindex)
          .map((day, i) => {
            //if (day !== 0) {

            if (Object.keys(day).length != 0) {
              return {
                hourInit: this.$date(
                  day.data.substr(0, day.data.indexOf("/"))
                ).format("HH:mm"),
                hourFinal: this.$date(day.data.substr(0, day.data.indexOf("/")))
                  .add(
                    this.$date.duration(
                      day.data.substr(1 + day.data.indexOf("/"))
                    )
                  )
                  .format("HH:mm"),
                onlyHourInit: this.$date(
                  day.data.substr(0, day.data.indexOf("/"))
                ).format("HH"),
                durationInHours: this.$date
                  .duration(day.data.substr(1 + day.data.indexOf("/")))
                  .hours(),
                vehicleContractorCode: day.vehicle_contractor_code,
                color: it.color,
                itineraryContractorCode: it.itinerary_contractor_code,
                itineraryId: it.itinerary_id,
                dateService: this.$date(
                  day.data.substr(0, day.data.indexOf("/"))
                ).format("DD-MM-YYYY"),
              };
            } else return -i;
          });
      });
    },
    mapDates() {
      console.log("mapDates");
      /*console.log("mapDates");
      console.log("this.startindex:" + this.startindex);
      console.log("this.viewdays:" + this.viewdays);
      console.log(
        "this.viewdays + this.startindex: " + this.viewdays + this.startindex
      );

      console.log(
        this.caldata.data.map((it) => {
          let itDatesCopy = JSON.parse(JSON.stringify(it.dates));
          return itDatesCopy
            .slice(this.startindex, this.viewdays + this.startindex)
            .map((day, i) => {
              //if (day !== 0) {

              if (Object.keys(day).length != 0) {
                return {
                  hourInit: this.$date(
                    day.data.substr(0, day.data.indexOf("/"))
                  ).format("HH:mm"),
                  hourFinal: this.$date(
                    day.data.substr(0, day.data.indexOf("/"))
                  )
                    .add(
                      this.$date.duration(
                        day.data.substr(1 + day.data.indexOf("/"))
                      )
                    )
                    .format("HH:mm"),
                  onlyHourInit: this.$date(
                    day.data.substr(0, day.data.indexOf("/"))
                  ).format("HH"),
                  durationInHours: this.$date
                    .duration(day.data.substr(1 + day.data.indexOf("/")))
                    .hours(),
                  vehicleContractorCode: day.vehicle_contractor_code,
                  color: it.color,
                  itineraryContractorCode: it.itinerary_contractor_code,
                  itineraryId: it.itinerary_id,
                  dateService: this.$date(
                    day.data.substr(0, day.data.indexOf("/"))
                  ).format("DD-MM-YYYY"),
                };
              } else return -i;
            });
        })
      );*/

      //let that = this;
      let daysOnCurrentYear = parseInt(
        this.currentday.endOf("year").dayOfYear()
      );

      let aa = this.caldata.data.map((it) => {
        console.log(
          "last dayof year" +
            parseInt(this.currentday.endOf("year").dayOfYear())
        );

        let itDatesCopyCutToViewDays = null;
        let itDatesCopy = JSON.parse(JSON.stringify(it.dates));
        console.log("this.startindex:" + this.startindex);
        console.log("daysOnCurrentYear:" + daysOnCurrentYear);
        if (this.viewdays === 7) {
          if (this.startindex + this.viewdays > daysOnCurrentYear) {
            itDatesCopyCutToViewDays = itDatesCopy.slice(
              this.startindex,
              daysOnCurrentYear
            );

            console.log("itDatesCopyCutToViewDays");
            console.log(itDatesCopyCutToViewDays);
            let numDaysFilled = itDatesCopyCutToViewDays.length;
            console.log("numDaysFilled:" + numDaysFilled);
            let numDaysNotFilled = this.viewdays - numDaysFilled;
            //console.log("numDaysNotFilled:"+numDaysNotFilled);
            //console.log("this.caldata");
            //console.log(this.caldata);

            //console.log("this.caldataAux");
            //console.log(this.caldataAux);

            for (let j = 0; j < numDaysNotFilled; j++) {
              console.log("iterando:" + j);
              let itinerary = this.caldataAux.data.find(
                (itinerary) => itinerary.itinerary_id == it.itinerary_id
              );
              if (itinerary == undefined) {
                itDatesCopyCutToViewDays.push({});
                console.log("undefined");
              } else {
                itDatesCopyCutToViewDays.push(
                  JSON.parse(JSON.stringify(itinerary.dates[j]))
                );
              }
            }

            console.log("itDatesCopyCutToViewDays2");
            console.log(itDatesCopyCutToViewDays);
          } else {
            itDatesCopyCutToViewDays = itDatesCopy.slice(
              this.startindex,
              this.viewdays + this.startindex
            );
          }
        } else {
          itDatesCopyCutToViewDays = itDatesCopy.slice(
            this.startindex,
            this.viewdays + this.startindex
          );
        }

        return itDatesCopyCutToViewDays.map((day, i) => {
          //if (day !== 0) {
          //console.log("Object.keys(day).length: "+ Object.keys(day).length)
          if (Object.keys(day).length != 0) {
            return {
              hourInit: this.$date(
                day.data.substr(0, day.data.indexOf("/"))
              ).format("HH:mm"),
              hourFinal: this.$date(day.data.substr(0, day.data.indexOf("/")))
                .add(
                  this.$date.duration(
                    day.data.substr(1 + day.data.indexOf("/"))
                  )
                )
                .format("HH:mm"),
              onlyHourInit: this.$date(
                day.data.substr(0, day.data.indexOf("/"))
              ).format("HH"),
              durationInHours: this.$date
                .duration(day.data.substr(1 + day.data.indexOf("/")))
                .hours(),
              vehicleContractorCode: day.vehicle_contractor_code,
              color: it.color,
              itineraryContractorCode: it.itinerary_contractor_code,
              itineraryId: it.itinerary_id,
              dateService: this.$date(
                day.data.substr(0, day.data.indexOf("/"))
              ).format("DD-MM-YYYY"),
            };
          } else return -i;
        });
      });

      //En el modo semanal ,en el caso de que haya dia no rellenados del siguiente año,
      //mirar si hay nuevos itinerarios ejecutandose que no sean los del año pasado, para asi agregarlos a itinerarios de la semana
      /*if (this.viewdays === 7) {
            if (this.startindex + this.viewdays > daysOnCurrentYear) {
                var itinerariesNotFilledNextYear= this.caldataAux.data.map ((it)=>{
                    let itinerary=that.caldata.data.find((itinerary) => itinerary.itinerary_id == it.itinerary_id)
                    if (itinerary == undefined){
                        itinerariesInCaldata.push(itinerary); 
                        let itDatesCopy = JSON.parse(JSON.stringify(it.dates));


                    }
                    
                })    
            } 
        }  */

      console.log("mapdates result");
      console.log(aa);
      return aa;
    },
    dailyMapedDatesReduced() {
      let dailyMapedDatesReduced = [];
      if (this.viewdays != 1) return;
      let dailyMapedDates = JSON.parse(JSON.stringify(this.dailyMapedDates));

      for (let it = 0; it < dailyMapedDates.length; it++) {
        if (typeof dailyMapedDates[it] != "number") {
          dailyMapedDatesReduced.push([]);
        } else {
          dailyMapedDatesReduced.push(0);
          continue;
        }
        for (let h = 0; h < 24; h++) {
          if (typeof dailyMapedDates[it][h] === "object") {
            let colspan = dailyMapedDates[it][h].colspan;
            if (colspan != 0) {
              dailyMapedDatesReduced[it].push(dailyMapedDates[it][h]);
              h = h + colspan - 1;
            }
          } else {
            dailyMapedDatesReduced[it].push(dailyMapedDates[it][h]);
          }
        }
      }

      return dailyMapedDatesReduced;
    },
    dailyMapedDates() {
      let daily = [];
      if (this.viewdays != 1) return;

      console.log("dailyMapedDates");
      console.log("this.startindex: " + this.startindex);
      console.log("this.viewdays:", this.viewdays);

      let lastDayOfYear = parseInt(
        this.$date(this.currentday).endOf("year").dayOfYear()
      );
      console.log("lastDayOfYear:" + lastDayOfYear);

      let threedays;
      //Si estamos a 1 de enero, primer dia el año, se ha de coger el dia anterior,
      //que es el ultimo dia del año anterior, por tanto hay que coger datos del caldataAux del ultimo dia
      if (this.startindex == 0) {
        let oneDayOfLastYear = this.caldataAux.data.map((it) => {
          let itdatesCopy = JSON.parse(JSON.stringify(it.dates));
          let itDatesCutted = itdatesCopy.slice(
            it.dates.length - 1,
            it.dates.length
          );
          let itinerary = {
            itinerary_id: it.itinerary_id,
            dates: itDatesCutted,
          };
          return itinerary;
        });

        console.log("oneDayOfLastYear");
        console.log(oneDayOfLastYear);

        let twoDaysOfThisYear = this.caldata.data.map((it) => {
          let itdatesCopy = JSON.parse(JSON.stringify(it.dates));
          let itDatesCutted = itdatesCopy.slice(
            this.startindex,2
          );
          let itinerary = {
            itinerary_id: it.itinerary_id,
            dates: itDatesCutted,
          };

          return itinerary;
        });
        console.log("twoDaysOfThisYear");
        console.log(twoDaysOfThisYear);
        
       
        for (let i = 0; i < oneDayOfLastYear.length; i++) {
          let itinerary = twoDaysOfThisYear.find(
            (itinerary) =>
              itinerary.itinerary_id == oneDayOfLastYear[i].itinerary_id)
          ;
          console.log("itinerary");
          console.log(itinerary);
          if (itinerary == undefined) {
            twoDaysOfThisYear.push(oneDayOfLastYear[i]);
            twoDaysOfThisYear[twoDaysOfThisYear.length-1].dates.push({},{});
          } else {
            itinerary.dates.unshift(oneDayOfLastYear[i].dates[0]);
          }
        }
        threedays = [];

        for (let j = 0; j < twoDaysOfThisYear.length; j++) {
          if (twoDaysOfThisYear[j].dates.length == 2) {
            twoDaysOfThisYear[j].dates.unshift({});
          }
          threedays.push(twoDaysOfThisYear[j].dates);
        }

        console.log("twoDaysOfThisYear2");
        console.log(twoDaysOfThisYear);
      } else {
        threedays = this.caldata.data.map((it) =>
          it.dates.slice(
            this.startindex - 1,
            this.viewdays + this.startindex + 1
          )
        );
      }

      console.log("threedays");
      console.log(threedays);
      for (let it = 0; it < this.caldata.data.length; it++) {
        let prevday = threedays[it][0];

        let day = threedays[it][1];

        let span = 0;
        let arr = [];
        let hprevday = 0;
        if (Object.keys(prevday).length != 0) {
          let hora = parseInt(
            this.$date(
              prevday.data.substr(0, prevday.data.indexOf("/"))
            ).format("HH")
          );

          let minutesFinal = this.$date(
            prevday.data.substr(0, prevday.data.indexOf("/"))
          )
            .add(
              this.$date.duration(
                prevday.data.substr(1 + prevday.data.indexOf("/"))
              )
            )
            .format("m");

          let duration = parseInt(
            this.$date
              .duration(prevday.data.substr(1 + prevday.data.indexOf("/")))
              .get("hours")
          );
          let colspan = (hora + duration) % 24;
          if (minutesFinal > 0) {
            colspan = colspan + 1;
          }

          if (hprevday <= (hora + duration) % 24 && hora + duration > 23) {
            span = (hora + duration) % 24;
            arr.push({
              hourInit: this.$date(
                prevday.data.substr(0, prevday.data.indexOf("/"))
              ).format("HH:mm"),
              hourFinal: this.$date(
                prevday.data.substr(0, prevday.data.indexOf("/"))
              )
                .add(
                  this.$date.duration(
                    prevday.data.substr(1 + prevday.data.indexOf("/"))
                  )
                )
                .format("HH:mm"),
              onlyHourInit: this.$date(
                prevday.data.substr(0, prevday.data.indexOf("/"))
              ).format("HH"),
              durationInHours: this.$date
                .duration(prevday.data.substr(1 + prevday.data.indexOf("/")))
                .hours(),
              colspan: colspan,
              itineraryId: this.caldata.data[it].itinerary_id,
              itineraryContractorCode:
                this.caldata.data[it].itinerary_contractor_code,
              vehicleContractorCode: prevday.vehicle_contractor_code,
              color: this.caldata.data[it].color,
              prevDay: true,
              nextDay: false,
              dateService: this.$date(
                prevday.data.substr(0, prevday.data.indexOf("/"))
              ).format("DD-MM-YYYY"),
            });

            hprevday = 1;
          }
        }
        for (let h = hprevday; h < 24; h++) {
          if (Object.keys(day).length != 0) {
            if (
              h ===
              parseInt(
                this.$date(day.data.substr(0, day.data.indexOf("/"))).format(
                  "HH"
                )
              )
            ) {
              let hourInit = this.$date(
                day.data.substr(0, day.data.indexOf("/"))
              ).format("HH");

              let minutesFinal = this.$date(
                day.data.substr(0, day.data.indexOf("/"))
              )
                .add(
                  this.$date.duration(
                    day.data.substr(1 + day.data.indexOf("/"))
                  )
                )
                .format("m");
              let duration = parseInt(
                this.$date
                  .duration(day.data.substr(1 + day.data.indexOf("/")))
                  .get("hours")
              );

              let nextDay = false;
              let colspan = duration;
              if (minutesFinal > 0) {
                colspan = colspan + 1;
              }

              //Itinerary on the next day too
              if (parseInt(hourInit) + parseInt(duration) > 24) {
                colspan = 24 - parseInt(hourInit);
                nextDay = true;
              }

              arr.push({
                hourInit: this.$date(
                  day.data.substr(0, day.data.indexOf("/"))
                ).format("HH:mm"),
                hourFinal: this.$date(day.data.substr(0, day.data.indexOf("/")))
                  .add(
                    this.$date.duration(
                      day.data.substr(1 + day.data.indexOf("/"))
                    )
                  )
                  .format("HH:mm"),
                onlyHourInit: this.$date(
                  day.data.substr(0, day.data.indexOf("/"))
                ).format("HH"),
                durationInHours: this.$date
                  .duration(day.data.substr(1 + day.data.indexOf("/")))
                  .hours(),
                colspan: colspan,
                itineraryId: this.caldata.data[it].itinerary_id,
                itineraryContractorCode:
                  this.caldata.data[it].itinerary_contractor_code,
                vehicleContractorCode: day.vehicle_contractor_code,
                color: this.caldata.data[it].color,
                prevDay: false,
                nextDay: nextDay,
                dateService: this.$date(
                  day.data.substr(0, day.data.indexOf("/"))
                ).format("DD-MM-YYYY"),
              });
            } else {
              arr.push(0);
            }
          }
        }
        if (arr.length) {
          if (arr.length < 24) {
            let a = Array(23).fill(span);
            a.unshift(arr[0]);
            daily.push(a);
          } else {
            console.log("igual a 24");
            let totalduration = arr
              .filter((i) => typeof i === "object")
              .reduce(
                (total, item) =>
                  parseInt(item.onlyHourInit) + item.colspan > 24
                    ? total +
                      ((parseInt(item.onlyHourInit) + item.colspan) % 24)
                    : total + item.colspan,
                0
              );
            daily.push(
              arr.map((i) => (typeof i !== "object" ? totalduration : i))
            );
          }
        } else {
          daily.push(0);
        }
      }
      console.log("daily");
      console.log(daily);
      return daily;
    },
  },
  inject: ["getHeaders"],
};
</script>
<style scoped>
.v-data-table__wrapper {
  overflow-x: hidden !important ;
}
th {
  font-weight: 600;
  vertical-align: bottom;
  line-height: 1.49;
  vertical-align: middle;
  border-top: 1px solid #ccc;
  border-bottom: none;
}
td {
  min-width: 33px !important;
  height: 2.1em !important;
  text-align: center;
  padding: 0px 0.2vh !important;
  font-size: 0.8rem !important;
}

td.nomsitineraris {
  width: 13vh !important;
  padding: 2px 1vh !important;
}
td.nomsitineraris a {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  font-weight: 500;
}

td.higherheight {
  height: 2em !important;
  padding: 0px !important;
  vertical-align: top;
}
td.nopadding {
  padding: 0px !important;
}
.today {
  border-left: 2px solid #222 !important;
  border-right: 2px solid #222 !important;
  text-align: center;
}
.today a {
  color: black;
}
.calDies {
  text-align: center;
  /*color: #b6b6b6;*/
  color: rgba(0, 0, 0, 0.6);

  padding: 5px;
}
.mapfade-enter-active {
  transition: opacity 0.5s;
}
.mapfade-leave-active {
  transition: opacity 0.5s;
}
.mapfade-leave-to {
  opacity: 0;
}
.mapfade-enter {
  opacity: 0;
}
.max-width-chip.v-chip {
  margin: 0px !important;
  margin-left: 4px;
  padding-left: 40px !important;
  padding-right: 40px !important;
  width: 150px;
  border-radius: 0 !important;
}
.max-width-chip .v-chip__content {
  line-height: 32px;
  padding-right: 30px !important;
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  width: 135px;

  text-overflow: ellipsis;
  position: relative;
}
.tooltip {
  position: relative;
  //color: #fff;
  //color: #000;
  cursor: pointer;
  z-index: 30;
}
/* Tooltip text */
.tooltip .tooltiptextdaily {
  position: absolute;
  visibility: hidden;
  background-color: rgba(97, 97, 97, 0.9);
  color: #fff;
  right: 0;
  bottom: 100%;
  padding: 5px 6px 5px 6px;
  z-index: 30;
  font-weight: 700;
}
.tooltip .tooltiptext {
  position: absolute;
  visibility: hidden;
  background-color: rgba(97, 97, 97, 0.9);
  color: #fff;
  min-width: 135px;
  bottom: 100%;

  padding: 5px 6px 5px 6px;
  z-index: 30;
  font-weight: 700;
}
.tooltip .tooltiptext2 {
  position: absolute;
  visibility: hidden;
  background-color: rgba(97, 97, 97, 0.9);
  color: #fff;
  width: 150px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  padding: 5px 6px 5px 6px;
  z-index: 30;
  font-weight: 700;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:hover .tooltiptext2 {
  visibility: visible;
}
.tooltip:hover .tooltiptextdaily {
  visibility: visible;
}
.chip {
  padding: 0 12px 0 12px;
  background-color: #e0e0e0;
  border-radius: 5px;
  display: inline-flex;
  margin: 2px;
  color: rgba(97, 97, 97, 0.9);
  align-items: center;
  justify-content: center;
  height: 26px;
  font-size: 12px;
  cursor: pointer;
  min-width: 130px;
}

.chip .icon {
  font-size: 2.3vh;
  color: rgba(97, 97, 97, 0.9);
  margin: -4px 4px;
}
</style>
