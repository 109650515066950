<template>
  <vl-layer-vector ref="vectorLayer" :id="name" :visible="visible" :z-index="zIndex">
    <vl-source-vector
      ref="vectorSource"
      :features.sync="features"
      :strategy-factory="loadingStrategyFactory"
    ></vl-source-vector>
    <vl-style-func :factory="styleFunction" />
  </vl-layer-vector>
</template>

<script>
//import axios from 'axios'
//import jsonReport from "@/config/test/last_locations.json";
import Vue from "vue";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader
import axios from "axios";
import { loadingAll } from "vuelayers/lib/ol-ext";
import {
  Stroke,
  Style,
  Icon,
  Text,
  Fill,
  Circle as CircleStyle,
} from "ol/style.js";
import Point from "ol/geom/Point";
import MultiPoint from "ol/geom/MultiPoint"; // Text,/ Text,
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";

Vue.use(VueLayers);

export default {
  components: {},
  props: {
    registration_id: {
      type: String,
      default: "",
    },
    vehicle_id: {
      type: Number,
      default: 0,
    },
    service_id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      default: "es",
    },
  },

  data: () => ({
    features: [],
    zIndex: 300
  }),
  created() {
    require("dayjs/locale/" + this.locale + ".js");
    this.$date.locale(this.locale);
  },
  async mounted() {
    let context = this;

    await context.loadFeatures();
    let refresh_frequency = 10000;
    const timer = setInterval(() => {
      context.loadFeatures();
    }, refresh_frequency);

    this.$once("hook:beforeDestroy", () => {
      clearInterval(timer);
    });
  },
  methods: {
    getDateFormat: function (date, format) {
      let returnDate = "";

      if (date != null) {
          returnDate = this.$date(date).format(format);
      }

      return returnDate;
    },
    loadingStrategyFactory: function () {
      return loadingAll;
    },
    styleFunction: function () {
      const context = this;
      return function (feature) {
        const styles = [];
        const geometry = feature.getGeometry();
        const feature_type = feature.getProperties().type;
        let style, styleBox, styleRegistration, styleTime, segment_opacity;
        switch (feature_type) {
          case "pointNowRight":
            style = new Style({
              image: new Icon({
                anchor: [0.5, 0.5],
                src: require("@/assets/icons_ubicacions/truck_right.png"),
                color:"#000000",
                scale: 0.6,
                //color:'#000000'
                /*offsetOrigin: "bottom-right",
                offset: [50,0]*/
              }),
              zIndex: 5,
            });
            styles.push(style);

            styleBox = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/gps.png"),
                anchor: [0.5, 0.48],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: 3,
            });
            styles.push(styleBox);

            styleRegistration = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: feature.getProperties().registration,
                offsetY: -52,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleRegistration);

            styleTime = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: context.getDateFormat(
                  feature.getProperties().timestamp,
                  "HH:mm:ss"
                ),
                offsetY: -37,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleTime);

            break;
          case "pointNowLeft":
            style = new Style({
              image: new Icon({
                anchor: [0.5, 0.5],
                src: require("@/assets/icons_ubicacions/truck_left.png"),
                color:'#000000',
                scale: 0.6,
              }),
              zIndex: 5,
            });
            styles.push(style);

            styleBox = new Style({
              image: new Icon({
                src: require("@/assets/icons_ubicacions/gps.png"),
                //anchor: [0.75, 0.5],
                anchor: [0.5, 0.48],
                rotateWithView: true,
                scale: 0.45,
              }),
              zIndex: 3,
            });
            styles.push(styleBox);

            styleRegistration = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: feature.getProperties().registration,
                offsetY: -52,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleRegistration);

            styleTime = new Style({
              text: new Text({
                //text: feature.getProperties().time_from,
                text: context.getDateFormat(
                  feature.getProperties().timestamp,
                  "HH:mm:ss"
                ),
                offsetY: -37,
                font: "bold 11px sans-serif",
              }),
              zIndex: 4,
            });
            styles.push(styleTime);

            break;
          case "pointLast":
            style = new Style({
              image: new CircleStyle({
                radius: 0,
                fill: new Fill({ color: "rgba('0,0,0,0')" }),
                /*stroke: new Stroke({
                  color: "rgba('0,0,0,0')",
                  width: 10,
                }),*/
              }),
              zIndex: 2,
            });
            styles.push(style);
            break;

          case "pointsMiddle":
            style = new Style({
              image: new CircleStyle({
                radius: 4,
                fill: new Fill({ color: "white" }),
                stroke: new Stroke({
                  color: "black",
                  width: 2,
                }),
              }),
              zIndex: 2,
            });
            styles.push(style);
            break;

          case "route":
            segment_opacity = 0.25;
            
            geometry.forEachSegment((start, end) => {
              let lineString = new LineString([start, end]);

              style = new Style({
                stroke: new Stroke({
                  color: "rgba(171, 62, 180, " + segment_opacity + ")",
                  width: 8,
                }),
                geometry: lineString,
                zIndex: 1,
              });
              styles.push(style);

              segment_opacity = segment_opacity + 0.25;
            });
            break;
        }

        return styles;
      };
    },
    getServiceStatus: async function () {
      const url = `/api/v1/service/${this.service_id}/`;
      try {
        const respuesta = await axios.get(url, this.getHeaders());
        const estat = respuesta.data.status;
        return estat;
      } catch (err) {
        console.log(err);
        console.log("ha habido un error");
      }
    },
    loadFeatures: async function () {
     
      const estat= await this.getServiceStatus();
      if (estat != 'Executing'){
        this.$emit("gpsChange", {"layer_name": this.name, "gps_active":false});
        return false;
      } 

      try {
        const writer = new GeoJSON();

        const url = `/api/v1/vehicle/${this.vehicle_id}/last_locations/`;
        const respuesta = await axios.get(url,this.getHeaders());
        const punts_gps = respuesta.data.data;

        //const punts_gps = jsonReport.data;
       ;

        punts_gps.push(punts_gps[3]);
        const punts_gps_reversed = punts_gps.reverse();
        const arr_coordinates_gps = punts_gps_reversed.map(function (punt_gps) {
          return [punt_gps.lon, punt_gps.lat];
        });

        if (arr_coordinates_gps[0][0] == null) {
          this.$emit("gpsChange", { layer_name: this.name, gps_active: false });
          return false;
        }
        const route = new LineString(arr_coordinates_gps);

        const routeFeature = new Feature({
          type: "route",
          geometry: route,
        });

        const geoJsonroute = writer.writeFeature(routeFeature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        });

        const lastCoordinate = routeFeature.getGeometry().getLastCoordinate();

        const penultimateCoord =
          route.getCoordinates()[route.getCoordinates().length - 2];

        //let imageTruck = imageTruckRight;
        let pointNowType = "pointNowRight";

        if (penultimateCoord[0] > lastCoordinate[0]) {
          pointNowType = "pointNowLeft";
        }

        const timePointNowFeature = punts_gps[punts_gps.length - 1].timestamp;

        const pointNowFeature = new Feature({
          type: pointNowType,
          geometry: new Point(lastCoordinate),
        });

        pointNowFeature.setProperties({
          timestamp: timePointNowFeature,
          registration: this.registration_id,
        });
        const geoJsonNowMarker = writer.writeFeature(pointNowFeature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        });

        const pointLastFeature = new Feature({
          type: "pointLast",
          geometry: new Point(routeFeature.getGeometry().getFirstCoordinate()),
        });

        const geoJsonLastMarker = writer.writeFeature(pointLastFeature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        });

        const pointsMiddleFeature = new Feature({
          type: "pointsMiddle",
          geometry: new MultiPoint(arr_coordinates_gps.slice(1, 4)),
        });

        const geoJsonMiddleMarkers = writer.writeFeature(pointsMiddleFeature, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        });

        this.features = await [
          JSON.parse(geoJsonroute),
          JSON.parse(geoJsonNowMarker),
          JSON.parse(geoJsonLastMarker),
          JSON.parse(geoJsonMiddleMarkers),
        ].map(Object.freeze);

        this.$emit("gpsChange", { layer_name: this.name, gps_active: true });
      } catch (err) {
        console.log(err);
        console.log("ha habido un error");
      }
    },
  },
  watch: {
    registration_id: async function () {
      await this.loadFeatures();
    },
    vehicle_id: async function () {
      await this.loadFeatures();
    },
  },
  inject: ["getHeaders"],
};
</script>
