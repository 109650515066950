<template>
  <div
    ref="draggableContainer"
    id="draggable-container"
    :key="componentKey"
    :class="{
      draggableContainer: 'true',
      draggableContainerCollpase: collapse,
    }"
  >
    <v-card
      height="100%"
      tile
      flat
      elevation="4"
      style=""
      id="draggable-header"
    >
      <v-navigation-drawer permanent width="100%">
        <v-row class="fill-height" dense no-gutters>
          <v-navigation-drawer
            mini-variant
            permanent
            height="100%"
            mini-variant-width="40"
          >
            <v-list-item
              class="px-2"
              style="background-color: #71cfdb; min-height: 38px"
              dark
              @click="handleCollapse"
              @mousedown.stop="dragMouseDown"

            >
              <v-app-bar-nav-icon>
                <v-icon>mdi-layers</v-icon>
              </v-app-bar-nav-icon>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item-group
                mandatory
                v-model="selectedItem"
                color="primary"
              >
                <v-list-item link dense>
                  <v-list-item-action>
                    <v-icon>mdi-map</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link dense>
                  <v-list-item-action>
                    <v-icon>mdi-truck</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>

          <v-list v-if="selectedItem == 0 && !collapse" dense class="grow">
            <v-subheader>CAPES : BASE</v-subheader>

            <v-divider></v-divider>
            <v-list-item-group v-model="selectedBaseLayer">
              <v-list-item
                v-for="layer in baseLayers"
                :key="layer.name"
                :class="{ layer_active: layer.visible }"
                @click="showBaseLayer(layer.name)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ layer.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list v-if="selectedItem == 1 && !collapse" dense class="grow">
            <v-subheader> CAPES : {{ overlayLayersTitle }}</v-subheader>

            <template v-for="layer in overlayLayers">
              <v-divider :key="`${layer.name}-divider`"></v-divider>
              <v-list-item :key="layer.name" :value="layer.name">
                <template v-slot:default="{}">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="selectedOverlaysLayers.includes(layer.name)"
                      @change="showLayer($event, layer.name)"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ layer.title }}</v-list-item-title>
                  </v-list-item-content>
                 
                  <v-icon
                    v-if="
                      layer.show_icon_service_executing != undefined &&
                      layer.show_icon_service_executing &&
                      layer.gps_active
                    "
                    color="green lighten-1"
                  >
                    mdi-leak
                  </v-icon>
                  <v-icon
                    v-else-if="
                      layer.show_icon_service_executing != undefined &&
                      layer.show_icon_service_executing &&
                      !layer.gps_active
                    "
                    color="grey lighten-1"
                  >
                    mdi-leak-off
                  </v-icon>
                  <v-icon v-else></v-icon>
                  <v-icon
                    color="grey lighten-1"
                    @click="fitLayer($event, layer.name)"
                  >
                    mdi-map-marker
                  </v-icon>
                </template>
              </v-list-item>
            </template>
            <!--<v-list-item-group
              v-model="selectedOverlaysLayers"
              multiple
   
            
              
            >
              <v-list-item 
                v-for="layer in overlayLayers"
                :key="layer.name"
                :value="layer.name"
          
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      @change="showLayer($event, layer.name)"
                     
                    ></v-checkbox>
                  </v-list-item-action>
                   
                  <v-list-item-content>
                    <v-list-item-title>{{ layer.title }}</v-list-item-title>
                  </v-list-item-content>

                  <v-icon
                    color="grey lighten-1"
                    @click="fitLayer($event, layer.name)"
                  >
                    mdi-map-marker
                  </v-icon>
                </template>
              </v-list-item>
            </v-list-item-group>-->
            <v-divider></v-divider>
          </v-list>
        </v-row>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LayerSelectorSimple",
  props: {
    baseLayers: {
      type: Array,
      default: () => [],
    },
    overlayLayers: {
      type: Array,
      default: () => [],
    },
    overlayLayersTitle: {
      type: String,
      default: "",
    },
    //paths: Array,
    //result: Array
  },
  data: () => ({
    selectedItem: 0,
    selectedBaseLayer: [],
    selectedOverlaysLayers: [],
    collapse: false,
    dragged:false,
    selectedpaths: [],
    menu_active: null,
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
    },
    overlayLayersData: [],
    checkbox1: true,
    componentKey: 0,
  }),
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    recalcularSelectedOverlaysLayers: function () {
      this.selectedOverlaysLayers = this.overlayLayers
        .filter((layer) => layer.visible == true)
        .map(function (layer) {
          return layer.name;
        });
    },
    showBaseLayer: function (layer_name) {
      let layer = this.baseLayers.find((layer) => layer.visible);
      if (layer != null) {
        layer.visible = false;
        //layer = Object.assign({}, layer, { visible: false });
      }

      layer = this.baseLayers.find((layer) => layer.name === layer_name);
      if (layer != null) {
        layer.visible = true;
        //layer = Object.assign({}, layer, { visible: true });
      }
    },
    showLayer: function (show, layer_name) {
      //console.log("showLayer");

      let layer = this.overlayLayers.find((layer) => layer.name === layer_name);
      if (layer != null) {
        if (show) {
          if (!layer.visible) layer.visible = true;
          //if (!layer.visible) layer = Object.assign({}, layer, { visible: true });
          //console.log(layer);
        } else {
          layer.visible = false;
          //layer = Object.assign({}, layer, { visible: false });
          //console.log(layer);
        }
      }
      this.recalcularSelectedOverlaysLayers();
    },
    fitLayer: function (show, layer_name) {
      //console.log("layer");

      let layer = this.overlayLayers.find((layer) => layer.name === layer_name);
      //console.log(layer);
      if (layer != null) {
        if (show) {
          this.$emit("fitLayerClick", layer_name);
        }
      }
    },
    /*handleclick: function (index) {
      if (this.selectedpaths.includes(index)) {
        this.selectedpaths.splice(this.selectedpaths.indexOf(index), 1);
      } else {
        this.selectedpaths.push(index);
      }

      this.$emit("selectedpath", index);
    },*/
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      this.dragged = true
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      console.log({...this.positions}); 
      // set the element's new position:
      
      // let posContainer = this.$refs.draggableContainer.getBoundingClientRect() //positionRelativeToDocument
      let posContainerParent = this.$refs.draggableContainer.parentElement.getBoundingClientRect() //positionRelativeToDocument
      let leftLimit = posContainerParent.x
      let topLimit = posContainerParent.y
      // posContainer.x+=12

      //horizontal postition
      let leftStyle = this.$refs.draggableContainer.offsetLeft - this.positions.movementX
      if( leftStyle>-leftLimit && 
        this.positions.clientX >=0 &&  (this.positions.clientX < window.innerWidth - this.$refs.draggableContainer.offsetWidth+36)  &&
        leftStyle < (window.innerWidth - leftLimit -this.$refs.draggableContainer.offsetWidth)
        ){
        this.$refs.draggableContainer.style.left = leftStyle+"px"
        console.log(this.$refs.draggableContainer.style.left);
      } 
      console.log(leftLimit);
      console.log(window.innerWidth - leftLimit);
      //vertical postition

      let topStyle = this.$refs.draggableContainer.offsetTop - this.positions.movementY
      if ( this.positions.clientY>95 &&
        this.positions.clientY < window.innerHeight-this.$refs.draggableContainer.offsetHeight+36 &&
        topStyle < (window.innerHeight - topLimit -this.$refs.draggableContainer.offsetHeight)

        
      ) {
        this.$refs.draggableContainer.style.top = topStyle + "px"
      }  

      // this.$refs.draggableContainer.style.top =
      //   this.$refs.draggableContainer.offsetTop -
      //   this.positions.movementY +
      //   "px";
      // this.$refs.draggableContainer.style.left =
      //   this.$refs.draggableContainer.offsetLeft -
      //   this.positions.movementX +
      //   "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    handleCollapse() {
      if (this.dragged) {
        this.dragged = false
        return
      }
      this.collapse = !this.collapse;
    }
  },
  watch: {
    overlayLayers: function () {
      //this.forceRerender();
      //console.log("overlayLayers");
      //console.log(newVal);
      //this.overlayLayersData.splice(0);
      //this.overlayLayersData=JSON.parse(JSON.stringify(newVal));

      this.recalcularSelectedOverlaysLayers();
      /*this.selectedOverlaysLayers = newVal
        .filter((layerConfig) => layerConfig.visible == true)
        .map(function (layerConfig) {
          return layerConfig.name;
        });*/
    },
  },
};
</script>
<style scoped>
.draggableContainerCollpase {
  width: 40px !important;
}
.draggableContainer {
  width: 285px;
  height: 230px;
  /* top: 8vh !important; */
  left: 1vh;
}
.max-width-chip.v-chip {
  margin: 0px !important;
  margin-left: 4px;
  padding-left: 40px !important;
  padding-right: 40px !important;
}
#draggable-container {
  /*width: 100% !important ;*/
  height: 60%;
  top: 8vh;
  right: 3vh;
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
.v-toolbar__title {
  font-size: 1rem;
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  font-size: 1.2rem;
}

.v-list-item__title {
  font-size: 0.8rem !important;
}
.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 24px !important;
}

.v-list--three-line .v-list-item .v-list-item__avatar,
.v-list--three-line .v-list-item .v-list-item__action,
.v-list-item--three-line .v-list-item__avatar,
.v-list-item--three-line .v-list-item__action {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.v-list-item__content {
  padding: 0px 0 !important;
}
/deep/ .v-icon.v-icon {
  font-size: 1.1rem !important;
}
.v-label {
  font-size: 0.8rem !important;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 0.8rem !important;
}
.layer_active {
  background-color: #e2e2e2;
}
/deep/ .bg-active {
  background-color: white;
}
.v-list-item__action {
  margin: 8px 0;
}
.v-list {
  padding: 0 0;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 10px;
}
.v-navigation-drawer--mini-variant .v-list-item > *:first-child {
    margin-left: 0;
    margin-right: 0;
}
</style>
